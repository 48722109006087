import {Box, Grid, Typography} from "@mui/material";
import DocumentCard from "./DocumentCard";
import {HtmlDocuments} from "../../../app/models/htmlDocument";
import {
  Slide,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import React, {useState} from "react";
import agent from "../../../app/api/agent";
import {toast} from "react-toastify";

interface Props {
  documents: HtmlDocuments;
  onDocumentSelect: (id: number) => void;
  selectDocumentWithIdOneClick: number | undefined;
  onSelectDocumentOneClick: (documentId?: number) => void;
  images: any;
  setRefetchDocuments: React.Dispatch<React.SetStateAction<number>>;
}

interface Image {
  id: number;
  imageFamilyId: number;
  fileName: string;
  imageUrl: string;
}

export default function RFPDocumentsOverview({
  documents,
  onDocumentSelect,
  selectDocumentWithIdOneClick,
  onSelectDocumentOneClick,
  images,
  setRefetchDocuments,
}: Props) {
  const [openModalImage, setOpenModalImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);
  const justForPropUse = undefined;

  const handleOpenModalImage = (image: any) => {
    setSelectedImage(image);
    setOpenModalImage(true);
  };

  const deleteImage = (id: any) => {
    agent.Document.deleteImage(id).then(() => {
      setRefetchDocuments((prevCount) => prevCount + 1);
      toast.success("Image deleted successfully");
      setOpenModalImage(false);
    });
  };

  const ImageModal = (props: any) => {
    const handleClose = () => {
      setOpenModalImage(false);
      setSelectedImage(null);
    };

    return (
      <div>
        <Dialog
          open={openModalImage}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            {selectedImage && (
              <Box>
                <img
                  src={selectedImage.imageUrl}
                  alt={selectedImage.fileName}
                  style={{width: "100%", height: "auto"}}
                />
              </Box>
            )}
          </DialogContent>

          <DialogActions>
            <Button
              sx={{
                background: "#047782",
                "&:hover": {
                  background: "#0196A4",
                },
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#fff",
                borderRadius: "4px",
                letterSpacing: "1.25px",
                margin: "15px",
              }}
              onClick={() => deleteImage(selectedImage?.id)}
            >
              Delete image
            </Button>
            <Button
              sx={{
                background: "#fff",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#047782",
                letterSpacing: "1.25px",
                border: "1px solid #047782",
                borderRadius: "4px",
                margin: "15px",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <>
      <Grid
        container
        spacing={6}
        flex={1}
        paddingX={10}
        sx={{
          position: "relative",
          overflowY: "auto",
          marginTop: "-25px",
          paddingTop: "10px",
          paddingBottom: "32px",
        }}
      >
        {documents &&
          documents.$values.map((document) => (
            <Grid item key={document.id + document.text}>
              <DocumentCard
                document={document}
                onDocumentSelect={onDocumentSelect}
                selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                onSelectDocumentOneClick={onSelectDocumentOneClick}
                representativeRoleInContractProgress={justForPropUse}
              />
            </Grid>
          ))}

        {images &&
          images.map((image: any) => (
            <>
              <ImageModal setOpen={setOpenModalImage} open={openModalImage} />
              <Grid item key={image.id}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    width: "200px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {image.fileName}
                </Typography>
                <img
                  src={image.imageUrl}
                  alt=""
                  style={{
                    height: 260,
                    width: 200,
                    paddingTop: 2.5,
                    paddingBottom: 2.5,
                    paddingLeft: 3,
                    paddingRight: 3,
                    cursor: "pointer",
                  }}
                  onClick={() => handleOpenModalImage(image)}
                />
              </Grid>
            </>
          ))}
      </Grid>
    </>
  );
}
