import React from "react";
import PropertyInfo from "../../app/models/propertyInfo";
import NotificationCard from "../notifications/NotificationCard";
import {Box} from "@mui/material";
import {NotificationHeader} from "./NotificationHeader";
interface Props {
  notifications: PropertyInfo[];
}

export default function NotificationContainer({notifications}: Props) {
  if (notifications.length === 0) {
    return (
      <Box sx={{height: "185px", width: "400px", padding: "10px"}}>
        There are no new notifications
      </Box>
    );
  }

  const sortedNotifications = [...notifications].sort((a, b) => b.id - a.id);

  return (
    <>
      <NotificationHeader />
      {notifications &&
        sortedNotifications.map((notification) => (
          <NotificationCard key={notification.id} notification={notification} />
        ))}
    </>
  );
}
