import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ChatModel, UserForChat } from "../../../app/models/messageCenter";

interface ChatState {
  chat: ChatModel | null;
}

const initialState: ChatState = {
  chat: null,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setChat: (state, action) => {
      state.chat = action.payload;
    },
    clearChat: (state) => {
      state.chat = null;
    },
    addMessage: (state, action) => {
      state.chat?.messages.push(action.payload);
    },
    addUser: (state, action: PayloadAction<UserForChat>) => {
      state.chat!.users[action.payload.id] = action.payload;
    },
  },
});

export const { setChat, clearChat, addMessage, addUser } = chatSlice.actions;
