import {Box, Card, CardActions, CardContent, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {Property} from "../../app/models/property";
import ShareIcon from "@mui/icons-material/Share";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import agent from "../../app/api/agent";
import {toast} from "react-toastify";
import {useState} from "react";
import VerticalDivider from "../../app/components/VerticalDividerCard";
import LightTooltip from "../../app/components/LightTooltip";
import FlagIcon from "@mui/icons-material/Flag";
import {useAppSelector} from "../../app/store/configureStore";
import FlareIcon from "@mui/icons-material/Flare";

interface Props {
  property: Property;
  handleFavoriteChange: (propertyId: any) => void;
}

const PropertyCard = ({property, handleFavoriteChange}: Props) => {
  const [icon, setIcon] = useState(property.isFavorite);
  const {user} = useAppSelector((state) => state.account);

  function handleFavorite(propertyId: any, e: any) {
    agent.Favorites.UpdateFavoriteProperty(propertyId)
      .then(() => {
        setIcon(!icon);
        handleFavoriteChange(propertyId);
        toast.success("Property was edited Successfully");
      })
      .catch((error) => console.log(error));
  }

  function handleCopyClipboard(url: any, e: any) {
    let currentURL = window.location.hostname;

    var withoutProtocol = currentURL.replace(/^https?:\/\//, "");

    // Remove path and query string
    var domain = withoutProtocol.split("/")[0];

    // Remove port number if present
    domain = domain.split(":")[0];

    let fullAddress = "https://" + domain.concat(url);
    let hyperlink = `<a href="${fullAddress}">${fullAddress}</a>`;

    // Create a temporary element to hold the HTML content
    let tempElem = document.createElement("div");
    tempElem.innerHTML = hyperlink;

    // Use the Clipboard API to write both plain text and HTML
    navigator.clipboard
      .write([
        new ClipboardItem({
          "text/plain": new Blob([fullAddress], {type: "text/plain"}),
          "text/html": new Blob([tempElem.innerHTML], {type: "text/html"}),
        }),
      ])
      .then(
        () => {
          toast.success("The property overview link is copied!");
        },
        () => {
          toast.error("Failed to copy. Please try again later.");
        }
      );
  }

  const icons: any = [
    {
      id: 1,
      label: "Share",
      icon: (
        <LightTooltip title="Copy Link" placement="left">
          <ShareIcon
            onClick={(e) => {
              handleCopyClipboard(`/property/nouser/det/${property.id}`, e);
            }}
            sx={{color: "#707070"}}
          />
        </LightTooltip>
      ),
    },
    {
      id: 2,
      label: "Favorite",
      icon: icon ? (
        <LightTooltip title="Favorite" placement="left">
          <FavoriteIcon
            onClick={(e) => {
              handleFavorite(property.id, e);
            }}
          />
        </LightTooltip>
      ) : (
        <LightTooltip title="Favorite" placement="left">
          <FavoriteBorderIcon
            onClick={(e) => handleFavorite(property.id, e)}
            sx={{color: "#707070"}}
          />
        </LightTooltip>
      ),
    },
  ];

  const fullAddress = `${property.addressLine} ${property.city}, ${property.state} ${property.postalCode} ${property.country}`;

  return (
    <>
      <Box>
        <Card
          key={property.id}
          sx={{
            border: "1px solid #AACCDD",
            borderRadius: "8px",
            boxShadow: "none",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0px",
            width: "100%",
            height: "154px",
            marginBottom: "15px",
            color: "#424242",
          }}
        >
          <CardContent
            sx={{
              minWidth: "245px",
              maxWidth: "245px",
              padding: "0px",
              marginRight: "10px",
              height: "154px",
            }}
          >
            <Link
              to={`/property/det/${property.id}`}
              style={{textDecoration: "none", color: "#757575"}}
            >
              <img
                src={property.mainImageUrl}
                alt=""
                style={{
                  height: "154px",
                  width: "100%",
                  padding: "0px",
                  margin: "0px",
                }}
              />
            </Link>
          </CardContent>
          <CardActions sx={{width: "15%"}}>
            <Link
              to={`/property/det/${property.id}`}
              style={{textDecoration: "none", color: "#757575"}}
            >
              <Typography sx={{color: "#424242"}} variant="h5">
                £{property.listPrice?.toLocaleString()}
              </Typography>
              <Typography sx={{lineHeight: "1.2", fontSize: "0.75rem"}}>
                {fullAddress}
              </Typography>

              <Typography variant="subtitle1">#C{property.id}</Typography>
            </Link>
          </CardActions>
          <VerticalDivider />
          {(user?.profession === "Buyer" && property.hasLenderOffer) ||
          (user?.profession === "Seller" && property.hasLenderOffer) ||
          (user?.profession === "Buyer" && property.hasServiceOffer) ||
          (user?.profession === "Seller" && property.hasServiceOffer) ? (
            <Link
              to={`/property/serviceoffers/${property.id}`}
              style={{
                textDecoration: "none",
                color: "#757575",
                width: "13%",
                height: "100%",
                display: "flex",
                padding: "15px",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FlagIcon
                  sx={{
                    color: "rgba(38, 153, 251, 1)",
                    fontSize: "40px",
                  }}
                />
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "rgba(97, 97, 97, 1)",
                    font: "Roboto",
                    fontWeight: "400",
                    lineHeight: "20px",
                    fontSize: "14px",
                  }}
                >
                  Offers
                </Typography>
              </Box>
            </Link>
          ) : user?.profession === "Representative" &&
            user.id === property.userId &&
            property.isOfferOrInquiry ? (
            <Link
              to={`/marketplace/property/interests/${property.id}`}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textDecoration: "none",
                color: "#757575",
                width: "13%",
                height: "100%",
                padding: "15px",
                justifyContent: "center",
              }}
            >
              <FlareIcon
                sx={{
                  color: "rgba(38, 153, 251, 1)",
                  fontSize: "50px",
                  marginBottom: "6px",
                }}
              />
              <Typography
                variant="subtitle1"
                sx={{
                  color: "rgba(97, 97, 97, 1)",
                  font: "Roboto",
                  fontWeight: "400",
                  lineHeight: "20px",
                  fontSize: "14px",
                }}
              >
                Interests
              </Typography>
            </Link>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textDecoration: "none",
                color: "#757575",
                width: "13%",
                height: "100%",
                padding: "15px",
                justifyContent: "center",
              }}
            />
          )}
          <VerticalDivider />
          <Box
            sx={{
              width: "25%",
              height: "100%",
              display: "flex",
              padding: "15px",
              flexDirection: "column",
              marginLeft: "30px",
            }}
          >
            <Link
              to={`/property/det/${property.id}`}
              style={{textDecoration: "none", color: "#757575"}}
            >
              <Typography
                variant="h5"
                sx={{
                  paddingBottom: "5px",
                  fontWeight: 700,
                  fontSize: "12px",
                  lineHeight: "20px",
                  color: "#424242",
                }}
              >
                Summary
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#616161",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                Duration of Listing:{property.durationOfListing}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#616161",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                Type:{property.type}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#616161",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                Lease:{property.lease}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#616161",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                Lease Rate Type:{property.leaseRateType}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#616161",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                Building Size:±{property.buildingSize}SF
              </Typography>
            </Link>
          </Box>
          <Box
            sx={{
              width: "20%",
              height: "100%",
              display: "flex",
              padding: "15px",
              flexDirection: "column",
            }}
          >
            <Link
              to={`/property/det/${property.id}`}
              style={{textDecoration: "none", color: "#757575"}}
            >
              <Typography
                variant="h5"
                sx={{
                  paddingBottom: "23px",
                  fontWeight: 700,
                  fontSize: "18px",
                  lineHeight: "22px",
                  color: "#424242",
                }}
              ></Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#616161",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                Site Size:{property.siteSize}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#616161",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                Zoning:{property.zoning}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#616161",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                Year Built:{property.built}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#616161",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                Last Renovation:{property.lastRenovation}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#616161",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                Parking:{property.parking}
              </Typography>
            </Link>
          </Box>
          <Box
            sx={{
              width: "10%",
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              marginRight: "5px",
            }}
          >
            {icons.map(({id, label, icon}: any) => {
              return (
                <Box
                  key={`${label} - ${id}`}
                  component="span"
                  sx={{display: "flex", margin: "5px"}}
                >
                  {icon}
                </Box>
              );
            })}
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default PropertyCard;
