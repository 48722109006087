import {useState} from "react";
import {Box, Button} from "@mui/material";
import InquiriesTable from "./InquiriesTable";
import OffersTable from "./OffersTable";
import DealDrawerWrapper from "../dealDrawer/DealDrawerWrapper";
import RfpOffersTable from "./RfpOffersTable";

interface Props {
  dealId: number;
  boxRef: React.MutableRefObject<Element | undefined>;
  prevScrollableAreaPosition: React.MutableRefObject<number | undefined>;
  isServiceCard?: boolean;
  contractProgress: {};
  property: any;
  onHandleLvL2ClickItemClick: (id: any) => void;
}

const DealInterestDrawer = ({
  dealId,
  boxRef,
  prevScrollableAreaPosition,
  isServiceCard = false,
  property,
  contractProgress,
  onHandleLvL2ClickItemClick,
}: Props) => {
  const [activeInquiry, setActiveInquiry] = useState(false);
  const [openProgressDrawer, setOpenProgressDrawer] = useState({
    openProgressDrawer: false,
  });

  return (
    <DealDrawerWrapper
      property={property}
      dealId={dealId}
      boxRef={boxRef}
      prevScrollableAreaPosition={prevScrollableAreaPosition}
      isServiceCard={isServiceCard}
      contractProgress={contractProgress}
      openProgressDrawer={openProgressDrawer}
      setOpenProgressDrawer={setOpenProgressDrawer}
      onHandleLvL2ClickItemClick={onHandleLvL2ClickItemClick}
    >
      <Box
        sx={{
          flex: 1,
          backgroundColor: "#F2F6FA",
          padding: "20px",
        }}
      >
        <Box sx={{backgroundColor: "#EAF0F5"}}>
          <Button
            onClick={() => setActiveInquiry(false)}
            sx={{
              paddingLeft: "15px",
              paddingTop: "15px",
              paddingBottom: "15px",
              color: activeInquiry ? "#616161" : "#0D2561",
              borderBottom: activeInquiry
                ? "none"
                : "2px solid rgba(13, 37, 97, 1)",
              borderRadius: 0,
            }}
          >
            OFFERS
          </Button>
          <Button
            onClick={() => setActiveInquiry(true)}
            sx={{
              paddingLeft: "15px",
              paddingTop: "15px",
              paddingBottom: "15px",
              color: activeInquiry ? "#0D2561" : "#616161",
              borderBottom: activeInquiry
                ? "2px solid rgba(13, 37, 97, 1)"
                : "none",
              borderRadius: 0,
            }}
          >
            INQUIRIES
          </Button>
        </Box>

        {activeInquiry ? (
          <InquiriesTable dealId={dealId} isServiceCard={isServiceCard} />
        ) : isServiceCard ? (
          <RfpOffersTable dealId={dealId} />
        ) : (
          <OffersTable dealId={dealId} />
        )}
      </Box>
    </DealDrawerWrapper>
  );
};

export default DealInterestDrawer;
