import {Property} from "../../../app/models/property";
import DealCard from "./DealCard";
import {v4 as uuidv4} from "uuid";
import MyDealsNoProperties from "../../../app/components/MyDealsNoProperties";
import MyDealsNoPropertiesButHasRFPs from "../../../app/components/MyDealsNoPropertiesButHasRFPs";
import LoaderWithLinearProgress from "../../../app/components/LoaderWithLinearProgress";

interface Props {
  properties: Property[];
  rfps: any;
  loading: boolean;
}

const DealList = ({properties, rfps, loading}: Props) => {
  if (loading) return <LoaderWithLinearProgress />;

  return (
    <>
      {properties &&
        properties.map((property) => (
          <DealCard key={uuidv4()} property={property} />
        ))}
      {properties.length === 0 && rfps.length === 0 && <MyDealsNoProperties />}
      {properties.length === 0 && rfps.length > 0 && (
        <MyDealsNoPropertiesButHasRFPs />
      )}
    </>
  );
};

export default DealList;
