import {useCallback} from "react";
import {useDropzone} from "react-dropzone";
import {useController, UseControllerProps} from "react-hook-form";

interface Props extends UseControllerProps {
  disabled?: boolean;
  children: any;
}

export default function ReusableDropzoneSingleImage(props: Props) {
  const {field} = useController({...props, defaultValue: null});

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      acceptedFiles[0] = Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      });
      field.onChange(acceptedFiles[0]);
    },
    [field]
  );
  const {getRootProps, getInputProps, acceptedFiles} = useDropzone({
    onDrop,
    multiple: false,
    disabled: props.disabled,
  });

  let files = acceptedFiles.map((file: any) => (
    <li key={file.path}>{file.path}</li>
  ));

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {props.children}
      <aside>
        <h4>File</h4>
        <ul>{files}</ul>
      </aside>
    </div>
  );
}
