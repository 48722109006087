import {Box, Card, CardActions, CardContent, Typography} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {RFP} from "../../app/models/rfp";
import {Link, useNavigate} from "react-router-dom";
import {useState} from "react";
import agent from "../../app/api/agent";
import {toast} from "react-toastify";
import FavoriteIcon from "@mui/icons-material/Favorite";
import VerticalDivider from "../../app/components/VerticalDividerCard";
import LightTooltip from "../../app/components/LightTooltip";
import FlagIcon from "@mui/icons-material/Flag";
import FlareIcon from "@mui/icons-material/Flare";
import {useAppSelector} from "../../app/store/configureStore";

interface Props {
  rfp: RFP;
  handleFavoriteRfpChange: (rfpId: any) => void;
}

const RFPCard = ({rfp, handleFavoriteRfpChange}: Props) => {
  const navigate = useNavigate();
  const [icon, setIcon] = useState(rfp.isFavorite);
  const {user} = useAppSelector((state) => state.account);

  function handleFavorite(rfpId: any, e: any) {
    agent.Favorites.UpdateFavoriteRFP(rfpId)
      .then(() => {
        setIcon(!icon);
        handleFavoriteRfpChange(rfpId);
        toast.success("RFP was edited Successfully");
      })
      .catch((error) => console.log(error));
  }
  const icons: any = [
    {
      id: 1,
      label: "Share",
      icon: (
        <LightTooltip title="Copy Link" placement="left">
          <ShareIcon sx={{color: "#707070"}} />
        </LightTooltip>
      ),
    },
    {
      id: 2,
      label: "Favorite",
      icon: icon ? (
        <LightTooltip title="Favorite" placement="left">
          <FavoriteIcon
            onClick={(e) => {
              handleFavorite(rfp.id, e);
            }}
          />
        </LightTooltip>
      ) : (
        <LightTooltip title="Favorite" placement="left">
          <FavoriteBorderIcon
            onClick={(e) => handleFavorite(rfp.id, e)}
            sx={{color: "#707070"}}
          />
        </LightTooltip>
      ),
    },
  ];

  async function handleClick(id: number) {
    navigate(`/requestforproposal/det/${id}`);
  }

  return (
    <Box>
      <Card
        key={rfp.id}
        sx={{
          border: "1px solid #AACCDD",
          borderRadius: "8px",
          boxShadow: "none",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "0px",
          width: "100%",
          height: "154px",
          marginBottom: "15px",
          color: "#424242",
        }}
      >
        <CardContent
          onClick={() => handleClick(rfp.id)}
          sx={{
            minWidth: "245px",
            maxWidth: "245px",
            padding: "0px",
            marginRight: "10px",
            height: "100%",
          }}
        >
          {rfp.mainRFPImage != null ? (
            <img
              src={rfp.mainRFPImage}
              alt=""
              style={{
                height: "154px",
                width: "100%",
                padding: "0px",
                margin: "0px",
              }}
            />
          ) : (
            <img
              src={"/images/RfpDefault.png"}
              alt="RFP"
              style={{
                height: "154px",
                width: "100%",
                padding: "0px",
                margin: "0px",
              }}
            />
          )}
        </CardContent>
        <CardActions
          onClick={() => handleClick(rfp.id)}
          sx={{width: "15%", height: "100%"}}
        >
          <Box
            style={{textDecoration: "none", color: "#757575", height: "100%"}}
          >
            <Typography
              sx={{color: "#424242", paddingBottom: "15px"}}
              variant="h5"
            >
              RFP
            </Typography>
            <Typography
              sx={{
                lineHeight: "1.2",
                fontSize: "0.75rem",
                paddingBottom: "10px",
                color: "#757575",
              }}
            >
              {rfp.addressOfSeeker}
            </Typography>
            <Typography
              sx={{paddingTop: "5px", color: "#757575"}}
              variant="subtitle1"
            >
              #P{rfp.id}
            </Typography>
          </Box>
        </CardActions>
        <VerticalDivider />
        {user?.id === rfp.userId && rfp.hasOfferOrInquiry ? (
          <Link
            to={`/rfp/rfpinterests/${rfp.id}`}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textDecoration: "none",
              color: "#757575",
              width: "13%",
              height: "100%",
              padding: "15px",
              justifyContent: "center",
            }}
          >
            {user.profession !== "Seller" ? (
              <>
                <FlagIcon
                  sx={{
                    color: "rgba(38, 153, 251, 1)",
                    fontSize: "50px",
                    marginBottom: "6px",
                  }}
                />
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "rgba(97, 97, 97, 1)",
                    font: "Roboto",
                    fontWeight: "400",
                    lineHeight: "20px",
                    fontSize: "14px",
                  }}
                >
                  Offers
                </Typography>
              </>
            ) : (
              <>
                <FlareIcon
                  sx={{
                    color: "rgba(38, 153, 251, 1)",
                    fontSize: "50px",
                    marginBottom: "6px",
                  }}
                />
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "rgba(97, 97, 97, 1)",
                    font: "Roboto",
                    fontWeight: "400",
                    lineHeight: "20px",
                    fontSize: "14px",
                  }}
                >
                  Interests
                </Typography>
              </>
            )}
          </Link>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textDecoration: "none",
              color: "#757575",
              width: "13%",
              height: "100%",
              padding: "15px",
              justifyContent: "center",
            }}
          />
        )}
        <VerticalDivider />

        <Box
          onClick={() => handleClick(rfp.id)}
          sx={{
            width: "22.5%",
            height: "100%",
            display: "flex",
            padding: "15px",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              paddingBottom: "5px",
              fontWeight: 700,
              fontSize: "12px",
              lineHeight: "20px",
              color: "#424242",
            }}
          >
            Summary
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              color: "#616161",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "20px",
            }}
          >
            timePeriod:{rfp.timePeriod}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              color: "#616161",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "20px",
            }}
          >
            serviceDescription:{rfp.serviceDescription}
          </Typography>
        </Box>

        <Box
          onClick={() => handleClick(rfp.id)}
          sx={{
            width: "22.5%",
            height: "100%",
            display: "flex",
            padding: "15px",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              paddingBottom: "23px",
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "22px",
              color: "#424242",
            }}
          ></Typography>
          <Typography
            variant="subtitle2"
            sx={{
              color: "#616161",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "20px",
            }}
          >
            addressOfSeeker:{rfp.addressOfSeeker}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              color: "#616161",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "20px",
            }}
          >
            nameOfSeeker:{rfp.nameOfSeeker}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              color: "#616161",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "20px",
            }}
          >
            addressOfProperty:{rfp.addressOfProperty}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              color: "#616161",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "20px",
            }}
          >
            typeOfService:{rfp.typeOfService}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              color: "#616161",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "20px",
            }}
          >
            serviceProvider:{rfp.serviceProvider}
          </Typography>
        </Box>

        <Box
          sx={{
            width: "10%",
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            marginRight: "5px",
          }}
        >
          {icons.map(({id, label, icon}: any) => {
            return (
              <Box
                key={`${label} - ${id}`}
                component="span"
                sx={{display: "flex", margin: "5px"}}
              >
                {icon}
              </Box>
            );
          })}
        </Box>
      </Card>
    </Box>
  );
};

export default RFPCard;
