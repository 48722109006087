import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#757575",
    boxShadow: theme.shadows[4],
    fontSize: 11,
  },
}));

export default LightTooltip;
