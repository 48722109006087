import {Box, Button, Card, Menu, MenuItem} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

interface Props {
  anchorElCategory: HTMLElement | null;
  onToggleCategory: (event: React.MouseEvent<HTMLButtonElement>) => void;
  openCategory: boolean;
  anchorElPrice: HTMLElement | null;
  onTogglePrice: (event: React.MouseEvent<HTMLButtonElement>) => void;
  openPrice: boolean;
  anchorElDealNumber: HTMLElement | null;
  onToggleDealNumber: (event: React.MouseEvent<HTMLButtonElement>) => void;
  openDealNumber: boolean;
  onHighToLow: (value: any) => void;
  onLowToHigh: (value: any) => void;
  onFilterClose: () => void;
  onlyProperties: () => void;
  onlyRFPs: () => void;
  allDeals: () => void;
  onAccendingDealId: (value: any) => void;
  onDecendingDealId: (value: any) => void;
  anchorElPropertyNumber: HTMLElement | null;
  openPropertyNumber: boolean;
  onTogglePropertyNumber: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onAccendingPropertyId: (value: any) => void;
  onDecendingPropertyId: (value: any) => void;
}

export default function DealFilter({
  anchorElCategory,
  onToggleCategory,
  openCategory,
  onTogglePrice,
  anchorElPrice,
  openPrice,
  onHighToLow,
  onLowToHigh,
  onFilterClose,
  onlyProperties,
  onlyRFPs,
  allDeals,
  anchorElDealNumber,
  openDealNumber,
  onToggleDealNumber,
  onAccendingDealId,
  onDecendingDealId,
  anchorElPropertyNumber,
  openPropertyNumber,
  onTogglePropertyNumber,
  onAccendingPropertyId,
  onDecendingPropertyId,
}: Props) {
  return (
    <Card sx={{display: "flex"}}>
      <Box display="flex">
        <Button
          onClick={onToggleCategory}
          sx={{
            fontFamily: "Roboto",
            fontSize: "12px",
            fontWeight: "700",
            lineHeight: "20px",
            letterSpacing: "0.4px",
            textAlign: "left",
            color: "rgba(97, 97, 97, 1)",
            paddingLeft: "15px",
          }}
        >
          Category
          {openCategory ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Menu
          anchorEl={anchorElCategory}
          open={openCategory}
          onClose={onToggleCategory}
        >
          <MenuItem onClick={onlyProperties}>Deals (properties)</MenuItem>
          <MenuItem onClick={onlyRFPs}>Services (RFPs)</MenuItem>
          <MenuItem onClick={allDeals}>All</MenuItem>
        </Menu>
      </Box>
      <Button
        onClick={onTogglePrice}
        sx={{
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: "700",
          lineHeight: "20px",
          letterSpacing: "0.4px",
          textAlign: "left",
          color: "rgba(97, 97, 97, 1)",
        }}
      >
        Price
        {openPrice ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      <Menu anchorEl={anchorElPrice} open={openPrice} onClose={onTogglePrice}>
        <MenuItem onClick={onLowToHigh}>High to Low</MenuItem>
        <MenuItem onClick={onHighToLow}>Low to High</MenuItem>
      </Menu>
      <Button
        onClick={onToggleDealNumber}
        sx={{
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: "700",
          lineHeight: "20px",
          letterSpacing: "0.4px",
          textAlign: "left",
          color: "rgba(97, 97, 97, 1)",
        }}
      >
        Deal Number
        {openDealNumber ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      <Menu
        anchorEl={anchorElDealNumber}
        open={openDealNumber}
        onClose={onToggleDealNumber}
      >
        <MenuItem onClick={onDecendingDealId}>Descending</MenuItem>
        <MenuItem onClick={onAccendingDealId}>Ascending</MenuItem>
      </Menu>

      <Button
        onClick={onTogglePropertyNumber}
        sx={{
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: "700",
          lineHeight: "20px",
          letterSpacing: "0.4px",
          textAlign: "left",
          color: "rgba(97, 97, 97, 1)",
        }}
      >
        Property Number
        {openPropertyNumber ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      <Menu
        anchorEl={anchorElPropertyNumber}
        open={openPropertyNumber}
        onClose={onTogglePropertyNumber}
      >
        <MenuItem onClick={onDecendingPropertyId}>Descending</MenuItem>
        <MenuItem onClick={onAccendingPropertyId}>Ascending</MenuItem>
      </Menu>

      <Button onClick={onFilterClose}>
        <CloseIcon sx={{color: "rgba(97, 97, 97, 1)"}} />
      </Button>
    </Card>
  );
}
