import {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {useNavigate, useParams} from "react-router-dom";
import {Property} from "../../app/models/property";
import agent from "../../app/api/agent";
import {
  Box,
  Grid,
  ImageList,
  ImageListItem,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ApartmentIcon from "@mui/icons-material/Apartment";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import HorizontalSplitOutlinedIcon from "@mui/icons-material/HorizontalSplitOutlined";
import SettingsOverscanOutlinedIcon from "@mui/icons-material/SettingsOverscanOutlined";
import StraightenOutlinedIcon from "@mui/icons-material/StraightenOutlined";
import TrackChangesOutlinedIcon from "@mui/icons-material/TrackChangesOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import ShareIcon from "@mui/icons-material/Share";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import EditIcon from "@mui/icons-material/Edit";
import ChildModal from "../inquiries/InquiryMarketplacePropertyCreate";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {toast} from "react-toastify";
import LightTooltip from "../../app/components/LightTooltip";
import LoaderWithLinearProgress from "../../app/components/LoaderWithLinearProgress";
import {useAppDispatch, useAppSelector} from "../../app/store/configureStore";
import PrimaryButton from "../../app/components/PrimaryButton";
import SecondaryButton from "../../app/components/SecondaryButton";
import {openDealCardAfterPrepareOfferByBuyer} from "../../app/store/uiSlice";

const PropertyOverview = () => {
  const navigate = useNavigate();
  const {user} = useAppSelector((state) => state.account);
  const dispatch = useAppDispatch();
  const {id} = useParams<{id: string}>();
  const [property, setProperty] = useState<Property | null>(null);
  const [loading, setLoading] = useState(true);
  const [galleryImages, setGalleryImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [icon, setIcon] = useState(property?.isFavorite);

  const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  // function handleCopyClipboard(url: any, e: any) {
  //   let currentURL = window.location.hostname;

  //   var withoutProtocol = currentURL.replace(/^https?:\/\//, "");

  //   // Remove path and query string
  //   var domain = withoutProtocol.split("/")[0];

  //   // Remove port number if present
  //   domain = domain.split(":")[0];

  //   let fullAddress = domain.concat(url);

  //   navigator.clipboard.writeText(fullAddress).then(
  //     () => {
  //       toast.success("The property overview link is copied!");
  //     },
  //     () => {
  //       toast.error("Failed to copy. Please try again later.");
  //     }
  //   );
  // }

  function handleCopyClipboard(url: any, e: any) {
    let currentURL = window.location.hostname;

    var withoutProtocol = currentURL.replace(/^https?:\/\//, "");

    // Remove path and query string
    var domain = withoutProtocol.split("/")[0];

    // Remove port number if present
    domain = domain.split(":")[0];

    let fullAddress = "https://" + domain.concat(url);
    let hyperlink = `<a href="${fullAddress}">${fullAddress}</a>`;

    // Create a temporary element to hold the HTML content
    let tempElem = document.createElement("div");
    tempElem.innerHTML = hyperlink;

    // Use the Clipboard API to write both plain text and HTML
    navigator.clipboard
      .write([
        new ClipboardItem({
          "text/plain": new Blob([fullAddress], {type: "text/plain"}),
          "text/html": new Blob([tempElem.innerHTML], {type: "text/html"}),
        }),
      ])
      .then(
        () => {
          toast.success("The property overview link is copied!");
        },
        () => {
          toast.error("Failed to copy. Please try again later.");
        }
      );
  }

  const icons = [
    user?.id === property?.userId && {
      id: 1,
      label: "Edit",
      icon: (
        <LightTooltip title="Edit Property Details" placement="top-end">
          <EditIcon sx={{color: "#707070"}} onClick={handleEdit} />
        </LightTooltip>
      ),
    },
    {
      id: 2,
      label: "Share",
      icon: (
        <LightTooltip title="Copy Link" placement="top-end">
          <ShareIcon
            onClick={(e) => {
              handleCopyClipboard(`/property/nouser/det/${property?.id}`, e);
            }}
            sx={{color: "#707070"}}
          />
        </LightTooltip>
      ),
    },
    {
      id: 3,
      label: "Favorite",
      icon: icon ? (
        <LightTooltip title="Favorite" placement="top-end">
          <FavoriteIcon
            onClick={(e) => {
              handleFavorite(property?.id, e);
            }}
          />
        </LightTooltip>
      ) : (
        <LightTooltip
          title="Favorite"
          placement="top-end"
          sx={{marginBottom: "-100px"}}
        >
          <FavoriteBorderIcon
            onClick={(e) => handleFavorite(property?.id, e)}
            sx={{color: "#707070"}}
          />
        </LightTooltip>
      ),
    },
  ].filter(Boolean); // Filter out any undefined values

  function handlePrepareOfferForBuyer(propertyId: any) {
    propertyId = property?.id;
    agent.Deals.postDealCardForBuyer(propertyId)
      .then((response) => {
        navigate("/deals");
        dispatch(openDealCardAfterPrepareOfferByBuyer(response));
      })
      .catch((error) => console.log(error));
  }

  function handleFavorite(propertyId: any, e: any) {
    agent.Favorites.UpdateFavoriteProperty(propertyId)
      .then(() => {
        setIcon(!icon);
        toast.success("Property was edited Successfully");
      })
      .catch((error) => console.log(error));
  }

  function handleEdit() {
    navigate(`/edit-property/${property?.id}`);
  }

  useEffect(() => {
    id &&
      agent.Marketplace.details(parseInt(id))
        .then((response) => {
          setProperty(response);
          const galleryValues = response.gallery.$values;
          const galleryUrls = galleryValues.map((img: any) => img.imageUrl);
          setIcon(property?.isFavorite);
          setGalleryImages(galleryUrls);
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
  }, [id, property?.isFavorite]);

  function handleResponseOffer() {
    if (user) {
      if (user.profession === "Lender") {
        navigate(`/property/createlenderoffer/${id}`);
      } else if (user.profession === "Representative") {
        handlePrepareOfferForBuyer(id);
      } else {
        navigate(`/propertyserviceoffer/${property?.id}`);
      }
    }
  }

  console.log(user?.profession);

  if (loading) return <LoaderWithLinearProgress />;

  if (!property) return <h3>Property not Found</h3>;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F5F5F5",
          paddingX: "20px",
          paddingTop: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            width: "100%",
            position: "sticky",
            backgroundColor: "#F5F5F5",
            top: 0,
            height: "60px",
            zIndex: "10",
          }}
        >
          <Box
            sx={{
              width: "50%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <NavLink to="/marketplace">
              <ArrowBackIosNewIcon
                sx={{
                  display: "flex",
                  color: "rgba(112, 112, 112, 1)",
                  "&:hover": {
                    color: "rgba(208, 235, 238, 1)",
                  },
                }}
              />
            </NavLink>
            <Typography
              variant="body2"
              color="#00000099"
              fontSize="14px"
              lineHeight="20px"
              paddingLeft="25px"
            >
              {property.state}
              <ArrowForwardIosIcon
                sx={{
                  fontSize: "12px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              />
              {property.city}
              <ArrowForwardIosIcon
                sx={{fontSize: "12px", marginLeft: "10px"}}
              />
            </Typography>
            <Typography
              variant="body2"
              color="black/0.87"
              fontSize="14px"
              lineHeight="20px"
              marginLeft="10px"
            >
              {property.addressLine}
            </Typography>
          </Box>

          <Box
            sx={{
              width: "50%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {icons.map(({id, label, icon}: any) => {
              return (
                <Box
                  key={`${label} - ${id}`}
                  component="span"
                  sx={{display: "flex", margin: "5px"}}
                >
                  {icon}
                </Box>
              );
            })}
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Item sx={{height: "540px"}}>
              <Box padding="10px">
                <img
                  style={{width: "100%", height: "200px"}}
                  src={property.mainImageUrl}
                  alt={property.city + " " + property.type}
                />
              </Box>
              <Grid container>
                <Grid item xs={6} display="inline-flex" padding="10px">
                  <Typography
                    variant="h6"
                    textAlign="left"
                    fontWeight="400"
                    color="#424242"
                  >
                    {property.addressLine} {property.city}, {property.state}{" "}
                    {property.postalCode}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="inline-flex"
                  padding="10px"
                  fontWeight="400"
                  color="#424242"
                >
                  <Typography variant="h6">
                    £{property.listPrice?.toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid
                  item
                  xs={6}
                  display="inline-flex"
                  flexDirection="column"
                  textAlign="left"
                  paddingLeft="10px"
                >
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <ScheduleIcon
                      sx={{height: "18px", width: "18px", marginRight: "4px"}}
                    />{" "}
                    Duration of Listing: {property.durationOfListing}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <ApartmentIcon
                      sx={{height: "18px", width: "18px", marginRight: "4px"}}
                    />
                    Type: {property.type}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <BookmarkBorderIcon
                      sx={{height: "18px", width: "18px", marginRight: "4px"}}
                    />
                    Lease: {property.lease}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <HorizontalSplitOutlinedIcon
                      sx={{height: "18px", width: "18px", marginRight: "4px"}}
                    />
                    Lease rate type: {property.leaseRateType}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <SettingsOverscanOutlinedIcon
                      sx={{height: "18px", width: "18px", marginRight: "4px"}}
                    />
                    Building Size: {property.buildingSize}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <StraightenOutlinedIcon
                      sx={{height: "18px", width: "18px", marginRight: "4px"}}
                    />
                    Site Size: {property.siteSize}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="inline-flex"
                  flexDirection="column"
                  textAlign="left"
                  paddingLeft="10px"
                >
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <TrackChangesOutlinedIcon
                      sx={{height: "18px", width: "18px", marginRight: "4px"}}
                    />
                    Zoning: {property.zoning}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <DateRangeOutlinedIcon
                      sx={{height: "18px", width: "18px", marginRight: "4px"}}
                    />
                    Year Built: {property.built}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <TodayOutlinedIcon
                      sx={{height: "18px", width: "18px", marginRight: "4px"}}
                    />
                    Last Renovation: {property.lastRenovation}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <CategoryOutlinedIcon
                      sx={{height: "18px", width: "18px", marginRight: "4px"}}
                    />
                    Parking: {property.parking}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <ListAltOutlinedIcon
                      sx={{height: "18px", width: "18px", marginRight: "4px"}}
                    />
                    Property: #{property.id}
                  </Typography>
                </Grid>
              </Grid>
            </Item>
          </Grid>

          <Grid item xs={8}>
            <Item sx={{height: "540px"}}>
              <ImageList
                sx={{width: "100%", height: "500px"}}
                cols={3}
                rowHeight={164}
              >
                {galleryImages &&
                  galleryImages.map((url, index) => (
                    <ImageListItem key={index}>
                      <img
                        style={{height: "200px"}}
                        src={url}
                        alt={` ${index}`}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
              </ImageList>
            </Item>
          </Grid>
        </Grid>

        <Grid container spacing={2} marginTop="5px">
          <Grid item xs={4}>
            <Item sx={{height: "230px"}}>
              <Box display="inline-flex" width="100%" padding="10px">
                <Typography
                  variant="h5"
                  sx={{
                    paddingBottom: "15px",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "22px",
                    color: "#424242",
                  }}
                >
                  Summary
                </Typography>
              </Box>
              <Grid
                item
                xs={12}
                flexDirection="column"
                justifyContent="flex-start"
                justifyItems="start"
                paddingLeft="10px"
              >
                <Typography variant="subtitle2" textAlign="left">
                  {property.summary}
                </Typography>
              </Grid>
            </Item>
          </Grid>

          <Grid item xs={8}>
            <Item sx={{height: "230px"}}>
              <Box display="inline-flex" width="65.5%" padding="10px">
                <Typography
                  variant="h5"
                  sx={{
                    paddingBottom: "15px",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "22px",
                    color: "#424242",
                  }}
                >
                  Property Details
                </Typography>
              </Box>
              <Box display="inline-flex" width="34%" padding="10px">
                <Typography
                  variant="h5"
                  sx={{
                    paddingBottom: "15px",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "22px",
                    color: "#424242",
                  }}
                >
                  Utilities
                </Typography>
              </Box>
              <Grid container>
                <Grid
                  item
                  xs={4}
                  display="inline-grid"
                  flexDirection="column"
                  justifyContent="flex-start"
                  justifyItems="start"
                  paddingLeft="10px"
                >
                  <Typography variant="subtitle2">
                    Exterior: {property.exterior}
                  </Typography>
                  <Typography variant="subtitle2">
                    Slab: {property.slab}
                  </Typography>
                  <Typography variant="subtitle2">
                    Building Dimensions: {property.buildingDimensions}
                  </Typography>
                  <Typography variant="subtitle2">
                    Column Spacing: {property.columnSpacing}
                  </Typography>
                  <Typography variant="subtitle2">
                    Ceiling Height: {property.ceilingHeight}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  display="inline-grid"
                  flexDirection="column"
                  justifyContent="flex-start"
                  justifyItems="start"
                  padding="5px"
                  paddingLeft="10px"
                >
                  <Typography variant="subtitle2">
                    Dock-High Doors: {property.dockHighDoors}
                  </Typography>
                  <Typography variant="subtitle2">
                    Drive In Doors: {property.driveInDoors}
                  </Typography>
                  <Typography variant="subtitle2">
                    Elevator: {property.elevator}
                  </Typography>
                  <Typography variant="subtitle2">
                    Sprinkler: {property.sprinkler}
                  </Typography>
                  <Typography variant="subtitle2">
                    Parking: {property.parking}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  display="inline-grid"
                  flexDirection="column"
                  justifyContent="flex-start"
                  justifyItems="start"
                >
                  <Typography variant="subtitle2">
                    Water & Sewer: {property.waterAndSewer}
                  </Typography>
                  <Typography variant="subtitle2">
                    Power: {property.power}
                  </Typography>
                  <Typography variant="subtitle2">
                    Power Available: {property.powerAvailable}
                  </Typography>
                  <Typography variant="subtitle2">
                    Gas: {property.gas}
                  </Typography>
                  <Typography></Typography>
                </Grid>
              </Grid>
            </Item>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            position: "sticky",
            backgroundColor: "#F5F5F5",
            bottom: 0,
          }}
        >
          <PrimaryButton
            onClick={handleResponseOffer}
            disabled={
              user?.profession === "Buyer" || user?.profession === "Seller"
            }
          >
            Prepare Offer
          </PrimaryButton>
          <ChildModal setModalOpen={setOpen} modalState={open} />
          <SecondaryButton
            onClick={(e: any) => {
              e.preventDefault();
              setOpen(true);
            }}
          >
            Inquire
          </SecondaryButton>
        </Box>
      </Box>
    </>
  );
};

export default PropertyOverview;
