import {
  Grid,
  Typography,
  Button,
  Card,
  Box,
  CardActions,
  FormControl,
} from "@mui/material";
import {NavLink, useNavigate} from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {useAppSelector} from "../../app/store/configureStore";
import {FieldValues, useForm} from "react-hook-form";

import {useEffect, useState} from "react";
import agent from "../../app/api/agent";
import AppTextInput from "../../app/components/AppTextInputCustom";
import AppSelectList from "../../app/components/AppSelectListCustom";
import LoaderWithLinearProgress from "../../app/components/LoaderWithLinearProgress";
import ReusableDropzoneSingleImage from "../../app/components/ReusableDropzoneSingleImage";
import ReusableDropzoneMultipleImages from "../../app/components/ReusableDropzoneMultipleImages";
import {toast} from "react-toastify";

const RequestForProposal = () => {
  const {user} = useAppSelector((state) => state.account);
  const navigate = useNavigate();
  const {control, handleSubmit} = useForm();
  const [loading, setLoading] = useState(false);
  const [userTypes, setUserTypes] = useState<Array<any>>([]);

  useEffect(() => {
    agent.Account.getTypes()
      .then((response) => {
        const filteredTypes = response.$values
          .filter(
            (type: any) =>
              ![
                "BuyerRepresentative",
                "SellerRepresentative",
                "SellerAttorney",
                "BuyerAttorney",
                "Admin",
                "Member",
                "EscrowAgent",
              ].includes(type.name)
          )
          .map((type: any) => type.name)
          .sort();

        setUserTypes(filteredTypes);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  async function handleSubmitData(data: FieldValues) {
    console.log(data);
    setLoading(true);
    try {
      agent.RFPs.create(data).then(() => {
        toast.success("RFP was created successfully");
        navigate("/marketplace");
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function cancelEdit() {
    navigate("/marketplace");
  }

  const timePeriod = ["ASAP", "within a week", "a month", "3 months"];
  const Service = ["In-Person", "Remote"];

  const dzStyles = {
    display: "flex",
    paddingTop: "30px",
    width: 630,
  };

  if (!user) {
    navigate("/login");
  }

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F4F4F4",
          height: "100vh",
          width: "100%",
          padding: "20px",
          paddingTop: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            width: "100%",
            alignItems: "center",
            position: "sticky",
            backgroundColor: "#F5F5F5",
            top: 0,
            height: "60px",
            zIndex: "10",
          }}
        >
          {/* <Box paddingBottom="20px" paddingTop="10px"> */}
          <NavLink to="/marketplace">
            <ArrowBackIosNewIcon
              sx={{
                display: "flex",
                color: "rgba(112, 112, 112, 1)",
                "&:hover": {
                  color: "rgba(208, 235, 238, 1)",
                },
              }}
            />
          </NavLink>
        </Box>
        <form onSubmit={handleSubmit(handleSubmitData)}>
          <Card sx={{padding: "20px", height: "90%"}}>
            <Grid
              container
              spacing={3}
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                width: "50%",
                paddingLeft: "50px",
                paddingRight: "50px",
              }}
            >
              <Grid item xs={12}>
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Roboto",
                    color: "#424242",
                    fontWeight: "600",
                    fontSize: "23px",
                  }}
                >
                  Request for Proposal
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <AppTextInput
                  label="Name of Seeker"
                  type="text"
                  name="NameOfSeeker"
                  control={control}
                  required
                  defaultValue={user?.firstName}
                />
              </Grid>

              <Grid item xs={12}>
                <AppTextInput
                  label="Address of Seeker"
                  type="text"
                  name="AddressOfSeeker"
                  control={control}
                  required
                  defaultValue={user?.companyAddress}
                />
              </Grid>

              <Grid item xs={12}>
                <AppTextInput
                  label="Address of Property"
                  type="text"
                  name="AddressOfProperty"
                  control={control}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <AppSelectList
                  control={control}
                  items={Service}
                  label="Type of Service"
                  name="TypeOfService"
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <AppSelectList
                  control={control}
                  items={userTypes}
                  label="Type of Service Provider"
                  name="ServiceProvider"
                  required
                />
              </Grid>

              <Grid item xs={12} sx={{paddingBottom: "45px"}}>
                <AppSelectList
                  label="Time Period"
                  control={control}
                  items={timePeriod}
                  name="TimePeriod"
                  required
                />
              </Grid>

              <Typography sx={{paddingLeft: "20px"}}>
                Service Description
              </Typography>

              <Grid item xs={12}>
                <AppTextInput
                  label="Describe what you are looking for"
                  type="text"
                  name="ServiceDescription"
                  control={control}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                width: "50%",
                paddingLeft: "20px",
                paddingRight: "20px",
                height: "100%",
              }}
            >
              <Grid item xs={12} paddingBottom="65px">
                <Box height="40px"></Box>
                <Box>
                  <Typography paddingTop="20px" fontWeight="700">
                    Attach Main RFP
                  </Typography>
                  <Card sx={{backgroundColor: "#F7F7F7"}}>
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControl style={dzStyles}>
                        <ReusableDropzoneSingleImage
                          name="MainRFP"
                          control={control}
                        >
                          <Box
                            display="inline-flex"
                            width="100%"
                            alignItems="center"
                          >
                            <img
                              src={"/images/DropzoneIcon.png"}
                              alt="IMG"
                              style={{
                                height: "75px",
                                width: "75px",
                              }}
                            />
                            <Typography variant="subtitle1">
                              Drag and drop a photo&nbsp;
                              <Typography
                                sx={{
                                  textDecoration: "underline",
                                  display: "inline-flex",
                                }}
                              >
                                or click here to browse
                              </Typography>
                            </Typography>
                          </Box>
                        </ReusableDropzoneSingleImage>
                      </FormControl>
                    </CardActions>
                  </Card>
                </Box>
                <Box paddingTop="20px">
                  <Typography fontWeight="700">
                    Attach Additional Documents
                  </Typography>
                  <Card sx={{backgroundColor: "#F7F7F7"}}>
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControl style={dzStyles}>
                        <ReusableDropzoneMultipleImages
                          name="Documents"
                          control={control}
                        >
                          <Box
                            display="inline-flex"
                            width="100%"
                            alignItems="center"
                          >
                            <img
                              src={"/images/DropzoneIcon.png"}
                              alt="IMG"
                              style={{
                                height: "75px",
                                width: "75px",
                              }}
                            />
                            <Typography variant="subtitle1">
                              Drag and drop a photo&nbsp;
                              <Typography
                                sx={{
                                  textDecoration: "underline",
                                  display: "inline-flex",
                                }}
                              >
                                or click here to browse
                              </Typography>
                            </Typography>
                          </Box>
                        </ReusableDropzoneMultipleImages>
                      </FormControl>
                    </CardActions>
                  </Card>
                </Box>
                <Box paddingTop="20px">
                  <Typography fontWeight="700">
                    Attach Additional Images or logos
                  </Typography>
                  <Card sx={{backgroundColor: "#F7F7F7"}}>
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControl style={dzStyles}>
                        <ReusableDropzoneSingleImage
                          name="MainRFPImage"
                          control={control}
                        >
                          <Box
                            display="inline-flex"
                            width="100%"
                            alignItems="center"
                          >
                            <img
                              src={"/images/DropzoneIcon.png"}
                              alt="IMG"
                              style={{
                                height: "75px",
                                width: "75px",
                              }}
                            />
                            <Typography variant="subtitle1">
                              Drag and drop a photo&nbsp;
                              <Typography
                                sx={{
                                  textDecoration: "underline",
                                  display: "inline-flex",
                                }}
                              >
                                or click here to browse
                              </Typography>
                            </Typography>
                          </Box>
                        </ReusableDropzoneSingleImage>
                      </FormControl>
                    </CardActions>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </Card>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              position: "sticky",
              backgroundColor: "#F5F5F5",
              bottom: 0,
            }}
          >
            <Button
              sx={{
                background: "#047782",
                "&:hover": {
                  background: "#0196A4",
                },
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#fff",
                borderRadius: "4px",
                letterSpacing: "1.25px",
                margin: "15px",
              }}
              type="submit"
            >
              Submit
            </Button>

            <Button
              onClick={cancelEdit}
              sx={{
                background: "#fff",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#047782",
                letterSpacing: "1.25px",
                border: "1px solid #047782",
                borderRadius: "4px",
                margin: "15px",
              }}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default RequestForProposal;
