import {Outlet} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useCallback, useEffect, useState} from "react";
import {fetchCurrentUser} from "../../features/account/accountSlice";
import {useAppDispatch} from "../store/configureStore";
import MiniDrawer from "./MiniDrawer";
import NavBar from "./NavBar";
import {useAppSelector} from "../store/configureStore";
import NavBarLogin from "./NavBarLogin";
import {Box} from "@mui/material";
import MessageCenter from "../../features/messageCenter/MessageCenter";
import LoaderWithLinearProgress from "../components/LoaderWithLinearProgress";

function App() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const initApp = useCallback(async () => {
    try {
      await dispatch(fetchCurrentUser());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    initApp().then(() => setLoading(false));
  }, [initApp]);

  const {user} = useAppSelector((state) => state.account);
  //  console.log(user)
  // Makes 10 calls to fetch user double check later

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <div className="app main-wrapper">
      {user ? (
        <>
          <ToastContainer
            position="bottom-center"
            hideProgressBar
            theme="colored"
          />
          <NavBar />

          <Box sx={{display: "flex", height: "100vh"}}>
            <MiniDrawer />
            <Box
              className="content-whole-screen-height cards-scrollable-area"
              sx={{
                position: "relative",
                width: "100%",
                top: "64px",
                backgroundColor: "#F5F5F5",
                overflowX: "hidden",
                overflowY: "scroll",
              }}
            >
              <Outlet />
            </Box>
            <MessageCenter />
          </Box>
        </>
      ) : (
        <>
          <ToastContainer
            position="bottom-center"
            hideProgressBar
            theme="colored"
          />
          <NavBarLogin />
          <Outlet />
        </>
      )}
    </div>
  );
}

export default App;
