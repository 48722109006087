import {Box, Typography} from "@mui/material";
import {PropsWithChildren, useEffect, useState} from "react";
import agent from "../api/agent";

interface Props extends PropsWithChildren {
  confirmDateList: any;
  contractProgressFirstVersionForDateSetter: any;
}

const ServiceProviderConfirmDateForPropertyVisit = ({
  confirmDateList,
  contractProgressFirstVersionForDateSetter,
}: Props) => {
  const [serviceProviderConfirmDate, setServiceProviderConfirmDate] =
    useState<any>();
  const [currentPhase, setCurrentPhase] = useState();

  useEffect(() => {
    contractProgressFirstVersionForDateSetter &&
      agent.ContractProgressDeal.getCurrentPhase(
        contractProgressFirstVersionForDateSetter.id
      ).then((response) => setCurrentPhase(response.currentPhaseId));
  }, [contractProgressFirstVersionForDateSetter]);

  useEffect(() => {
    setServiceProviderConfirmDate(
      confirmDateList.filter(
        (item: any) =>
          (item.roleName === "Inspector" &&
            item.contractProgressId ===
              contractProgressFirstVersionForDateSetter.id) ||
          (item.roleName === "Appraiser" &&
            item.contractProgressId ===
              contractProgressFirstVersionForDateSetter.id) ||
          (item.roleName === "Buyer" &&
            currentPhase === 5 &&
            contractProgressFirstVersionForDateSetter.id)
      )
    );
  }, [
    confirmDateList,
    contractProgressFirstVersionForDateSetter.id,
    currentPhase,
  ]);

  console.log(
    serviceProviderConfirmDate,
    contractProgressFirstVersionForDateSetter
  );

  return (
    <Box sx={{paddingTop: "15px"}}>
      {serviceProviderConfirmDate && serviceProviderConfirmDate.length > 0 && (
        <>
          <Typography>Confirmed schedule date and time:</Typography>
          <Typography>
            {serviceProviderConfirmDate &&
              serviceProviderConfirmDate.map((item: any) => item.confirmedDate)}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default ServiceProviderConfirmDateForPropertyVisit;
