import {Box, Fab, Typography} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {useAppDispatch, useAppSelector} from "../../app/store/configureStore";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import {useEffect} from "react";
import {
  closeChat,
  toggleCreateChat,
  toggleMessageCenter,
} from "./messageCenterSlice";
import {useMessageCenterConnectionContext} from "../../app/store/MessageCenterConnectionContext";
import MessageGroups from "./MessageGroups";
import CreateChat from "./CreateChat";
import {FieldValues} from "react-hook-form";

export default function MessageCenter() {
  const {
    open,
    actionComments,
    globalMessages,
    users,
    propertiesRfps,
    subjects,
    totalMessageCards,
    openCreateChat,
  } = useAppSelector((state) => state.messageCenter);
  const dispatch = useAppDispatch();
  const {onCreateChat, onDeleteUnreadMessages} =
    useMessageCenterConnectionContext();

  const createChatHandler = async (data: FieldValues) => {
    dispatch(closeChat());
    await onCreateChat(data);
  };

  const closeCreateChatHandler = () => {
    dispatch(closeChat());
  };

  useEffect(() => {
    if (!open) dispatch(closeChat());
  }, [open, dispatch]);

  if (!open) return;

  return (
    <Box
      className="content-whole-screen-height"
      sx={{
        position: "relative",
        flex: "0 0 418px",
        top: "64px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "rgba(242, 246, 250, 1)",
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25) inset",
        zIndex: "5",
      }}
    >
      <Box
        display="flex"
        color="rgba(13, 37, 97, 1)"
        paddingTop="30px"
        paddingBottom="15px"
        paddingX="18px"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" marginRight="auto">
          <MenuOpenIcon
            onClick={() => dispatch(toggleMessageCenter())}
            sx={{
              fontSize: "30px",
              transform: "rotateY(180deg)",
              marginRight: "13px",
              cursor: "pointer",
            }}
          />
          <Typography variant="h5" sx={{fontSize: "23px"}}>
            Message Center
          </Typography>
        </Box>
        <Fab
          size="small"
          sx={{
            backgroundColor: "rgba(13, 37, 97, 1)",
            "&:hover": {
              backgroundColor: "rgba(15, 43, 112, 1)",
            },
            marginRight: "10px",
          }}
          onClick={() => dispatch(toggleCreateChat())}
        >
          <EditIcon sx={{color: "rgba(255, 255, 255, 1)"}} />
        </Fab>
      </Box>
      <Box
        className="message-groups-container"
        sx={{overflowY: "auto", height: "100%", paddingX: "10px"}}
      >
        {openCreateChat && (
          <CreateChat
            onCreateChat={createChatHandler}
            onCloseCreateChatHandler={closeCreateChatHandler}
          />
        )}
        <MessageGroups
          totalMessageCards={totalMessageCards}
          actionComments={actionComments}
          globalMessages={globalMessages}
          users={users}
          propertiesRfps={propertiesRfps}
          subjects={subjects}
          deleteUnreadMessagesHandler={onDeleteUnreadMessages}
        />
      </Box>
    </Box>
  );
}

// <Fade mountOnEnter unmountOnExit in={open}>
//   {/* The component is wrapped in div to fix a MUI bug #27154 */}
//   <div>
//   </div>
// </Fade>
