import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Box, Card} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import agent from "../api/agent";
import LoaderWithLinearProgress from "./LoaderWithLinearProgress";
import LinkIcon from "@mui/icons-material/Link";
import {toast} from "react-toastify";

interface Props {
  dealId: number;
}

const ServiceProvidersOffersTable = ({dealId}: Props) => {
  const [property, setProperty] = useState<any>();
  const [offers, setOffers] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleRFPResponseOffer = (offerId: any) => {
    navigate(`/propertyserviceofferfilled/${offerId}`);
  };

  useEffect(() => {
    agent.Deals.oneDealProperty(dealId)
      .then((response) => {
        setProperty(response);
      })
      .catch((error) => console.log(error.response))
      .finally(() => setLoading(false));
  }, [dealId]);

  useEffect(() => {
    agent.OfferProperty.GetServiceOffersPropertyWithoutRFP(dealId)
      .then((response) => {
        console.log(response.$values);

        setOffers(response.$values);
      })
      .catch((error) => console.log(error.response))
      .finally(() => setLoading(false));
  }, [dealId]);

  function handleShareOffer(url: any, e: any) {
    e.preventDefault();
    let currentURL = window.location.hostname;

    var withoutProtocol = currentURL.replace(/^https?:\/\//, "");

    // Remove path and query string
    var domain = withoutProtocol.split("/")[0];

    // Remove port number if present
    domain = domain.split(":")[0];

    let fullAddress = domain.concat(url);

    navigator.clipboard.writeText(fullAddress).then(
      () => {
        toast.success("The offer link is copied!");
      },
      () => {
        toast.error("Failed to copy. Please try again later.");
      }
    );
  }

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <Card sx={{height: "calc(100% - 54.5px)"}}>
      {offers && offers.length > 0 ? (
        <Box sx={{display: "flex", flexDirection: "column"}}>
          <Card
            sx={{
              display: "inline-flex",
              width: "100%",
              backgroundColor: "#FFF",
              border: "none",
              boxShadow: "none",
            }}
          >
            <Table sx={{minWidth: 650}}>
              <TableHead>
                <TableRow>
                  <div style={{display: "flex"}}>
                    <TableCell width="14.28%">Name</TableCell>
                    <TableCell width="14.28%">Company Name</TableCell>
                    <TableCell width="14.28%">For Whom</TableCell>
                    <TableCell width="14.28%">
                      Time required to complete
                    </TableCell>
                    <TableCell width="14.28%">Cost (GBP)</TableCell>
                    <TableCell width="14.28%">Scope of Work</TableCell>
                    <TableCell width="14.28%">Service Provider</TableCell>
                  </div>
                  <TableCell width="10%"> Share Link</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Card>

          {offers.map((offer) => (
            <Card
              sx={{
                display: "inline-flex",
                width: "100%",
                backgroundColor: "#FFF",
                border: "none",
                boxShadow: "none",
              }}
              key={offer.id}
            >
              <Table sx={{minWidth: 650}}>
                <TableBody>
                  <TableRow sx={{display: "flex"}}>
                    <div
                      style={{display: "flex", width: "90%"}}
                      onClick={() => handleRFPResponseOffer(offer.id)}
                    >
                      <TableCell width="14.28%"> {offer?.firstName}</TableCell>
                      <TableCell width="14.28%">{offer?.companyName}</TableCell>
                      <TableCell width="14.28%">{offer.forWhom}</TableCell>
                      <TableCell width="14.28%">
                        {" "}
                        {offer.timeRequired}
                      </TableCell>
                      <TableCell width="14.28%"> {offer.cost}</TableCell>
                      <TableCell width="14.28%">{offer.scopeOfWork}</TableCell>
                      <TableCell width="14.28%">{offer.role}</TableCell>
                    </div>
                    <div style={{display: "flex", width: "10%"}}>
                      <TableCell width="100%">
                        <LinkIcon
                          onClick={(e) =>
                            handleShareOffer(
                              `/propertyserviceofferfilled/${offer.id}`,
                              e
                            )
                          }
                        />
                      </TableCell>
                    </div>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          ))}
        </Box>
      ) : (
        <Card
          sx={{
            boxShadow: "none",
            backgroundColor: "#FFF",
            width: "100%",
            padding: "10px",
            marginLeft: "10px",
          }}
        >
          There are no offers
        </Card>
      )}
    </Card>
  );
};

export default ServiceProvidersOffersTable;
