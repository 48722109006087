import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import React, {ChangeEvent, useState} from "react";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalState: boolean;
  setSelectedImage: React.Dispatch<React.SetStateAction<File | null>>;
  selectedImage: File | null;
  handleSubmitImage: () => Promise<void>;
}

const RFPImageUploadModal = ({
  setModalOpen,
  modalState,
  setSelectedImage,
  selectedImage,
  handleSubmitImage,
}: Props) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Dialog
      open={modalState}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      maxWidth="xl"
    >
      <DialogTitle>
        <Typography sx={{fontWeight: 600, fontSize: "20px"}}>
          Select an image to upload
        </Typography>
      </DialogTitle>

      <DialogContent>
        <div>
          <input type="file" accept="image/*" onChange={handleImageChange} />
          {previewUrl && (
            <img
              src={previewUrl}
              alt="Preview"
              style={{width: "200px", marginTop: "10px"}}
            />
          )}
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{
            background: "rgba(4, 119, 130, 1)",
            "&.MuiButtonBase-root:hover": {
              bgcolor: "rgba(1, 150, 164, 1)",
            },
            "&:disabled": {
              backgroundColor: "rgba(0, 0, 0, 0.12)",
            },
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: "500",
            color: "rgba(255, 255, 255, 1)",
            borderRadius: "4px",
            letterSpacing: "1.25px",
            textAlign: "center",
            lineHeight: "36px",
            minWidth: "100px",
            height: "40px",
            margin: "15px",
          }}
          onClick={handleSubmitImage}
          disabled={!selectedImage}
        >
          Submit
        </Button>
        <Button
          sx={{
            background: "#fff",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontSize: "0.875rem",
            fontWeight: "400",
            color: "#047782",
            letterSpacing: "1.25px",
            border: "1px solid #047782",
            borderRadius: "4px",
            margin: "15px",
          }}
          onClick={() => setModalOpen(false)}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RFPImageUploadModal;
