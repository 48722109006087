import {PropsWithChildren} from "react";
import {Box} from "@mui/material";
import "./DealDrawerWrapper.css";

interface Props extends PropsWithChildren {}

const RFPDrawerWrapper = ({children}: Props) => {
  return (
    <Box
      className="open-drawer-height"
      sx={{
        padding: "20px",
        paddingTop: "0px",
        marginTop: "-15px",
        position: "relative",
      }}
    >
      <Box display="flex" flexDirection="column" height="100%">
        <Box
          className="open-drawer-content-height"
          sx={{
            display: "flex",
            backgroundColor: "#F2F6FA",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default RFPDrawerWrapper;
