import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import PropertyInfo from "../models/propertyInfo";
import agent from "../api/agent";

interface NotificationSliceState {
  notifications: PropertyInfo[];
  totalNotifications: number;
}

const initialState: NotificationSliceState = {
  notifications: [],
  totalNotifications: 0,
};

export const getNotifications = createAsyncThunk(
  "notificationSlice/getNotifications",

  async () => {
    return await agent.Notifications.allNotifications();
  }
);

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification(state, action) {
      state.notifications = [action.payload, ...state.notifications];
      state.totalNotifications++;
    },
    clearNotifications(state) {
      //state.notifications = initialState.notifications;
      state.totalNotifications = initialState.totalNotifications;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload.$values;
      state.totalNotifications = action.payload.$values.length;
    });
  },
});

export const {setNotification, clearNotifications} = notificationSlice.actions;
