import { Avatar, Box, Typography } from "@mui/material";
import { UserForChat } from "../../../app/models/messageCenter";
import { User } from "../../../app/models/user";

interface Props {
  appUser: User | null;
  user: UserForChat;
  userId: string;
  prevUserId: string;
  message: string;
}

export default function MessageItem({
  appUser,
  user,
  userId,
  prevUserId,
  message,
}: Props) {
  const appUserMessageOwner = appUser?.id === user.id;
  const sameUserAsPrevious = userId === prevUserId;

  return (
    <>
      {!sameUserAsPrevious && (
        <Box
          display="flex"
          alignItems="center"
          marginTop="15px"
          marginBottom="5px"
          justifyContent={appUserMessageOwner ? "end" : "start"}
        >
          <Avatar
            sx={{
              backgroundColor: "lightblue",
              marginRight: appUserMessageOwner ? "0px" : "10px",
              marginLeft: appUserMessageOwner ? "10px" : "0px",
              order: appUserMessageOwner ? 2 : 1,
            }}
          >
            {user?.profilePictureUrl ? (
              <img
                src={user?.profilePictureUrl}
                alt="User profile"
                style={{
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            ) : (
              <Typography sx={{ color: "#0D2561" }}>
                {user?.firstName.charAt(0).toUpperCase()}
              </Typography>
            )}
          </Avatar>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "24px",
              letterSpacing: "0.5px",
              textAlign: "center",
              color: "rgba(66, 66, 66, 1)",
              order: appUserMessageOwner ? 1 : 2,
            }}
          >
            {user.firstName}
          </Typography>
        </Box>
      )}

      <Typography
        sx={{
          marginLeft: appUserMessageOwner ? "25px" : "48px",
          marginRight: appUserMessageOwner ? "48px" : "25px",
          textAlign: appUserMessageOwner ? "right" : "left",
          marginTop: sameUserAsPrevious ? "-2px" : "-10px",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "20px",
          letterSpacing: "0.25px",
          color: "rgba(117, 117, 117, 1)",
        }}
        className="paragraph"
      >
        {message}
      </Typography>
    </>
  );
}
