import {configureStore} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {accountSlice} from "../../features/account/accountSlice";
import {chatSlice} from "../../features/messageCenter/Chat/chatSlice";
import {messageCenterSlice} from "../../features/messageCenter/messageCenterSlice";
import {uiSlice} from "./uiSlice";
import {notificationSlice} from "./notificationSlice";

// export function configureStore() {
//     return createStore(counterReducer);
// }

export const store = configureStore({
  reducer: {
    ui: uiSlice.reducer,
    account: accountSlice.reducer,
    messageCenter: messageCenterSlice.reducer,
    chat: chatSlice.reducer,
    notification: notificationSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
