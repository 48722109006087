import { Box, Divider, Typography } from "@mui/material";
import { relativeDate } from "../../../app/util/util";
import { useEffect, useMemo, useState } from "react";

interface Props {
  timestamp: string;
  prevTimestamp?: string;
}

export default function MessageTimeDivider({
  timestamp,
  prevTimestamp,
}: Props) {
  const [, setRerender] = useState({});

  const time = new Date(timestamp).toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  const relativeTime = relativeDate(timestamp);

  const sameAsPrevious = useMemo(() => {
    if (prevTimestamp) {
      const prevRelativeTime = relativeDate(prevTimestamp);
      return relativeTime === prevRelativeTime;
    }
  }, [prevTimestamp, relativeTime]);

  useEffect(() => {
    if (sameAsPrevious) return;

    const interval = setInterval(() => setRerender({}), 60000);

    return () => {
      clearInterval(interval);
    };
  }, [sameAsPrevious]);

  if (sameAsPrevious) return;

  return (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      marginTop="24px"
      marginBottom="20px"
    >
      <Divider
        sx={{
          width: "40%",
          borderColor: "rgba(158, 158, 158, 1)",
        }}
      />
      <Box
        sx={{
          width: "60%",
          height: "30px",
          borderRadius: "35px",
          border: "1px solid rgba(158, 158, 158, 1)",
          display: "flex",
          alignContent: "center",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "20px",
            letterSpacing: "0.4px",
            textAlign: "center",
            color: "rgba(66, 66, 66, 1)",
          }}
        >
          {time} | {relativeTime}
        </Typography>
      </Box>
    </Box>
  );
}
