import {
  Grid,
  Typography,
  Button,
  Card,
  Box,
  CardActions,
  TextField,
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {useAppSelector} from "../../app/store/configureStore";
import {useEffect, useState} from "react";
import agent from "../../app/api/agent";
import ChildModal from "../inquiries/MarketplaceRFPCreateInquiry";
import LoaderWithLinearProgress from "../../app/components/LoaderWithLinearProgress";
import RfpAdditionalDocumentsListCard from "../../app/components/RfpAdditionalDocumentsListCard";
import MainRFPDocumentView from "../../app/components/MainRFPDocument";

const RequestForProposalOverview = (Props: any) => {
  const {user} = useAppSelector((state) => state.account);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [rfpDocuments, setRrfpDocuments] = useState([]);
  const [mainRFP, setMainRFP] = useState("");
  const {id} = useParams<{id: string}>();
  const [open, setOpen] = useState(false);

  const [rfp, setRfp] = useState({
    id: 0,
    nameOfSeeker: "",
    addressOfSeeker: "",
    addressOfProperty: "",
    typeOfService: "",
    serviceProvider: "",
    timePeriod: "",
    serviceDescription: "",
    mainRFP: "",
    documents: [],
    gallery: [],
    PublicId: "",
    userId: "",
  });

  async function handleResponseOffer() {
    navigate(`/responsetorfp/${id}`);
  }

  useEffect(() => {
    if (id) {
      setLoading(true);
      agent.RFPs.RFPDetails(parseInt(id))
        .then((response) => {
          setRfp(response);
          const documentValues = response.documents.$values;
          setRrfpDocuments(documentValues);
          const mainRFPUrl = response.mainRFP;
          setMainRFP(mainRFPUrl);
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
    }
  }, [id]);

  const handleNavigate = () => {
    navigate(-1);
  };

  if (!user) {
    navigate("/login");
  }

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F4F4F4",
          padding: "20px",
          paddingTop: "0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            width: "100%",
            alignItems: "center",
            position: "sticky",
            backgroundColor: "#F5F5F5",
            top: 0,
            height: "60px",
            zIndex: "10",
          }}
        >
          <Box onClick={() => handleNavigate()}>
            <ArrowBackIosNewIcon
              sx={{
                display: "flex",
                color: "rgba(112, 112, 112, 1)",
                "&:hover": {
                  color: "rgba(208, 235, 238, 1)",
                },
              }}
            />
          </Box>
        </Box>
        <Card sx={{padding: "20px", height: "100%"}}>
          <Grid
            container
            spacing={3}
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              width: "50%",
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          >
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Roboto",
                  color: "#424242",
                  fontWeight: "600",
                  fontSize: "23px",
                }}
              >
                Request for Proposal
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                label="Name of Seeker"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                type="text"
                name="NameOfSeeker"
                disabled
                value={rfp.nameOfSeeker}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                label="Address of Seeker"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                type="text"
                name="AddressOfSeeker"
                disabled
                value={rfp.addressOfSeeker}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                label="Address of Property"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                type="text"
                name="AddressOfProperty"
                disabled
                value={rfp.addressOfProperty}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                label="Type of Service"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                name="TypeOfService"
                disabled
                value={rfp.typeOfService}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                label="Service Provider"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                type="text"
                name="ServiceProvider"
                disabled
                value={rfp.serviceProvider}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                label="Time Period"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                name="TimePeriod"
                disabled
                value={rfp.timePeriod}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Typography sx={{paddingLeft: "20px"}}>
              Service Description
            </Typography>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                label="Describe what you are looking for"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                type="text"
                name="ServiceDescription"
                disabled
                value={rfp.serviceDescription}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              width: "50%",
              paddingLeft: "20px",
              paddingRight: "20px",
              height: "100%",
            }}
          >
            <Grid item xs={12}>
              <Box height="40px"></Box>
              <Box>
                <Typography paddingTop="20px" fontWeight="700">
                  Main RFP
                </Typography>
                {mainRFP && mainRFP.length > 0 ? (
                  <Card sx={{backgroundColor: "#F7F7F7"}}>
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MainRFPDocumentView mainRFP={mainRFP} />
                    </CardActions>
                  </Card>
                ) : (
                  <Card
                    sx={{
                      backgroundColor: "#F7F7F7",
                      padding: 1,
                      height: "300px",
                      width: "470px",
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      flexWrap: "wrap",
                    }}
                  >
                    No documents attached
                  </Card>
                )}
              </Box>
              <Box paddingTop="20px">
                <Typography fontWeight="700">Additional Documents</Typography>
                {rfpDocuments && rfpDocuments.length > 0 ? (
                  <Card sx={{backgroundColor: "#F7F7F7"}}>
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <RfpAdditionalDocumentsListCard
                        rfpDocuments={rfpDocuments}
                        name="x"
                      />
                    </CardActions>
                  </Card>
                ) : (
                  <Card
                    sx={{
                      backgroundColor: "#F7F7F7",
                      padding: 1,
                      height: "300px",
                      width: "470px",
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      flexWrap: "wrap",
                    }}
                  >
                    No documents attached
                  </Card>
                )}
              </Box>
            </Grid>
          </Grid>
        </Card>
        {user?.id === rfp.userId ? null : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              position: "sticky",
              backgroundColor: "#F5F5F5",
              bottom: 0,
            }}
          >
            <ChildModal setModalOpen={setOpen} modalState={open} />
            <Button
              sx={{
                background: "rgba(4, 119, 130, 1)",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "rgba(1, 150, 164, 1)",
                },
                "&:disabled": {
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#fff",
                borderRadius: "4px",
                letterSpacing: "1.25px",
                margin: "15px",
              }}
              onClick={handleResponseOffer}
            >
              Submit an Offer
            </Button>

            <Button
              onClick={(e) => {
                e.preventDefault();
                setOpen(true);
              }}
              sx={{
                background: "#fff",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#047782",
                letterSpacing: "1.25px",
                border: "1px solid #047782",
                borderRadius: "4px",
                margin: "15px",
              }}
            >
              Inquire
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default RequestForProposalOverview;
