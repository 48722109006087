import {Typography, Paper, Box} from "@mui/material";

interface Props {
  document: any;
  onSetModalOpen: any;
}

export default function DocumentViewCardOfferDocuments({
  document,
  onSetModalOpen,
}: Props) {
  return (
    <div>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: "200px",
          fontSize: "14px",
        }}
      >
        {document.documentTitle}
      </Typography>

      <Paper
        elevation={4}
        sx={{
          width: 150,
          height: 200,
          paddingX: 2.5,
          paddingY: 3,
          fontSize: "4px",
        }}
      >
        <Box onClick={() => onSetModalOpen(document)}>
          <Box
            sx={{
              wordWrap: "break-word",
              overflow: "hidden",
              height: 150,
            }}
          >
            {document.documentText}
          </Box>
        </Box>
      </Paper>
    </div>
  );
}
