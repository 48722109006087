import {useState, useEffect} from "react";
import agent from "../../../app/api/agent";
import {HtmlDocuments} from "../../../app/models/htmlDocument";
import {toast} from "react-toastify";
import DrawerSpeedDial from "./DrawerSpeedDial";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {Box} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import RFPDrawerWrapper from "../dealDrawer/RFPDrawerWrapper";
import RFPDocumentsOverview from "./RFPDocumentsOverview";
import RFPDocumentViewer from "./RFPDocumentViewer";
import RFPImageUploadModal from "../../../app/components/RFPImageUploadModal";
import RFPDocumentSelectModal from "../../../app/components/RFPDocumentSelectModal";

interface Props {
  serviceId: number;
}

const DealActionDrawerRFP = ({serviceId}: Props) => {
  const [documents, setDocuments] = useState<HtmlDocuments>();
  const [images, setImages] = useState();
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectDocumentWithId, setSelectDocumentWithId] = useState<number>();
  const [selectDocumentWithIdOneClick, setSelectDocumentWithIdOneClick] =
    useState<number>();
  const [documentFamilyId, setDocumentFamilyId] = useState({
    documentId: 0,
    file: "",
    documentName: "",
  });

  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [refetchDocuments, setRefetchDocuments] = useState(0);

  async function handleDeleteDocument(id: number) {
    if (id) {
      try {
        await agent.Document.deleteDocument(id)
          .then(() => setRefetchDocuments((prevCount) => prevCount + 1))
          .then(() => toast.success("Document deleted successfully"));
      } catch (error) {
        toast.error(<div>Failed to Delete Document. Try again later!</div>);
      }
    }
  }

  const handleSubmitImage = async () => {
    if (!selectedImage) return;

    const formData = new FormData();

    formData.append("FileImage", selectedImage);
    formData.append("RFPId", serviceId.toString());

    setLoading(true);
    try {
      await agent.Document.uploadImageforRFP(formData).then(() => {
        setOpenImageModal(false);
        setRefetchDocuments((prevCount) => prevCount + 1);
        toast.success("Image uploaded successfully");
      });
    } catch (error) {
      toast.error("Error uploading image");
    }
    setLoading(false);
  };

  const selectDocumentHandler = (documentId?: number) => {
    setSelectDocumentWithId(documentId);
  };

  const selectDocumentOneClickHandler = (documentId?: number) => {
    setSelectDocumentWithIdOneClick(documentId);
  };

  async function handleSubmitData() {
    const {documentId, file, documentName} = documentFamilyId;

    try {
      // Create and upload the document
      const document = {
        title: documentName,
        text: file,
        documentId: documentId,
      };

      const request = agent.Document.uploadHtmlDocumentForRFP;
      await request(serviceId, document).then();
      setOpenDocumentModal(false);
      setRefetchDocuments((prevCount) => prevCount + 1);
      toast.success("File uploaded successfully");
    } catch {
      toast.error(
        <div>
          File failed to upload.
          <br />
          Only docx files are allowed.
        </div>
      );
    }
  }

  const actionIcons = [
    {
      icon: (
        <DeleteOutlineIcon
          sx={{color: "#047782"}}
          onClick={() => handleDeleteDocument(selectDocumentWithIdOneClick!)}
        />
      ),
      name: "Delete the Document",
      show: true,
    },

    {
      icon: (
        <>
          <RFPImageUploadModal
            setModalOpen={setOpenImageModal}
            modalState={openImageModal}
            setSelectedImage={setSelectedImage}
            selectedImage={selectedImage}
            handleSubmitImage={handleSubmitImage}
          />
          <InsertPhotoIcon
            sx={{
              color: "#047782",
              display: "flex",
              alignItems: "center",
              alignContent: "center",
            }}
            onClick={(e: any) => {
              e.preventDefault();
              setOpenImageModal(true);
            }}
          />
        </>
      ),
      name: "Upload an Image",
      show: true,
    },

    {
      icon: (
        <>
          <RFPDocumentSelectModal
            setModalOpen={setOpenDocumentModal}
            modalState={openDocumentModal}
            setDocumentFamilyId={setDocumentFamilyId}
            handleSubmitData={handleSubmitData}
          />
          <CloudUploadIcon
            sx={{
              color: "#047782",
              display: "flex",
              alignItems: "center",
              alignContent: "center",
            }}
            onClick={(e: any) => {
              e.preventDefault();
              setOpenDocumentModal(true);
            }}
          />
        </>
      ),
      name: "Upload a Document",
      show: true,
    },
  ];

  useEffect(() => {
    if (!serviceId) return;

    agent.Document.getHtmlDocumentsForRfp(serviceId)
      .then((response) => {
        setDocuments(response);
      })
      .catch((error) => console.log(error.response))
      .finally(() => setLoading(false));
  }, [serviceId, refetchDocuments]);

  useEffect(() => {
    if (!serviceId) return;
    agent.Document.getImagesForRfp(serviceId).then((response) =>
      setImages(response.$values)
    );
  }, [serviceId, refetchDocuments]);

  return (
    <>
      <RFPDrawerWrapper>
        {selectDocumentWithId && (
          <RFPDocumentViewer
            document={
              documents!.$values.find(
                (d: any) => d.id === selectDocumentWithId
              )!
            }
            onClickToGoBack={selectDocumentHandler}
          />
        )}
        {!loading && !selectDocumentWithId && (
          <RFPDocumentsOverview
            documents={documents!}
            onDocumentSelect={selectDocumentHandler}
            selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
            onSelectDocumentOneClick={selectDocumentOneClickHandler}
            images={images}
            setRefetchDocuments={setRefetchDocuments}
          />
        )}
        {!loading && (
          <Box position="relative">
            <DrawerSpeedDial actionIcons={actionIcons} />
          </Box>
        )}
      </RFPDrawerWrapper>
    </>
  );
};

export default DealActionDrawerRFP;
