import { Box, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface Props {
  mainRFP: any;
}

export default function MainRFPDocumentView({ mainRFP }: Props) {
  return (
    <div>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: "200px",
          whiteSpace: "nowrap",
        }}
      >
        {"Main RFP Document"}
      </Typography>
      <Paper
        elevation={4}
        sx={{
          width: 150,
          height: 200,
          paddingX: 2.5,
          paddingY: 3,
          fontSize: "4px",
        }}
      >
        <Link to={mainRFP} style={{ textDecoration: "none" }}>
          <Box
            sx={{
              wordWrap: "break-word",
              overflow: "hidden",
              height: 212,
              textDecoration: "none",
            }}
          >
            {
              "Lorem ipsum dolor sit amet. In iste atque sed omnis quam aut quia quibusdam id soluta aliquid? Et dignissimos voluptatem sit tempore voluptas eos itaque voluptatem rem perspiciatis ipsa."
            }
            {
              "Ex facere voluptates et aliquam impedit ut provident voluptatibus ut nesciunt doloribus hic repellat possimus qui molestiae quisquam. Ut adipisci quia sit ipsa rerum aut excepturi quidem ab omnis laborum. Est itaque iusto ut asperiores excepturi ut illum vero non neque voluptatem est architecto dolorem sed voluptas voluptas."
            }
            {
              "Aut sunt earum non omnis odit et voluptas officiis ut laudantium necessitatibus. Ab possimus dolorem et quia minus qui odio dolores. Non dicta dolorum sed iste autem et consequuntur modi cum quia omnis qui accusamus fugit eos nisi neque. Aut possimus asperiores ut dolorem velit At sunt porro sed esse repellat et quia inventore et aliquid voluptas qui numquam eligendi."
            }
          </Box>
        </Link>
      </Paper>
    </div>
  );
}
