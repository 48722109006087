import {Grid, Typography} from "@mui/material";
import PropertyInfo from "../../app/models/propertyInfo";

interface Props {
  notification: PropertyInfo;
}

export function NotificationBody({notification}: Props) {
  return (
    <Grid container>
      <Grid item xs={4}>
        {" "}
        <img
          src={notification.mainImageUrl}
          alt=""
          style={{
            width: "140px",
            height: "90px",
            padding: "10px",
            paddingBottom: 0,
          }}
        />
      </Grid>
      <Grid item xs={8}>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 500,
            paddingTop: "10px",
            paddingLeft: "10px",
          }}
        >
          {notification.notificationText}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            paddingTop: "5px",
            paddingLeft: "10px",
            color: "#757575",
          }}
        >
          #{notification.dealNumber} | {notification.propertyAddress}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",

            paddingLeft: "10px",
            color: "#757575",
          }}
        >
          Deal Progress # {notification.contractProgressId}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{paddingLeft: "10px"}}>
        <Typography sx={{fontSize: "13px"}}>What happened?</Typography>
        <Typography sx={{fontSize: "13px", fontWeight: 600}}>
          {notification.userRoleName} {notification.actionType} in{" "}
          {notification.documentFamily}
        </Typography>
      </Grid>
    </Grid>
    // <Box sx={{paddingLeft: "10px"}}>
    //   <Typography
    //     sx={{
    //       fontWeight: 700,
    //       fontSize: "18px",
    //       display: "flex",
    //       alignItems: "center",
    //     }}
    //   >
    //     Your Deal
    //     <ChevronRightIcon sx={{fontSize: "23px"}} />
    //     {notification.propertyAddress}
    //   </Typography>
    //   <Typography sx={{fontSize: "13px", paddingBottom: "10px"}}>
    //     {notification.propertyCity}, &nbsp;
    //     {notification.propertyState}
    //   </Typography>

    //   <Typography sx={{fontSize: "13px"}}>
    //     Pay attention to your next Task
    //   </Typography>
    //   <Typography
    //     sx={{fontSize: "15px", fontWeight: 600, paddingBottom: "10px"}}
    //   >
    //     {notification.notificationText}
    //   </Typography>

    //   <Typography sx={{fontSize: "13px"}}>What happened?</Typography>
    //   <Typography sx={{fontSize: "13px", fontWeight: 600}}>
    //     {notification.userRoleName} {notification.actionType} in{" "}
    //     {notification.documentFamily}
    //   </Typography>
    // </Box>
  );
}
