import {Navigate, Outlet, useLocation} from "react-router-dom";
import {toast} from "react-toastify";
import {useAppSelector} from "../store/configureStore";

export default function RequireAuth() {
  const {user} = useAppSelector((state) => state.account);
  const location = useLocation();

  //--------------------samo za sega, da se promeni
  console.log("user", user);
  console.log("username", user?.userName);

  var rolesForNow = user?.userName === "admin" ? "Admin" : "";

  if (!user) {
    return <Navigate to="/login" state={{from: location}} />;
  } else {
    return <Outlet />;
  }

  if (rolesForNow !== "Admin") {
    toast.error("Not authorised to access this area");
    return <Navigate to="/" />;
  }

  //--------------------ova treba da se vluci vo promenata
  //   if (roles && !roles.some((r) => user.roles?.includes(r))) {
  //     toast.error("Not authorised to access this area");
  //     return <Navigate to="/" />;
  //   }

  return <Outlet />;
}
