import {createBrowserRouter, Navigate} from "react-router-dom";
import Login2 from "../../features/account/Login";
import RegisterMUI from "../../features/account/Register";
import MarketplaceMUI from "../../features/marketplace/Marketplace";
import RequestForProposal from "../../features/rfps/CreateRFP";
import PhaseEditor from "../../features/phase/PhaseEditor";
import CreatePartnerMUI from "../../features/users/CreatePartner";
import UserTypes from "../../features/users/UserTypes";
import NotFound from "../errors/NotFound";
import ServerError from "../errors/ServerError";
import App from "../layout/App";
import RequireAuth from "./RequireAuth";
import AuthorizationWrapper from "../../features/account/AuthorizationWrapper";
import NewProperty from "../../features/properties/NewProperty";
import CreateProfile from "../../features/account/CreateProfile";
import EditProperty from "../../features/properties/EditProperty";
import CreateRFPDetailsView from "../../features/rfps/RfpOverview";
import RequestForProposalResponse from "../../features/rfps/RFPResponse";
import InquiryRFPViewPage from "../../features/inquiries/InquiryRFPViewPage";
import InquiryPropertyViewPage from "../../features/inquiries/InquiryPropertyViewPage";
import OfferPageProperty from "../../features/offers/OfferPageProperty";
import OfferPropertyViewPage from "../../features/offers/OfferPropertyViewPage";
import DealPage from "../../features/deal/dealOverview/DealPage";
import DealOverviewDetails from "../../features/deal/dealOverview/DealOverviewDetails";
import ServiceCardDetailsView from "../../features/deal/dealOverview/ServiceCardDetailsView";
import PropertyOverview from "../../features/properties/PropertyOverview";
import OfferRFPResponseViewPage from "../../features/offers/OfferRFPResponseViewPage";
import LenderOffers from "../../features/offers/LenderOffersPageMarketplace";
import CreateLenderOffer from "../../features/offers/CreateLenderOffer";
import LenderOfferViewPage from "../../features/offers/LenderOfferViewPageMarketplace";
import LenderOfferViewPageMyDeals from "../../features/offers/LenderOfferViewPageMyDeals";
import LenderOffersPageDeals from "../../features/offers/LenderOffersPageDeals";
import FeatureToBeImplementedSoonPage from "../components/FeatureToBeImplementedSoonPage";
import PropertyInterestPageMarketplace from "../../features/properties/PropertyInterestPageMarketplace";
import PropertyOverviewNotLoggedUser from "../../features/properties/PropertyOverviewNotLoggedUser";
import EditProfile from "../../features/account/EditProfile";
import PropertyServiceResponse from "../../features/properties/PropertyServiceResponse";
import RfpResponseOffersAndInquiries from "../../features/rfps/RfpResponseOffersAndInquiries";
import PropertyServiceResponseFilled from "../../features/properties/PropertyServiceResponseViewPage";
import PrivacyPolicyPage from "../components/PrivacyPolicyPage";
import DealServiceResponseViewPage from "../../features/properties/DealServiceResponseViewPage";
import SupportPage from "../components/Support";
import StatisticsPage from "../components/StatisticsPage";
import MyMarketplace from "../../features/marketplace/MyMarketplace";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      // admin routes
      {
        element: <RequireAuth />,
        children: [
          {path: "user-types", element: <UserTypes />},
          {path: "phase", element: <PhaseEditor />},
        ],
      },
      {path: "", element: <MarketplaceMUI />},

      {path: "property/det/:id", element: <PropertyOverview />},
      {
        path: "property/nouser/det/:id",
        element: <PropertyOverviewNotLoggedUser />,
      },
      {path: "new-property", element: <NewProperty />},
      {path: "edit-property/:id", element: <EditProperty />},
      {path: "marketplace", element: <MarketplaceMUI />},
      {path: "marketplace/my-marketplace", element: <MyMarketplace />},
      {path: "propertyserviceoffer/:id", element: <PropertyServiceResponse />},
      {
        path: "propertyserviceofferfilled/:id",
        element: <PropertyServiceResponseFilled />,
      },
      {
        path: "dealserviceofferfilled/:id/:contractId",
        element: <DealServiceResponseViewPage />,
      },

      {path: "requestforproposal", element: <RequestForProposal />},
      {
        path: "requestforproposal/det/:id",
        element: <CreateRFPDetailsView />,
      },
      {path: "responsetorfp/:id", element: <RequestForProposalResponse />},

      {path: "deals", element: <DealPage />},

      {
        path: "deals/overview/:propertyId/:contractId",
        element: <DealOverviewDetails />,
      },
      {path: "deals/service/det/:id", element: <ServiceCardDetailsView />},

      {path: "add-partners", element: <CreatePartnerMUI />},

      {path: "reports", element: <FeatureToBeImplementedSoonPage />},
      {path: "library", element: <FeatureToBeImplementedSoonPage />},
      {path: "dashboard", element: <FeatureToBeImplementedSoonPage />},
      {path: "statistics", element: <StatisticsPage />},

      {
        path: "offer/property/:id/:contractProgressId",
        element: <OfferPageProperty />,
      },
      {
        path: "offer/propertyfilled/:offerId/:propertyId",
        element: <OfferPropertyViewPage />,
      },
      {
        path: "property/viewlenderoffer/:id",
        element: <LenderOfferViewPage />,
      },
      {
        path: "deals/viewlenderoffer/:id/:contractId",
        element: <LenderOfferViewPageMyDeals />,
      },
      {
        path: "property/serviceoffers/:id",
        element: <LenderOffers handleFavoriteChange={handleFavoriteChange} />,
      },
      {
        path: "rfp/rfpinterests/:id",
        element: (
          <RfpResponseOffersAndInquiries
            handleFavoriteRfpChange={function (rfpId: any): void {
              throw new Error("Function not implemented.");
            }}
          />
        ),
      },
      {
        path: "marketplace/property/interests/:id",
        element: <PropertyInterestPageMarketplace />,
      },
      {
        path: "deals/lenderoffers/:propertyId/:contractId",
        element: <LenderOffersPageDeals />,
      },
      {
        path: "property/createlenderoffer/:id",
        element: <CreateLenderOffer />,
      },

      {path: "offer/rfpfilled/:id", element: <OfferRFPResponseViewPage />},

      {path: "inquiry/rfp/:id", element: <InquiryRFPViewPage />},
      {path: "inquiry/property/:id", element: <InquiryPropertyViewPage />},

      {path: "server-error", element: <ServerError />},
      {path: "not-found", element: <NotFound />},

      {
        path: "login",
        element: (
          <AuthorizationWrapper>
            <Login2 />
          </AuthorizationWrapper>
        ),
      },
      {
        path: "register",
        element: (
          <AuthorizationWrapper>
            <RegisterMUI />{" "}
          </AuthorizationWrapper>
        ),
      },
      {path: "create-profile", element: <CreateProfile />},
      {path: "edit-profile", element: <EditProfile />},
      {path: "privacy-policy", element: <PrivacyPolicyPage />},
      {path: "support", element: <SupportPage />},

      {path: "*", element: <Navigate replace to="/not-found" />},
    ],
  },
]);

//Just for putting the prop in LenderOffers

function handleFavoriteChange(propertyId: any): void {
  throw new Error("Function not implemented.");
}
