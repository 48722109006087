import React from 'react'
import { useLocation } from 'react-router-dom';

const ServerError = () => {
    const {state} = useLocation();
  return (
    <>
        {state?.error ? (
            <>
                <h3>
                    {state.error.title}
                </h3>
                <hr />
                <div>{state.error.detail || 'Internal server error'}</div>
            </>
        ) : (
            <h4>Server error</h4>
        )} 
    </>
  )
}

export default ServerError