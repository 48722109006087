import {FormControl, MenuItem, FormHelperText, TextField} from "@mui/material";
import {useController, UseControllerProps} from "react-hook-form";

interface Props extends UseControllerProps {
  label?: string;
  items: string[];
  value?: any;
  required?: boolean;
  disabled?: boolean;
  onChange?: (...event: any[]) => void;
}

export default function AppSelectList2(props: Props) {
  const {fieldState, field} = useController({...props, defaultValue: ""});
  return (
    <FormControl required fullWidth error={!!fieldState.error}>
      <TextField
        select
        value={field.value}
        label={props.label}
        //  onChange={field.onChange}
        onChange={(e) => {
          field.onChange(e);
          if (props.onChange) {
            props.onChange(e); // Call the provided onChange prop
          }
        }}
        fullWidth
        variant="standard"
        disabled={props.disabled}
      >
        {props.items &&
          props.items.map((item, index) => (
            <MenuItem value={item} key={index}>
              {item}
            </MenuItem>
          ))}
      </TextField>
      <FormHelperText>{fieldState.error?.message}</FormHelperText>
    </FormControl>
  );
}
