import {Grid} from "@mui/material";
import DocumentViewCard from "./DocumentViewCard";
import {UseControllerProps} from "react-hook-form";

interface Props extends UseControllerProps {
  rfpDocuments: never[];
}

export default function RfpAdditionalDocumentsListCard({rfpDocuments}: Props) {
  return (
    <Grid container spacing={1} margin="auto">
      {rfpDocuments.map((document: any) => (
        <Grid item key={document.id}>
          <DocumentViewCard document={document} />
        </Grid>
      ))}
    </Grid>
  );
}
