import {Form, NavLink, useNavigate} from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Typography,
  Grid,
  Input,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {useAppDispatch} from "../../app/store/configureStore";
import {signInUser} from "./accountSlice";
import {FieldValues, useForm} from "react-hook-form";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import React from "react";
import {getNotifications} from "../../app/store/notificationSlice";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: {isSubmitting, errors},
  } = useForm();

  async function submitForm(data: FieldValues) {
    try {
      await dispatch(signInUser(data))
        .then((response: any) => {
          !response.payload.isConfirmed
            ? navigate("/create-profile")
            : navigate("/marketplace");
        })
        .then(() => dispatch(getNotifications()));
    } catch (error) {
      console.log(error);
    }
  }
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({...values, showPassword: !values.showPassword});
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handlePasswordChange = (event: any) => {
    setValues({...values, password: event.target.value});
  };

  return (
    <Container
      component="main"
      maxWidth="xl"
      sx={{display: "flex", justifyContent: "center"}}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "absolute",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          backgroundImage: "url(../../images/sean-pollock-new.jpg)",
          backgroundSize: "cover",
        }}
      >
        <Grid
          sx={{
            width: "460px",
            height: "384px",
            backgroundColor: "white",
            padding: "30px",
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            sx={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "23px",
              lineHeight: "32px",
              color: "#424242",
              paddingBottom: "10px",
            }}
          >
            Welcome back to Domunus
          </Typography>

          <Typography
            component="p"
            variant="h6"
            sx={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "20px",
              lineHeight: "32px",
              letterSpacing: "0.25px",
              color: "#757575",
              paddingBottom: "25px",
            }}
          >
            Log in to your account
          </Typography>

          <Box onSubmit={handleSubmit(submitForm)}>
            <Form onSubmit={handleSubmit(submitForm)}>
              <Input
                sx={{width: "100%", color: "#000000", paddingBottom: "10px"}}
                type="email"
                {...register("email", {required: "Email is required"})}
                placeholder="Email Address"
                autoFocus
              />
              {errors && (
                <Typography sx={{color: "error.main"}}>
                  {!!errors.email ? (errors?.email?.message as string) : ""}
                </Typography>
              )}
              <Input
                sx={{width: "100%", paddingBottom: "10px"}}
                {...register("password", {required: "Password is required"})}
                placeholder="Password"
                type={values.showPassword ? "text" : "password"}
                onInput={handlePasswordChange}
                value={values.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors && (
                <Typography sx={{color: "error.main"}}>
                  {!!errors.password
                    ? (errors?.password?.message as string)
                    : ""}
                </Typography>
              )}
              <Grid
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <NavLink
                  to="/register"
                  style={{
                    marginTop: "70px",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontSize: "14px",
                    fontWeight: "500",
                    letterSpacing: "1.25px",
                    lineHeight: "36px",
                    textAlign: "center",
                    marginRight: "25px",
                    textTransform: "uppercase",
                    color: "#047782",
                  }}
                >
                  Not a user? Sign up
                </NavLink>

                <Button
                  sx={{
                    marginTop: "70px",
                    width: "119px",
                    alignItems: "center",
                    background: "#047782",
                    "&:hover": {
                      backgroundColor: "#047782",
                    },
                    borderRadius: "4px",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontSize: "14px",
                    fontWeight: "500",
                    letterSpacing: "1.25px",
                    textAlign: "center",
                  }}
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Loading..." : "Sign In"}
                </Button>
              </Grid>
            </Form>
          </Box>
        </Grid>
      </Box>
    </Container>
  );
};

export default Login;
