import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Box, Card, Checkbox} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import agent from "../../../app/api/agent";
import LoaderWithLinearProgress from "../../../app/components/LoaderWithLinearProgress";
import {useAppSelector} from "../../../app/store/configureStore";
import {Property} from "../../../app/models/property";
import {RFP} from "../../../app/models/rfp";

interface Props {
  dealId: number;
  isServiceCard: boolean;
}

const InquiriesTable = ({dealId, isServiceCard}: Props) => {
  const {user} = useAppSelector((state) => state.account);
  const [inquiries, setInquiries] = useState<Array<any>>([]);
  const [property, setProperty] = useState<Property>();
  const [rfp, setRfp] = useState<RFP>();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const {id} = useParams();

  useEffect(() => {
    agent.Deals.oneDealProperty(dealId)
      .then((response) => {
        setProperty(response);
      })
      .catch((error) => console.log(error.response))
      .finally(() => setLoading(false));
  }, [dealId]);

  useEffect(() => {
    id &&
      agent.RFPs.RFPDetails(parseInt(id))
        .then((response) => {
          setRfp(response);
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
  }, [id]);

  const handleInquiry = (inquiryId: any) => {
    isServiceCard
      ? navigate(`/inquiry/rfp/${inquiryId}`)
      : navigate(`/inquiry/property/${inquiryId}`);
  };

  useEffect(() => {
    setLoading(true);
    let request;

    request = isServiceCard
      ? agent.InquriesRFP.allRFPInquiries
      : agent.InquriesProperty.allPropertyInquiries;

    request(dealId)
      .then((response) => {
        setInquiries(response.$values);
      })
      .catch((error) => console.log(error.response))
      .finally(() => setLoading(false));
  }, [dealId, isServiceCard]);

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <Card sx={{height: "calc(100% - 54.5px)"}}>
      {inquiries && inquiries.length > 0 ? (
        <Box sx={{display: "flex", flexDirection: "column"}}>
          <Card
            sx={{
              display: "inline-flex",
              width: "100%",
              backgroundColor: "#FFF",
              border: "none",
              boxShadow: "none",
            }}
          >
            <Table sx={{minWidth: 650}}>
              <TableHead>
                <TableRow>
                  <TableCell width="5.5%">
                    <Checkbox />
                  </TableCell>
                  <TableCell width="13.5%">
                    <ArrowUpwardIcon /> First Name
                  </TableCell>
                  <TableCell width="13.5%">
                    <ArrowUpwardIcon /> Last Name
                  </TableCell>
                  <TableCell width="13.5%"> Inquiry Type</TableCell>
                  <TableCell width="13.5%"> Offer Price (optional) </TableCell>
                  <TableCell width="40.5%"> Message</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Card>

          {isServiceCard ? (
            user?.id === rfp?.userId ? (
              inquiries.map((inquiry) => (
                <Card
                  sx={{
                    display: "inline-flex",
                    width: "100%",
                    backgroundColor: "#FFF",
                    border: "none",
                    boxShadow: "none",
                  }}
                  key={inquiry.id}
                >
                  <Table sx={{minWidth: 650}}>
                    <TableBody>
                      <TableRow onClick={() => handleInquiry(inquiry.id)}>
                        <TableCell width="5.5%" component="th" scope="row">
                          <Checkbox />
                        </TableCell>
                        <TableCell width="13.5%">{inquiry.firstName}</TableCell>
                        <TableCell width="13.5%">{inquiry.lastName}</TableCell>
                        <TableCell width="13.5%">
                          {inquiry.inquiryType}
                        </TableCell>
                        <TableCell width="13.5%">
                          {inquiry.offerPrice}
                        </TableCell>
                        <TableCell width="40.5%">
                          {inquiry.inquiryMessage}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              ))
            ) : (
              <Card
                sx={{
                  boxShadow: "none",
                  backgroundColor: "#FFF",
                  width: "100%",
                  padding: "10px",
                  marginLeft: "10px",
                }}
              >
                There are no inquiries
              </Card>
            )
          ) : user?.id === property?.userId ? (
            inquiries.map((inquiry) => (
              <Card
                sx={{
                  display: "inline-flex",
                  width: "100%",
                  backgroundColor: "#FFF",
                  border: "none",
                  boxShadow: "none",
                }}
                key={inquiry.id}
              >
                <Table sx={{minWidth: 650}}>
                  <TableBody>
                    <TableRow onClick={() => handleInquiry(inquiry.id)}>
                      <TableCell width="5.5%" component="th" scope="row">
                        <Checkbox />
                      </TableCell>
                      <TableCell width="13.5%">{inquiry.firstName}</TableCell>
                      <TableCell width="13.5%">{inquiry.lastName}</TableCell>
                      <TableCell width="13.5%">{inquiry.inquiryType}</TableCell>
                      <TableCell width="13.5%">{inquiry.offerPrice}</TableCell>
                      <TableCell width="40.5%">
                        {inquiry.inquiryMessage}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
            ))
          ) : (
            <Card
              sx={{
                boxShadow: "none",
                backgroundColor: "#FFF",
                width: "100%",
                padding: "10px",
                marginLeft: "10px",
              }}
            >
              There are no inquiries
            </Card>
          )}
        </Box>
      ) : (
        <Card
          sx={{
            boxShadow: "none",
            backgroundColor: "#FFF",
            width: "100%",
            padding: "10px",
            marginLeft: "10px",
          }}
        >
          There are no inquiries
        </Card>
      )}
    </Card>
  );
};

export default InquiriesTable;
