import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {useEffect, useState} from "react";
import agent from "../api/agent";

export default function StatisticsPage() {
  const [userList, setUserList] = useState([]);
  const [uniqueDealProgresses, setUniqueDealProgresses] = useState();
  const [progressStatuses, setProgressStatuses] = useState([]);

  useEffect(() => {
    agent.Statistics.userList().then((response) =>
      setUserList(response.$values)
    );
    agent.Statistics.progressStatuses().then((response) =>
      setProgressStatuses(response.$values)
    );
    agent.Statistics.uniqueDealProgresses().then((response) =>
      setUniqueDealProgresses(response)
    );
  }, []);

  return (
    <>
      <Typography sx={{margin: 1, fontSize: "20px", fontWeight: 500}}>
        Domunus Statistics:
      </Typography>

      <Typography sx={{margin: 1, fontSize: "18px", fontWeight: 400}}>
        User List - Total Number ({userList.length}):
      </Typography>

      <TableContainer component={Paper}>
        <Table
          sx={{minWidth: 650}}
          size="small"
          stickyHeader
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">First and Last Name</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Address</TableCell>
              <TableCell align="left">Profession</TableCell>
              <TableCell align="left">Company Name</TableCell>
              <TableCell align="left">Phone Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList &&
              userList.map((user: any) => (
                <TableRow
                  key={user.id}
                  sx={{"&:last-child td, &:last-child th": {border: 0}}}
                >
                  <TableCell component="th" scope="row" align="left">
                    {user.firstName} {user.lastName}
                  </TableCell>
                  <TableCell align="left"> {user.email}</TableCell>
                  <TableCell align="left">
                    {user.address}, {user.city},{user.postalCode}, {user.state},{" "}
                    {user.country}
                  </TableCell>
                  <TableCell align="left"> {user.profession} </TableCell>
                  <TableCell align="left"> {user.companyName}</TableCell>
                  <TableCell align="left"> {user.phoneNumber}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography sx={{margin: 2, fontSize: "20px", fontWeight: 400}}>
        Deal Progress Statuses - Total unique Deal Progresses (
        {uniqueDealProgresses}):
      </Typography>

      <TableContainer component={Paper}>
        <Table
          sx={{minWidth: 650}}
          size="small"
          stickyHeader
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">Deal Progress ID</TableCell>
              <TableCell align="left">
                Date&Time of last completed task
              </TableCell>
              <TableCell align="left">Last Completed Task Id</TableCell>
              <TableCell align="left">Current Phase of Deal Progress</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {progressStatuses &&
              progressStatuses.map((progresStatus: any) => (
                <TableRow
                  key={progresStatus.id}
                  sx={{"&:last-child td, &:last-child th": {border: 0}}}
                >
                  <TableCell component="th" scope="row" align="left">
                    {progresStatus.contractProgressId}
                  </TableCell>
                  <TableCell align="left"> {progresStatus.dateTime}</TableCell>
                  <TableCell align="left">
                    {progresStatus.mvpTaskMessageId}
                  </TableCell>
                  <TableCell align="left"> {progresStatus.phaseId} </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
