import {Typography, Paper, Box} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

interface Props {
  // document: HtmlDocument;
  onDocumentSelect: (id: number) => void;
  selectDocumentWithIdOneClick: number | undefined;
  onSelectDocumentOneClick: (documentId?: number) => void;
  dealId: number;
  progressTracker: never[];
  contractProgressId: any;
}

export default function OfferFormCard({
  dealId,
  progressTracker,
  contractProgressId,
}: Props) {
  const navigate = useNavigate();

  function handleClick() {
    if (progressTracker.length > 5) {
      navigate(`/offer/property/${dealId}/${contractProgressId}`);
    } else {
      toast.error(
        "Please complete and approve all the necessary documents. The Offer Form will then be available to fill out and submit."
      );
    }
  }

  return (
    <div
      onClick={() => handleClick()}
      style={{
        opacity: progressTracker.length > 5 ? 1 : 0.5,
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: "200px",
          whiteSpace: "nowrap",
        }}
      >
        Offer Form
      </Typography>

      <Paper
        elevation={4}
        sx={{
          width: 200,
          height: 260,
          paddingX: 2.5,
          paddingY: 3,
          fontSize: "4px",
        }}
      >
        <Box
          sx={{
            wordWrap: "break-word",
            overflow: "hidden",
            height: 212,
            fontSize: "8px",
          }}
        >
          Offer Form <br /> <br />
          Lorem ipsum dolor sit amet consectetur. Consequat feugiat eleifend
          lobortis risus aliquam. Amet nunc maecenas mi elit erat ipsum orci
          consectetur. Sapien nec diam sit at commodo tincidunt turpis. Lacus
          convallis tortor et tristique. Summary Lorem ipsum dolor sit amet
          consectetur. <br /> <br />
          Consequat feugiat eleifend lobortis risus aliquam. Amet nunc maecenas
          mi elit erat ipsum orci consectetur. Sapien nec diam sit at commodo
          tincidunt turpis. Lacus convallis tortor et tristique.
        </Box>
      </Paper>
    </div>
  );
}
