import {Box, Typography} from "@mui/material";

export default function VideoMeetings() {
  return (
    <Box
      sx={{
        backgroundColor: "#FFF",
        height: "69.5vh",
        width: "385px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: "25px",
          textAlign: "center",
          paddingX: "10px",
        }}
      >
        Video Meetings feature is coming soon...
      </Typography>
    </Box>
  );
}
