import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Box, Card} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {useAppSelector} from "../store/configureStore";
import LoaderWithLinearProgress from "./LoaderWithLinearProgress";
import {Property} from "../models/property";
import agent from "../api/agent";
import {toast} from "react-toastify";
import LinkIcon from "@mui/icons-material/Link";

const LenderOffersTableMarketplace = () => {
  const {user} = useAppSelector((state) => state.account);
  const {id} = useParams<{id: string}>();
  const [property] = useState<Property>();
  const [lenderOffers, setLenderOffers] = useState<Array<any>>([]);
  const [myLenderOffers, setMyLenderOffers] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleOffer = (lenderOfferId: any) => {
    navigate(`/property/viewlenderoffer/${lenderOfferId}`);
  };

  useEffect(() => {
    id &&
      agent.LenderOffers.allLenderOffers(parseInt(id))
        .then((response) => {
          setLenderOffers(response.$values);
          setMyLenderOffers(response.$values);
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
  }, [id]);

  function handleShareOffer(url: any, e: any) {
    e.preventDefault();
    let currentURL = window.location.hostname;

    var withoutProtocol = currentURL.replace(/^https?:\/\//, "");

    // Remove path and query string
    var domain = withoutProtocol.split("/")[0];

    // Remove port number if present
    domain = domain.split(":")[0];

    let fullAddress = domain.concat(url);

    navigator.clipboard.writeText(fullAddress).then(
      () => {
        toast.success("The offer link is copied!");
      },
      () => {
        toast.error("Failed to copy. Please try again later.");
      }
    );
  }

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <Card sx={{height: "calc(100% - 54.5px)", overflowY: "scroll"}}>
      {lenderOffers && lenderOffers.length > 0 ? (
        <Box sx={{display: "flex", flexDirection: "column"}}>
          <Card
            sx={{
              display: "inline-flex",
              width: "100%",
              backgroundColor: "#FFF",
              border: "none",
              boxShadow: "none",
            }}
          >
            <Table sx={{minWidth: 650}}>
              <TableHead>
                <TableRow>
                  <div style={{display: "flex"}}>
                    <TableCell width="14.28%">Name of Lender</TableCell>
                    <TableCell width="14.28%">Loan Amount</TableCell>
                    <TableCell width="14.28%">Percent Down</TableCell>
                    <TableCell width="14.28%">Loan Term</TableCell>
                    <TableCell width="14.28%">APR</TableCell>
                    <TableCell width="14.28%">Closing Cost</TableCell>
                    <TableCell width="14.28%">Points In</TableCell>
                  </div>
                  <TableCell width="10%"> Share Link</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Card>

          {user?.id === property?.userId
            ? lenderOffers.map((lenderOffer) => {
                return (
                  <Card
                    sx={{
                      display: "inline-flex",
                      width: "100%",
                      backgroundColor: "#FFF",
                      border: "none",
                      boxShadow: "none",
                    }}
                    key={lenderOffer.id}
                  >
                    <Table sx={{minWidth: 650}}>
                      <TableBody>
                        <TableRow sx={{display: "flex"}}>
                          <div
                            style={{display: "flex", width: "90%"}}
                            onClick={() => handleOffer(lenderOffer.id)}
                          >
                            <TableCell width="14.28%">
                              {lenderOffer.nameOfLender}
                            </TableCell>
                            <TableCell width="14.28%">
                              £{lenderOffer.loanAmount}
                            </TableCell>
                            <TableCell width="14.28%">
                              {lenderOffer.percentDown}
                            </TableCell>
                            <TableCell width="14.28%">
                              {lenderOffer.loanTerm}
                            </TableCell>
                            <TableCell width="14.28%">
                              {lenderOffer.annualPercentageRate}
                            </TableCell>
                            <TableCell width="14.28%">
                              {lenderOffer.closingCost}
                            </TableCell>
                            <TableCell width="14.28%">
                              {lenderOffer.pointsIn}
                            </TableCell>
                          </div>
                          <div style={{display: "flex", width: "10%"}}>
                            <TableCell width="100%">
                              <LinkIcon
                                onClick={(e) =>
                                  handleShareOffer(
                                    `/property/viewlenderoffer/${lenderOffer.id}`,
                                    e
                                  )
                                }
                              />
                            </TableCell>
                          </div>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Card>
                );
              })
            : myLenderOffers.map((lenderOffer) => (
                <Card
                  sx={{
                    display: "inline-flex",
                    width: "100%",
                    backgroundColor: "#FFF",
                    border: "none",
                    boxShadow: "none",
                  }}
                  key={lenderOffer.id}
                >
                  <Table sx={{minWidth: 650}}>
                    <TableBody>
                      <TableRow sx={{display: "flex"}}>
                        <div
                          style={{display: "flex", width: "90%"}}
                          onClick={() => handleOffer(lenderOffer.id)}
                        >
                          <TableCell width="14.28%">
                            {lenderOffer.nameOfLender}
                          </TableCell>
                          <TableCell width="14.28%">
                            £{lenderOffer.loanAmount}
                          </TableCell>
                          <TableCell width="14.28%">
                            {lenderOffer.percentDown}
                          </TableCell>
                          <TableCell width="14.28%">
                            {lenderOffer.loanTerm}
                          </TableCell>
                          <TableCell width="14.28%">
                            {lenderOffer.annualPercentageRate}
                          </TableCell>
                          <TableCell width="14.28%">
                            {lenderOffer.closingCost}
                          </TableCell>
                          <TableCell width="14.28%">
                            {lenderOffer.pointsIn}
                          </TableCell>
                        </div>
                        <div style={{display: "flex", width: "10%"}}>
                          <TableCell width="100%">
                            <LinkIcon
                              onClick={(e) =>
                                handleShareOffer(
                                  `/property/viewlenderoffer/${lenderOffer.id}`,
                                  e
                                )
                              }
                            />
                          </TableCell>
                        </div>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              ))}
        </Box>
      ) : (
        <Card
          sx={{
            boxShadow: "none",
            backgroundColor: "#FFF",
            width: "100%",
            padding: "10px",
            marginLeft: "10px",
          }}
        >
          There are no offers
        </Card>
      )}
    </Card>
  );
};

export default LenderOffersTableMarketplace;
