import {MessageCenterState} from "../../features/messageCenter/messageCenterSlice";
import {MessagesForGroup} from "../models/messageCenter";
import {User} from "../models/user";

export function getCookie(key: string) {
  const b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

export function currencyFormat(amount: number) {
  return "£" + (amount / 100).toFixed(2);
}

export function relativeDate(timestamp: string) {
  const diff = Math.round((Date.now() - new Date(timestamp).getTime()) / 1000);

  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30;
  const year = month * 12;

  if (diff < 30) {
    return "Just now";
  } else if (diff < 2 * minute) {
    return "A minute ago";
  } else if (diff < hour) {
    return Math.floor(diff / minute) + " minutes ago";
  } else if (Math.floor(diff / hour) === 1) {
    return "1 hour ago";
  } else if (diff < day) {
    return Math.floor(diff / hour) + " hours ago";
  } else if (diff < day * 2) {
    return "Yesterday";
  } else if (diff < week) {
    return Math.floor(diff / day) + " days ago";
  } else if (Math.floor(diff / week) === 1) {
    return "1 week ago";
  } else if (diff < month) {
    return Math.floor(diff / week) + " weeks ago";
  } else if (Math.floor(diff / month) === 1) {
    return "1 month ago";
  } else if (diff < year) {
    return Math.floor(diff / month) + " months ago";
  } else if (Math.floor(diff / year) === 1) {
    return "1 year ago";
  } else {
    return Math.floor(diff / year) + " years ago";
  }
}

// export function scrollCardIntoView(
//   boxRef: React.MutableRefObject<Element | undefined>
// ) {
//   const scrollableArea = document.querySelector(
//     ".cards-scrollable-area"
//   ) as HTMLElement;

//   if (!boxRef.current || !scrollableArea) return;

//   const prevScrollableAreaPosition = scrollableArea.scrollTop;
//   const scrollableAreaTop = scrollableArea.getBoundingClientRect().top;
//   const boxTop = boxRef.current.getBoundingClientRect().top;

//   const scrollOptions: ScrollToOptions = {
//     top: scrollableArea.scrollTop + boxTop - scrollableAreaTop - 16,
//     behavior: "smooth",
//   };
//   scrollableArea.scrollTo(scrollOptions);
//   scrollableArea.style.overflowY = "hidden";

//   return prevScrollableAreaPosition;
// }

export function scrollCardIntoView(
  boxRef: React.MutableRefObject<Element | undefined>
) {
  const scrollableArea = document.querySelector(
    ".cards-scrollable-area"
  ) as HTMLElement;

  if (!boxRef.current || !scrollableArea) {
    console.error("boxRef or scrollableArea is not defined");
    return;
  }

  const prevScrollableAreaPosition = scrollableArea.scrollTop;
  const scrollableAreaTop = scrollableArea.getBoundingClientRect().top;
  const boxTop = boxRef.current.getBoundingClientRect().top;

  const scrollOptions = {
    top: scrollableArea.scrollTop + boxTop - scrollableAreaTop - 16,
  };

  scrollableArea.scrollTop = scrollOptions.top;
  scrollableArea.style.overflowY = "hidden";

  return prevScrollableAreaPosition;
}

export function scrollCardOutOfView(scrollY: number | undefined) {
  const scrollableArea = document.querySelector(
    ".cards-scrollable-area"
  ) as HTMLElement;

  if (!scrollableArea) return;

  const scrollOptions: ScrollToOptions = {
    top: scrollY,
    behavior: "smooth",
  };
  document.body.style.overflowY = "auto";
  scrollableArea.style.overflowY = "auto";
  scrollableArea.scrollTo(scrollOptions);
}

export function cutStringToNearestWord(
  text: string,
  resultingStringLength: number
) {
  if (text.length <= resultingStringLength) {
    return text;
  }

  const words = text.split(" ");
  let result = "";

  for (let i = 0; i < words.length - 1; i++) {
    if (result.length + words[i].length + 1 <= resultingStringLength) {
      result += (result ? " " : "") + words[i];
    } else {
      break;
    }
  }

  return result;
}

export function getAccessToken() {
  const userString = localStorage.getItem("user");
  const user: User = JSON.parse(userString!);

  return user.token;
}

export function getMessageGroup(state: MessageCenterState, chatId: string) {
  let messageGroup: MessagesForGroup | undefined;

  if (chatId.startsWith("G")) {
    messageGroup = state.globalMessages;
  } else if (chatId.startsWith("P") || chatId.startsWith("R")) {
    messageGroup = state.actionComments;
  }

  return messageGroup;
}
