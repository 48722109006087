import {Typography} from "@mui/material";
import MessageGroup from "./MessageGroup";
import {
  MessageGroupTypes,
  MessagesForGroup,
  PropertyRfpDictionary,
  SubjectDictionary,
  UserDictionary,
} from "../../app/models/messageCenter";
import {useState} from "react";

interface Props {
  totalMessageCards: number;
  actionComments: MessagesForGroup;
  globalMessages: MessagesForGroup;
  users: UserDictionary;
  propertiesRfps: PropertyRfpDictionary;
  subjects: SubjectDictionary;
  deleteUnreadMessagesHandler: (chatId: string) => Promise<void>;
}

export default function MessageGroups({
  totalMessageCards,
  actionComments,
  globalMessages,
  users,
  propertiesRfps,
  subjects,
  deleteUnreadMessagesHandler,
}: Props) {
  const [messageGroupChatId, setMessageGroupChatId] = useState<string>();

  const openMessageGroupChatHandler = (id: string) => {
    setMessageGroupChatId(id);
  };

  const closeMessageGroupChatHandler = () => {
    setMessageGroupChatId(undefined);
  };

  if (totalMessageCards === 0 && Object.keys(globalMessages).length === 0)
    return (
      <Typography
        variant="body2"
        sx={{
          display: "inline-block",
          marginX: "40px",
          marginTop: "20px",
        }}
      >
        No new messages
      </Typography>
    );

  return (
    <>
      {/* If MessageCenter Chat is open, display only the chat in the appropriate MessageGroup
       and hide the other MessageGroup*/}
      {!messageGroupChatId && (
        <MessageGroup
          type={MessageGroupTypes.action}
          messages={actionComments}
          users={users}
          propertiesRfps={propertiesRfps}
          subjects={subjects}
          onDeleteUnreadMessages={deleteUnreadMessagesHandler}
        />
      )}
      <MessageGroup
        type={MessageGroupTypes.global}
        messages={globalMessages}
        users={users}
        propertiesRfps={propertiesRfps}
        subjects={subjects}
        messageGroupChatId={messageGroupChatId}
        onOpenMessageGroupChatHandler={openMessageGroupChatHandler}
        onCloseMessageGroupChatHandler={closeMessageGroupChatHandler}
        onDeleteUnreadMessages={deleteUnreadMessagesHandler}
      />
    </>
  );
}
