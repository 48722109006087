import React, { useEffect, useState } from "react";
import agent from "../../app/api/agent";

interface UserType {
  id: string;
  name: string;
}
function UserTypes() {
  const [userTypes, setUserTypes] = useState<Array<any>>([]);
  const [name, setName] = useState<string>("");
  const [editingId, setEditingId] = useState<string | null>(null);

  useEffect(() => {
    agent.Admin.getTypes()
      .then((response) => {
        const userTypes = response.$values;
        setUserTypes(userTypes);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (editingId) {
      agent.Admin.updateTypes(editingId, name)
        .then((response) => {
          setUserTypes(
            userTypes.map((userType) =>
              userType.id === editingId
                ? { ...userType, name: response.name }
                : userType
            )
          );
        })
        .catch((error) => {
          console.log(error);
        });

      setEditingId(null);
    } else {
      agent.Admin.createTypes(name)
        .then((response) => {
          setUserTypes([...userTypes, response]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setName("");
  };

  const handleDelete = (id: number) => {
    agent.Admin.deleteTypes(id)
      .then(() => {
        setUserTypes(userTypes.filter((userType) => userType.id !== id));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEdit = (userType: UserType) => {
    setName(userType.name);
    setEditingId(userType.id);
  };

  const handleCancleEdit = () => {
    setName("");
    setEditingId(null);
  };

  return (
    <>
      <div>
        <h4 className="mb-3 mb-md-2">User Types</h4>
      </div>

      <div className="card-body">
        <h6 className="card-title">ALL User Types</h6>

        <div className="table-responsive">
          <table className="table">
            <tbody>
              {userTypes.map((userType) => (
                <tr key={userType.id}>
                  <td className="w-100">
                    <span
                      className="d-flex align-items-center"
                      style={{ height: "31px" }}
                    >
                      {userType.name}
                    </span>
                  </td>
                  <td>
                    <button
                      onClick={() => handleEdit(userType)}
                      className="btn btn-warning py-1 me-2"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(userType.id)}
                      className="btn btn-danger py-1"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="card-body">
        <h6 className="card-title">Add User Types</h6>

        <form className="forms-sample" onSubmit={handleSubmit}>
          <div className="col-sm-7">
            <input
              type="text"
              placeholder="Enter Type"
              value={name}
              onChange={(event) => setName(event.target.value)}
              className="form-control"
            />

            <label htmlFor="exampleInputUsername2" className="col-sm-5">
              {editingId ? (
                <>
                  <button type="submit" className="btn btn-warning me-2">
                    Update
                  </button>
                  <button
                    type="button"
                    onClick={handleCancleEdit}
                    className="btn btn-secondary"
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Create
                </button>
              )}
            </label>
          </div>
        </form>
      </div>
    </>
  );
}

export default UserTypes;
