import {RFP} from "../../../app/models/rfp";
import ServiceCard from "./ServiceCard";

interface Props {
  rfps: RFP[];
}

const ServiceList = ({rfps}: Props) => {
  return (
    <>{rfps && rfps.map((rfp) => <ServiceCard key={rfp.id} rfp={rfp} />)}</>
  );
};

export default ServiceList;
