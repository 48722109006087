import {Link, NavLink} from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {Box, Paper, Typography} from "@mui/material";
import PrimaryButton from "./PrimaryButton";

export default function FeatureToBeImplementedSoonPage() {
  return (
    <>
      <Box
        sx={{
          height: "60px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <NavLink to="/marketplace">
          <ArrowBackIosNewIcon
            sx={{
              display: "flex",
              color: "rgba(112, 112, 112, 1)",
              "&:hover": {
                color: "rgba(208, 235, 238, 1)",
              },
            }}
          />
        </NavLink>
      </Box>
      <Box
        sx={{
          height: "calc(100% - 60px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{
            height: "600px",
            width: "650px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={"/images/Remote work.png"}
            alt="IMG"
            style={{
              height: "430px",
              width: "430px",
            }}
          />
          <Typography sx={{fontWeight: 800, fontSize: "23px"}}>
            This feature is in development
          </Typography>
          <Typography sx={{fontWeight: 400, fontSize: "16px"}}>
            Stay tuned or join our Newsletter to get notified of new updates
          </Typography>
          <Link
            target="_blank"
            to="https://www.domunus.com/join?ss_source=sscampaigns&ss_campaign_id=65982237556e833ebe2cec93&ss_email_id=65a54f69dfbe416a33b2985d&ss_campaign_name=How+to+simplify+your+real+estate+transaction&ss_campaign_sent_date=2024-01-15T15%3A29%3A58Z"
          >
            <PrimaryButton>Subscribe</PrimaryButton>
          </Link>
        </Paper>
      </Box>
    </>
  );
}
