import {Box} from "@mui/material";
import {useEffect, useState} from "react";
import LoaderWithLinearProgress from "../../app/components/LoaderWithLinearProgress";
import {NavLink, useParams} from "react-router-dom";
import agent from "../../app/api/agent";
import LendersOfferDrawerDeals from "../../app/components/LendersOfferDrawerDeals";
import DealCardBuyerDisabledForLenderOfferView from "../deal/dealOverview/DealCardBuyerDisabledForLenderOfferView";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export default function LenderOffersPageDeals() {
  const [loading, setLoading] = useState(false);
  const {propertyId, contractId} = useParams<{
    propertyId: string;
    contractId: string;
  }>();
  const [property, setProperty] = useState<any>();

  useEffect(() => {
    if (propertyId) {
      setLoading(true);
      agent.Deals.oneDealProperty(parseInt(propertyId))
        .then((response) => {
          setProperty(response);
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
    }
  }, [propertyId]);

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <Box
      sx={{
        backgroundColor: "#F4F4F4",
        height: "100%",
        width: "100%",
        paddingBottom: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: "1",
          width: "100%",
          alignItems: "center",
          position: "sticky",
          backgroundColor: "#F5F5F5",
          top: 0,
          height: "60px",
          zIndex: "10",
        }}
      >
        <NavLink to="/deals">
          <ArrowBackIosNewIcon
            sx={{
              display: "flex",
              color: "rgba(112, 112, 112, 1)",
              "&:hover": {
                color: "rgba(208, 235, 238, 1)",
              },
            }}
          />
        </NavLink>
      </Box>
      {property && (
        <>
          <DealCardBuyerDisabledForLenderOfferView
            property={property}
            contractId={contractId}
          />
          <LendersOfferDrawerDeals
            dealId={property.id}
            contractId={contractId}
          />
        </>
      )}
    </Box>
  );
}
