import {InputAdornment, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface Props {
  onSearchClick: () => void;
}

export default function SearchBarClosed({onSearchClick}: Props) {
  return (
    <TextField
      sx={{
        backgroundColor: "white",
        border: "1px solid #9E9E9E",
        borderRadius: "35px",
        padding: "5px",
        width: "135px",
        height: "45px",
        color: "#9E9E9E",
      }}
      onClick={onSearchClick}
      variant="standard"
      label=""
      placeholder="Search"
      type="search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{paddingLeft: "10px"}}>
            <SearchIcon
              sx={{
                color: "rgba(158, 158, 158, 1)",
                width: "24px",
                height: "24px",
              }}
            />
          </InputAdornment>
        ),
        disableUnderline: true,
      }}
    />
  );
}
