import ReactDOM from "react-dom/client";
import "./app/layout/style.css";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import { router } from "./app/router/Routes";
import { Provider } from "react-redux";
import { store } from "./app/store/configureStore";
import { MessageCenterConnectionContextProvider } from "./app/store/MessageCenterConnectionContext";
import { ChatConnectionContextProvider } from "./app/store/ChatConnectionContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // Commented because it messes up with SignalR
  // <React.StrictMode>
  <Provider store={store}>
    <MessageCenterConnectionContextProvider>
      <ChatConnectionContextProvider>
        <RouterProvider router={router} />
      </ChatConnectionContextProvider>
    </MessageCenterConnectionContextProvider>
  </Provider>
  // </React.StrictMode>
);
