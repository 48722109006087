import {
  Box,
  Button,
  Card,
  CardActions,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {Form, NavLink, useNavigate} from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {FieldValues, useForm} from "react-hook-form";
import AppTextInput from "../../app/components/AppTextInputCustom";
import AppSelectList from "../../app/components/AppSelectListCustom";
import AppSelectListStates from "../../app/components/AppSelectListCustomStates";
import {useEffect, useState} from "react";
import agent from "../../app/api/agent";
import {toast} from "react-toastify";
import {LoadingButton} from "@mui/lab";
import {useAppSelector} from "../../app/store/configureStore";
import LoaderWithLinearProgress from "../../app/components/LoaderWithLinearProgress";
import ReusableDropzoneSingleImage from "../../app/components/ReusableDropzoneSingleImage";

export default function CreateProfile() {
  const navigate = useNavigate();
  const states = ["England", "Wales"];
  const countries = ["United Kingdom"];
  const {
    control,
    handleSubmit,
    formState: {isSubmitting},
  } = useForm();
  const [loading, setLoading] = useState(false);
  const {user} = useAppSelector((state) => state.account);
  const [professionList, setProfessionList] = useState<[]>([]);
  const [chosenProfession, setChosenProfession] = useState("");

  const handleChange = (e: any) => {
    setChosenProfession(e.target.value);
  };

  async function handleSubmitData(data: FieldValues) {
    setLoading(true);
    console.log(data);
    if (user && user.email) {
      data.email = user.email;
    }

    data.chosenProfession = user?.profession;

    agent.Account.createProfile(data)
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(response));

        window.location.reload();
      })
      .catch((error) => toast.error(error.message));
    setLoading(false);
  }

  useEffect(() => {
    if (user) {
      if (user.isConfirmed) {
        navigate("/marketplace");
        toast.success("You have successfully updated your profile!");
      } else {
        agent.Admin.getTypes()
          .then((response) => {
            const userRolesNames = response.$values
              .map((roleName: any) => roleName.name)
              .filter(
                (name: string) =>
                  name !== "BuyerRepresentative" &&
                  name !== "SellerRepresentative" &&
                  name !== "SellerAttorney" &&
                  name !== "BuyerAttorney" &&
                  name !== "Admin" &&
                  name !== "Member" &&
                  name !== "EscrowAgent"
              )
              .map((name: string) => name.replace(/([A-Z])/g, " $1").trim())
              .sort();
            setProfessionList(userRolesNames);
          })
          .catch((error) => console.log(error.response))
          .finally(() => setLoading(false));
      }
    }
  }, [user, navigate]);

  if (user === null) {
    navigate("/login");
  }

  async function cancelEdit() {
    navigate(-1);
  }

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F4F4F4",
          height: "100%",
          width: "100%",
          paddingBottom: "10px",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            width: "100%",
            position: "sticky",
            alignItems: "center",
            backgroundColor: "#F5F5F5",
            top: 0,
            height: "60px",
            zIndex: "10",
          }}
        >
          <NavLink to="/marketplace">
            <ArrowBackIosNewIcon
              sx={{
                display: "flex",
                color: "rgba(112, 112, 112, 1)",
                "&:hover": {
                  color: "rgba(208, 235, 238, 1)",
                },
              }}
            />
          </NavLink>
        </Box>

        <Form onSubmit={handleSubmit(handleSubmitData)}>
          <Card
            sx={{
              padding: "60px",
              height: "100vh",
              width: "100%",
              paddingTop: "50px",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                paddingLeft: "25px",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Roboto",
                  color: "#424242",
                  fontWeight: "600",
                  fontSize: "23px",
                }}
              >
                Create a profile
              </Typography>
            </Grid>

            <Grid
              container
              spacing={3}
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                width: "50%",
                paddingLeft: "50px",
                paddingRight: "50px",
              }}
            >
              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="First Name"
                  type="text"
                  name="firstName"
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Last Name"
                  type="text"
                  name="lastName"
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  sx={{width: "100%"}}
                  variant="standard"
                  type="text"
                  name="email"
                  value={user?.email}
                  label="Email Address"
                  disabled
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Phone Number"
                  type="text"
                  name="phoneNumber"
                  className="no-spinners"
                  required
                />
              </Grid>

              <Grid item xs={6}>
                {user?.profession === null ? (
                  <>
                    <AppSelectList
                      control={control}
                      items={professionList}
                      label="Role"
                      name="profession"
                      onChange={handleChange}
                      required
                    />

                    <Grid item xs={12} display={"none"}>
                      <TextField value={chosenProfession} />
                    </Grid>
                  </>
                ) : (
                  <TextField
                    sx={{width: "100%"}}
                    variant="standard"
                    name="profession"
                    value={user?.profession}
                    label="Profession"
                    disabled
                    required
                  />
                )}
              </Grid>

              {user?.profession !== "Seller" &&
                user?.profession !== "Buyer" && (
                  <Grid item xs={12}>
                    <AppTextInput
                      control={control}
                      label="Company Name"
                      type="text"
                      name="companyName"
                    />
                  </Grid>
                )}

              {user?.profession !== "Seller" &&
                user?.profession !== "Buyer" && (
                  <Grid item xs={12}>
                    <AppTextInput
                      control={control}
                      label="Company Type"
                      type="text"
                      name="companyType"
                    />
                  </Grid>
                )}

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label={
                    user?.profession === "Buyer" ||
                    user?.profession === "Seller"
                      ? "Address"
                      : "Company Address"
                  }
                  type="text"
                  name="companyAddress"
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Unit"
                  type="text"
                  name="unit"
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput control={control} label="City" name="city" />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Postal Code"
                  type="text"
                  name="postalCode"
                />
              </Grid>

              <Grid item xs={6}>
                <AppSelectListStates
                  control={control}
                  items={states}
                  label="State"
                  name="state"
                />
              </Grid>

              <Grid item xs={6}>
                <AppSelectList
                  control={control}
                  items={countries}
                  label="Country"
                  name="country"
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                width: "50%",
                paddingLeft: "20px",
                paddingRight: "20px",
                height: "100%",
              }}
            >
              {user?.profession !== "Seller" &&
                user?.profession !== "Buyer" && (
                  <Grid item xs={12}>
                    <AppTextInput
                      control={control}
                      label="Licence Number"
                      type="text"
                      name="licenceNumber"
                      required
                    />
                  </Grid>
                )}

              <Grid item xs={6}>
                <Typography sx={{fontWeight: "700", paddingBottom: "20px"}}>
                  Profile picture
                </Typography>
                <Card sx={{backgroundColor: "#F7F7F7", padding: 2}}>
                  <CardActions>
                    <ReusableDropzoneSingleImage control={control} name="File">
                      <Box
                        display="flex"
                        flexDirection="column"
                        width="100%"
                        alignItems="center"
                      >
                        <img
                          src={"/images/DropzoneProfilePicture.png"}
                          alt="IMG"
                          style={{
                            height: "90px",
                            width: "100px",
                            marginBottom: "15px",
                            marginTop: "15px",
                          }}
                        />
                        <Typography variant="subtitle1" textAlign="center">
                          Drag and drop a photo here to upload or
                          <Typography
                            sx={{
                              textDecoration: "underline",
                              display: "inline-flex",
                            }}
                          >
                            click here to browse
                          </Typography>
                        </Typography>
                      </Box>
                    </ReusableDropzoneSingleImage>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Card>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              position: "sticky",
              backgroundColor: "#F5F5F5",
              bottom: 0,
            }}
          >
            <LoadingButton
              sx={{
                background: "#047782",
                "&:hover": {
                  background: "#0196A4",
                },
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#fff",
                borderRadius: "4px",
                letterSpacing: "1.25px",
                margin: "15px",
              }}
              loading={isSubmitting}
              type="submit"
            >
              Save
            </LoadingButton>

            <Button
              onClick={cancelEdit}
              sx={{
                background: "#fff",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#047782",
                letterSpacing: "1.25px",
                border: "1px solid #047782",
                borderRadius: "4px",
                margin: "15px",
              }}
            >
              Cancel
            </Button>
          </Box>
        </Form>
      </Box>
    </>
  );
}
