import {
  Button,
  CardActions,
  Divider,
  Typography,
  Box,
  MenuList,
  MenuItem,
  Link as LinkMUI,
} from "@mui/material";
import {NavLink} from "react-router-dom";
import {useAppSelector} from "../../app/store/configureStore";

function Menu(props: any) {
  const {user} = useAppSelector((state: any) => state.account);

  const sortedItems = [...props.items].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <>
      <Box>
        <Typography
          gutterBottom
          variant="h5"
          sx={{
            color: "#0D2561",
            fontWeight: "400",
            fontSize: "23px",
            lineHeight: "32px",
            paddingLeft: "20px",
          }}
        >
          Partners
        </Typography>
        <Divider sx={{margin: "10px", border: "1px solid #0D2561"}} />
        <MenuList sx={{overflowY: "scroll", height: "740px"}}>
          {sortedItems.map((item: any) => (
            <MenuItem
              style={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                padding: "8px",
                color: "black",
                width: "100%",
                paddingLeft: "20px",
              }}
              key={item.id}
              className={`${
                item.name === props.selectedItem.name ? "active" : ""
              }`}
              onClick={() => props.onItemClick(item)}
            >
              <LinkMUI sx={{textDecoration: "none", color: "black"}} href="#">
                {item.name.replace(/([A-Z])/g, " $1").trim()}
              </LinkMUI>
            </MenuItem>
          ))}
        </MenuList>
      </Box>
      {user.profession === "Admin" && (
        <CardActions
          sx={{alignItems: "center", display: "flex", width: "100%"}}
        >
          <NavLink to="/user-types" style={{width: "100%"}}>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "#00838F",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "#00838F",
                },
                color: "#FFF",
                borderRadius: "4px",
                border: "1px solid",
                width: "100%",
              }}
            >
              Add Partner Type
            </Button>
          </NavLink>
        </CardActions>
      )}

      {/* {user.Roles === "admin" ? (
        <CardActions
          sx={{ alignItems: "center", display: "flex", width: "100%" }}
        >
          <NavLink to="/user-types" style={{ width: "100%" }}>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "#00838F",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "#00838F",
                },
                color: "#FFF",
                borderRadius: "4px",
                border: "1px solid",
                width: "100%",
              }}
            >
              Add Partner Type
            </Button>
          </NavLink>
        </CardActions>
      ) : null} */}
    </>
  );
}

export default Menu;
