import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  MenuItem,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import {Form, NavLink, useNavigate} from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {useEffect, useState} from "react";
import {useAppSelector} from "../../app/store/configureStore";
import LoaderWithLinearProgress from "../../app/components/LoaderWithLinearProgress";
import {toast} from "react-toastify";
import agent from "../../app/api/agent";
import {TransitionProps} from "@mui/material/transitions";
import React from "react";

export default function EditProfile() {
  const navigate = useNavigate();

  const states = [{name: "England"}, {name: "Wales"}];
  const countries = [
    {
      value: "United Kingdom",
    },
  ];
  const [loading, setLoading] = useState(false);
  const {user} = useAppSelector((state) => state.account);
  const [open, setOpen] = useState(false);

  const [updatedUser, setUpdatedUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: 0,
    officeNumber: 0,
    companyName: "",
    companyType: "",
    companyAddress: "",
    unit: "",
    city: "",
    postalCode: "",
    state: "",
    country: "",
    profession: "",
    licenceNumber: "",
    file: null,
    profilePictureUrl: "",
  });

  useEffect(() => {
    if (user) {
      agent.Account.currentUser()
        .then((response) => {
          setUpdatedUser(response);
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
    }
  }, [user]);

  const [file, setFile] = useState(null);

  const handleMainImageChange = (event: any) => {
    const file1 = event.target.files[0];

    setUpdatedUser({...updatedUser, file: file1});
    setFile(file1);
  };

  const handleInputChange = (event: any) => {
    let {name, value} = event.target;

    setUpdatedUser({...updatedUser, [name]: value});
  };

  const handleSubmit = async (event: any) => {
    setLoading(true);
    event.preventDefault();
    const formData = new FormData();

    formData.append("firstName", updatedUser?.firstName);
    formData.append("lastName", updatedUser?.lastName);
    formData.append("email", updatedUser?.email);
    formData.append("phoneNumber", updatedUser?.phoneNumber.toString());
    formData.append("officeNumber", updatedUser?.officeNumber.toString());
    formData.append("companyName", updatedUser?.companyName);
    formData.append("companyType", updatedUser?.companyType);
    formData.append("companyAddress", updatedUser?.companyAddress);
    formData.append("unit", updatedUser?.unit);
    formData.append("postalCode", updatedUser?.postalCode);
    formData.append("state", updatedUser?.state);
    formData.append("country", updatedUser?.country);
    formData.append("profession", updatedUser?.profession);
    formData.append("licenceNumber", updatedUser?.licenceNumber);
    formData.append("city", updatedUser?.city);

    if (updatedUser?.file !== null) {
      formData.append("file", updatedUser?.file);
    } else {
      formData.append("oldMainImageUrl", updatedUser.profilePictureUrl);
    }

    await agent.Account.editProfile(formData)
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(response));
        navigate("/marketplace");
        setLoading(false);
        window.location.reload();
        toast.success("Profile was edited Successfully");
      })
      .catch((error) => toast.error(error));
  };

  async function cancelEdit() {
    navigate(-1);
  }

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const ChildModal = (props: any) => {
    const handleClose = () => {
      setOpen(false);
    };

    return (
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{color: "rgba(66, 66, 66, 1)"}}
            >
              Are you sure you want to save these changes?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Form onClick={(e) => handleSubmit(e)}>
              <Button
                sx={{
                  background: "#047782",
                  "&:hover": {
                    background: "#0196A4",
                  },
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontSize: "0.875rem",
                  fontWeight: "400",
                  color: "#fff",
                  borderRadius: "4px",
                  letterSpacing: "1.25px",
                  margin: "15px",
                }}
                onClick={handleClose}
              >
                SAVE
              </Button>
            </Form>
            <Button
              sx={{
                background: "#fff",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#047782",
                letterSpacing: "1.25px",
                border: "1px solid #047782",
                borderRadius: "4px",
                margin: "15px",
              }}
              onClick={handleClose}
            >
              CANCEL
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F4F4F4",
          height: "100%",
          width: "100%",
          paddingBottom: "10px",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            width: "100%",
            position: "sticky",
            alignItems: "center",
            backgroundColor: "#F5F5F5",
            top: 0,
            height: "60px",
            zIndex: "10",
          }}
        >
          <NavLink to="/marketplace">
            <ArrowBackIosNewIcon
              sx={{
                display: "flex",
                color: "rgba(112, 112, 112, 1)",
                "&:hover": {
                  color: "rgba(208, 235, 238, 1)",
                },
              }}
            />
          </NavLink>
        </Box>

        <Card
          sx={{
            padding: "60px",
            height: "100%",
            width: "100%",
            paddingTop: "50px",
          }}
        >
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Grid
              item
              xs={12}
              sx={{
                paddingLeft: "25px",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Roboto",
                  color: "#424242",
                  fontWeight: "600",
                  fontSize: "23px",
                }}
              >
                Edit profile
              </Typography>
            </Grid>

            <Grid
              container
              spacing={3}
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                width: "50%",
                paddingLeft: "50px",
                paddingRight: "50px",
              }}
            >
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  onChange={handleInputChange}
                  value={updatedUser?.firstName}
                  label="First Name"
                  type="text"
                  name="firstName"
                  sx={{width: "100%"}}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  onChange={handleInputChange}
                  value={updatedUser?.lastName}
                  label="Last Name"
                  type="text"
                  name="lastName"
                  sx={{width: "100%"}}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  sx={{width: "100%"}}
                  variant="standard"
                  type="text"
                  onChange={handleInputChange}
                  name="email"
                  value={updatedUser?.email}
                  label="Email Address"
                  disabled
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  sx={{width: "100%"}}
                  variant="standard"
                  value={updatedUser?.phoneNumber}
                  label="Phone Number"
                  onChange={handleInputChange}
                  type="text"
                  name="phoneNumber"
                  className="no-spinners"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  sx={{width: "100%"}}
                  variant="standard"
                  name="profession"
                  value={updatedUser?.profession}
                  label="Profession"
                  disabled
                  onChange={handleInputChange}
                />
              </Grid>

              {user?.profession !== "Seller" &&
                user?.profession !== "Buyer" && (
                  <Grid item xs={12}>
                    <TextField
                      sx={{width: "100%"}}
                      variant="standard"
                      value={updatedUser?.companyName}
                      onChange={handleInputChange}
                      label="Company Name"
                      type="text"
                      name="companyName"
                    />
                  </Grid>
                )}

              {user?.profession !== "Seller" &&
                user?.profession !== "Buyer" && (
                  <Grid item xs={12}>
                    <TextField
                      sx={{width: "100%"}}
                      variant="standard"
                      value={updatedUser?.companyType}
                      onChange={handleInputChange}
                      label="Company Type"
                      type="text"
                      name="companyType"
                    />
                  </Grid>
                )}

              <Grid item xs={6}>
                <TextField
                  sx={{width: "100%"}}
                  variant="standard"
                  value={updatedUser?.companyAddress}
                  onChange={handleInputChange}
                  label={
                    user?.profession === "Buyer" ||
                    user?.profession === "Seller"
                      ? "Address"
                      : "Company Address"
                  }
                  type="text"
                  name="companyAddress"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  sx={{width: "100%"}}
                  variant="standard"
                  onChange={handleInputChange}
                  value={updatedUser?.unit}
                  label="Unit"
                  type="text"
                  name="unit"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  sx={{width: "100%"}}
                  variant="standard"
                  onChange={handleInputChange}
                  value={updatedUser?.city}
                  label="City"
                  name="city"
                  type="text"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  sx={{width: "100%"}}
                  variant="standard"
                  onChange={handleInputChange}
                  value={updatedUser?.postalCode}
                  label="Postal Code"
                  type="text"
                  name="postalCode"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  onChange={handleInputChange}
                  value={updatedUser?.state}
                  select
                  label="State"
                  type="text"
                  name="state"
                  sx={{width: "100%"}}
                >
                  {states.map((option: any) => (
                    <MenuItem
                      key={option.name}
                      value={option.name}
                      onChange={handleInputChange}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  onChange={handleInputChange}
                  value={updatedUser?.country}
                  select
                  label="Country"
                  name="country"
                  type="text"
                  className="form-control"
                  sx={{width: "100%"}}
                >
                  {countries.map((option: any) => (
                    <MenuItem
                      value={option.value}
                      key={option.value}
                      onChange={handleInputChange}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                width: "50%",
                paddingLeft: "20px",
                paddingRight: "20px",
                height: "100%",
              }}
            >
              {user?.profession !== "Seller" &&
                user?.profession !== "Buyer" && (
                  <Grid item xs={12}>
                    <TextField
                      sx={{width: "100%"}}
                      variant="standard"
                      value={updatedUser?.licenceNumber}
                      label="Licence Number"
                      type="text"
                      name="licenceNumber"
                      onChange={handleInputChange}
                    />
                  </Grid>
                )}

              <Grid item xs={6}>
                <Box>
                  <Typography>Profile picture</Typography>
                  {file ? (
                    <img
                      style={{
                        maxWidth: "200px",
                        maxHeight: "200px",
                        marginRight: "15px",
                      }}
                      src={URL.createObjectURL(file)}
                      alt="file"
                    />
                  ) : (
                    <img
                      style={{
                        maxWidth: "200px",
                        maxHeight: "200px",
                        marginRight: "15px",
                      }}
                      src={updatedUser?.profilePictureUrl!}
                      alt={updatedUser?.profilePictureUrl!}
                    />
                  )}
                </Box>

                <Box>
                  <Typography>Change Profile Picture</Typography>
                  <input
                    className="form-control"
                    type="file"
                    accept="image/*"
                    onChange={handleMainImageChange}
                  />
                </Box>
              </Grid>
            </Grid>
          </Form>
        </Card>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            position: "sticky",
            backgroundColor: "#F5F5F5",
            bottom: 0,
          }}
        >
          <ChildModal setModalOpen={setOpen} modalState={open} />
          <Button
            onClick={(e) => {
              e.preventDefault();
              setOpen(true);
            }}
            sx={{
              background: "#047782",
              "&:hover": {
                background: "#0196A4",
              },
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontSize: "0.875rem",
              fontWeight: "400",
              color: "#fff",
              borderRadius: "4px",
              letterSpacing: "1.25px",
              margin: "15px",
            }}
          >
            Submit
          </Button>

          <Button
            onClick={cancelEdit}
            sx={{
              background: "#fff",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontSize: "0.875rem",
              fontWeight: "400",
              color: "#047782",
              letterSpacing: "1.25px",
              border: "1px solid #047782",
              borderRadius: "4px",
              margin: "15px",
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
}
