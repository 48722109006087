import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  DialogContentText,
  Input,
  Typography,
} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import SecondaryButton from "./SecondaryButton";
import {Form} from "react-router-dom";
import mammoth from "mammoth";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalState: boolean;

  setDocumentFamilyId: React.Dispatch<
    React.SetStateAction<{
      documentId: number;
      file: string;
      documentName: string;
    }>
  >;
  handleSubmitData(): Promise<void>;
}

const RFPDocumentSelectModal = ({
  modalState,
  setModalOpen,
  setDocumentFamilyId,
  handleSubmitData,
}: Props) => {
  const {handleSubmit} = useForm();
  const [childModalDocumentConfirmOpen, setChildModalDocumentConfirmOpen] =
    useState(false);

  const handleClose = () => {
    setModalOpen(false);
  };

  const documentUploadHandler = async (e: any) => {
    const file = e.target.files?.[0];

    console.log(file);

    const fileTitle = file?.name.split(".")[0];

    if (file) {
      try {
        // Convert to document to HTML
        const arrayBuffer = await file.arrayBuffer();
        const conversionResult = await mammoth.convertToHtml({arrayBuffer});

        setDocumentFamilyId((prevState) => {
          return {
            documentName: fileTitle!,
            documentId: prevState.documentId,
            file: conversionResult.value,
          };
        });
      } catch {}
    }
  };

  const Transition2 = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const ChildModalDocumentConfirm = (props: any) => {
    const handleCloseDocConfirm = (e: any) => {
      e.preventDefault();
      setChildModalDocumentConfirmOpen(false);
    };

    return (
      <div>
        <Dialog
          open={childModalDocumentConfirmOpen}
          TransitionComponent={Transition2}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{color: "rgba(66, 66, 66, 1)"}}
            >
              Are you sure you want to upload the document?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Form onClick={() => handleSubmitData()}>
              <Button
                sx={{
                  background: "#047782",
                  "&:hover": {
                    background: "#0196A4",
                  },
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontSize: "0.875rem",
                  fontWeight: "400",
                  color: "#fff",
                  borderRadius: "4px",
                  letterSpacing: "1.25px",
                  margin: "15px",
                }}
                onClick={handleCloseDocConfirm}
              >
                SAVE
              </Button>
            </Form>
            <Button
              sx={{
                background: "#fff",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#047782",
                letterSpacing: "1.25px",
                border: "1px solid #047782",
                borderRadius: "4px",
                margin: "15px",
              }}
              onClick={handleCloseDocConfirm}
            >
              CANCEL
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return (
    <Dialog
      open={modalState}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      maxWidth="xl"
    >
      <DialogTitle>
        <Typography sx={{fontWeight: 600, fontSize: "20px"}}>
          Select a Document you want to upload
        </Typography>
      </DialogTitle>
      <Form onSubmit={handleSubmit(handleSubmitData)}>
        <DialogContent>
          <Grid item xs={12}>
            <Input
              id="file"
              type="file"
              onChange={documentUploadHandler}
              fullWidth
            />
          </Grid>
        </DialogContent>
      </Form>
      <DialogActions>
        <ChildModalDocumentConfirm
          setModalOpen={setChildModalDocumentConfirmOpen}
          modalState={childModalDocumentConfirmOpen}
        />
        <Button
          onClick={(e) => {
            e.preventDefault();
            setChildModalDocumentConfirmOpen(true);
          }}
          sx={{
            background: "rgba(4, 119, 130, 1)",
            "&.MuiButtonBase-root:hover": {
              bgcolor: "rgba(1, 150, 164, 1)",
            },
            "&:disabled": {
              backgroundColor: "rgba(0, 0, 0, 0.12)",
            },
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: "500",
            color: "rgba(255, 255, 255, 1)",
            borderRadius: "4px",
            letterSpacing: "1.25px",
            textAlign: "center",
            lineHeight: "36px",
            minWidth: "100px",
            height: "40px",
            margin: "15px",
          }}
        >
          Submit
        </Button>
        <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default RFPDocumentSelectModal;
