import {
  Grid,
  Typography,
  Button,
  Card,
  Box,
  CardActions,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
} from "@mui/material";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {useAppSelector} from "../../app/store/configureStore";
import React, {useEffect, useRef, useState} from "react";
import agent from "../../app/api/agent";
import LoaderWithLinearProgress from "../../app/components/LoaderWithLinearProgress";
import {toast} from "react-toastify";
import {Offer} from "../../app/models/offer";
import {TransitionProps} from "@mui/material/transitions";
import RfpAdditionalDocumentsListCardWithOfferPreviewItems from "../../app/components/RfpAdditionalDocumentsListCardWithOfferPreviewItems";
import ReactQuill from "react-quill";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

interface Image {
  id: number;
  imageFamilyId: number;
  fileName: string;
  imageUrl: string;
}

const OfferPropertyViewPage = () => {
  const {user} = useAppSelector((state) => state.account);
  const navigate = useNavigate();
  const {offerId, propertyId} = useParams<{
    offerId: string;
    propertyId: string;
  }>();
  const [loading, setLoading] = useState(false);
  const [offer, setOffer] = useState<Offer>();
  const [offerDocuments, setOfferDocuments] = useState([]);
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<any>(null);
  const [contractProgressImages, setContractProgressImages] = useState([]);
  const [openModalImage, setOpenModalImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);

  const handleOpenModalImage = (image: any) => {
    setSelectedImage(image);
    setOpenModalImage(true);
  };

  const TOOLBAR_OPTIONS: never[] = [];

  const contentRef = useRef<HTMLDivElement>(null);

  const handleDownloadPdf = async () => {
    if (contentRef.current) {
      const content = contentRef.current;

      if (content) {
        const canvas = await html2canvas(content);

        const imgData = canvas.toDataURL("image/png");

        const pdf = new jsPDF("p", "mm", "a4");

        const imgWidth = 210; // A4 width in mm
        const pageHeight = 306; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        let position = 0;

        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save("document.pdf");

        toast.success("The document was downloaded successfully!");
      }
    }
  };

  const handleDocumentClick = (document: any) => {
    setSelectedDocument(document);
    setOpenDocumentModal(true);
  };

  useEffect(() => {
    if (offerId) {
      setLoading(true);
      agent.OfferProperty.onePropertyOffer(parseInt(offerId))
        .then((response) => {
          setOffer(response);
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
    }
  }, [offerId]);

  useEffect(() => {
    if (offerId) {
      agent.Document.DocumentsForContractProgressSellerSide(parseInt(offerId))
        .then((response) => {
          setOfferDocuments(response.$values);
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
    }
  }, [offerId]);

  useEffect(() => {
    if (offerId) {
      agent.Document.ImagesForContractProgressSellerSide(parseInt(offerId))
        .then((response) => {
          setContractProgressImages(response.$values);
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
    }
  }, [offerId]);

  async function handleResponse(id: string | undefined) {
    if (id) {
      setLoading(true);

      agent.OfferProperty.update(parseInt(id))
        .then(() => {
          if (offer) {
            offer.isAccepted = true;
          }
          toast.success("Offer was successfully accepted");
          navigate("/deals");
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
    }
  }

  async function cancelEdit() {
    navigate(-1);
  }

  if (!user) {
    navigate("/login");
  }

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const ChildDocumentModal = ({open, handleClose}: any) => {
    return (
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          sx={{overflow: "unset"}}
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{color: "rgba(66, 66, 66, 1)"}}
            >
              {/* {selectedDocument && selectedDocument.documentText} */}

              <ReactQuill
                value={selectedDocument?.documentText}
                modules={{toolbar: TOOLBAR_OPTIONS}}
              />

              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "fit-content",
                }}
              >
                <div ref={contentRef}>
                  <ReactQuill
                    value={selectedDocument?.documentText}
                    modules={{toolbar: TOOLBAR_OPTIONS}}
                  />
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                background: "#047782",
                "&:hover": {
                  background: "#0196A4",
                },
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#fff",
                borderRadius: "4px",
                letterSpacing: "1.25px",
                margin: "15px",
              }}
              onClick={handleDownloadPdf}
            >
              DOWNLOAD
            </Button>
            <Button
              sx={{
                background: "#fff",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#047782",
                letterSpacing: "1.25px",
                border: "1px solid #047782",
                borderRadius: "4px",
                margin: "15px",
              }}
              onClick={handleClose}
            >
              CANCEL
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const ImageModal = (props: any) => {
    const handleClose = () => {
      setOpenModalImage(false);
      setSelectedImage(null);
    };

    return (
      <div>
        <Dialog
          open={openModalImage}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            {selectedImage && (
              <Box>
                <img
                  src={selectedImage.imageUrl}
                  alt={selectedImage.fileName}
                  style={{width: "100%", height: "auto"}}
                />
              </Box>
            )}
          </DialogContent>

          <DialogActions>
            <Button
              sx={{
                background: "#fff",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#047782",
                letterSpacing: "1.25px",
                border: "1px solid #047782",
                borderRadius: "4px",
                margin: "15px",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F4F4F4",
          height: "100%",
          width: "100%",
          paddingX: "20px",
          overflowY: "scroll",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            width: "100%",
            alignItems: "center",
            position: "sticky",
            backgroundColor: "#F5F5F5",
            top: 0,
            height: "60px",
            zIndex: "10",
            overflowY: "scroll",
          }}
        >
          <NavLink to="/marketplace">
            <ArrowBackIosNewIcon
              sx={{
                display: "flex",
                color: "rgba(112, 112, 112, 1)",
                "&:hover": {
                  color: "rgba(208, 235, 238, 1)",
                },
              }}
            />
          </NavLink>
        </Box>

        <Card sx={{padding: "20px"}}>
          <Grid
            item
            xs={12}
            sx={{
              paddingLeft: "25px",
              paddingTop: "15px",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Roboto",
                color: "#424242",
                fontWeight: "600",
                fontSize: "23px",
              }}
            >
              Offer Submission
            </Typography>
          </Grid>

          <Grid
            container
            spacing={3}
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              width: "50%",
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                disabled
                label="Name of Broker"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                type="text"
                name="nameOfBroker"
                value={offer?.nameOfBroker}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                disabled
                label="Name of Buyer"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                type="text"
                name="nameOfBuyer"
                value={offer?.nameOfBuyer}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                disabled
                label="Address of Buyer/Firm"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                type="text"
                name="addressOfByuer"
                value={offer?.addressOfBuyerFirm}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                disabled
                label="Email Address"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                type="text"
                name="emailAddress"
                value={offer?.emailAddress}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="standard"
                disabled
                label="Offer Price"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                type="text"
                name="offerPrice"
                value={offer?.offerPrice}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="standard"
                disabled
                label="Acceleration Option"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                type="text"
                name="accelerationOption"
                value={offer?.acceleratedOption}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{fontWeight: "700"}}>Contingencies</Typography>
            </Grid>

            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      "&.Mui-disabled": {
                        color: "black",
                      },
                    },
                  }}
                  disabled
                  control={
                    <Checkbox
                      checked={offer?.abilityToSecureFinancing}
                      sx={{
                        "&.Mui-disabled": {
                          color: "#000000",
                        },
                      }}
                    />
                  }
                  label="Ability To Secure Financing"
                />
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      "&.Mui-disabled": {
                        color: "black",
                      },
                    },
                  }}
                  disabled
                  control={
                    <Checkbox
                      checked={offer?.appraisalValue}
                      sx={{
                        "&.Mui-disabled": {
                          color: "#000000",
                        },
                      }}
                    />
                  }
                  label="Appraisal Value"
                />
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      "&.Mui-disabled": {
                        color: "black",
                      },
                    },
                  }}
                  disabled
                  control={
                    <Checkbox
                      checked={offer?.satisfactoryTitle}
                      sx={{
                        "&.Mui-disabled": {
                          color: "#000000",
                        },
                      }}
                    />
                  }
                  label="Satisfactory title"
                />
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      "&.Mui-disabled": {
                        color: "black",
                      },
                    },
                  }}
                  disabled
                  control={
                    <Checkbox
                      checked={offer?.propertySurvey}
                      sx={{
                        "&.Mui-disabled": {
                          color: "#000000",
                        },
                      }}
                    />
                  }
                  label="Property survey"
                />
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      "&.Mui-disabled": {
                        color: "black",
                      },
                    },
                  }}
                  disabled
                  control={
                    <Checkbox
                      checked={offer?.inspectionContingency}
                      sx={{
                        "&.Mui-disabled": {
                          color: "#000000",
                        },
                      }}
                    />
                  }
                  label="Inspection contingency"
                />
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      "&.Mui-disabled": {
                        color: "black",
                      },
                    },
                  }}
                  disabled
                  control={
                    <Checkbox
                      checked={offer?.reviewOfLeases}
                      sx={{
                        "&.Mui-disabled": {
                          color: "#000000",
                        },
                      }}
                    />
                  }
                  label="Review of leases"
                />
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      "&.Mui-disabled": {
                        color: "black",
                      },
                    },
                  }}
                  disabled
                  control={
                    <Checkbox
                      checked={offer?.landUseApproval}
                      sx={{
                        "&.Mui-disabled": {
                          color: "#000000",
                        },
                      }}
                    />
                  }
                  label="Sale Contingency"
                />
              </FormGroup>
            </Grid>

            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      "&.Mui-disabled": {
                        color: "black",
                      },
                    },
                  }}
                  disabled
                  control={
                    <Checkbox
                      checked={offer?.propertyIncomeAndExpenses}
                      sx={{
                        "&.Mui-disabled": {
                          color: "#000000",
                        },
                      }}
                    />
                  }
                  label="Property income and expenses"
                />
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      "&.Mui-disabled": {
                        color: "black",
                      },
                    },
                  }}
                  disabled
                  control={
                    <Checkbox
                      checked={offer?.environmentalConditions}
                      sx={{
                        "&.Mui-disabled": {
                          color: "#000000",
                        },
                      }}
                    />
                  }
                  label="Environmental conditions"
                />
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      "&.Mui-disabled": {
                        color: "black",
                      },
                    },
                  }}
                  disabled
                  control={
                    <Checkbox
                      checked={offer?.saleOfOtherProperty}
                      sx={{
                        "&.Mui-disabled": {
                          color: "#000000",
                        },
                      }}
                    />
                  }
                  label="Sale of other Property"
                />
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      "&.Mui-disabled": {
                        color: "black",
                      },
                    },
                  }}
                  disabled
                  control={
                    <Checkbox
                      checked={offer?.insuranceApproval}
                      sx={{
                        "&.Mui-disabled": {
                          color: "#000000",
                        },
                      }}
                    />
                  }
                  label="Inspection Contingency"
                />
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      "&.Mui-disabled": {
                        color: "black",
                      },
                    },
                  }}
                  disabled
                  control={
                    <Checkbox
                      checked={offer?.closingDate}
                      sx={{
                        "&.Mui-disabled": {
                          color: "#000000",
                        },
                      }}
                    />
                  }
                  label="Closing Date"
                />
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      "&.Mui-disabled": {
                        color: "black",
                      },
                    },
                  }}
                  disabled
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-disabled": {
                          color: "#000000",
                        },
                      }}
                    />
                  }
                  label="Other (please specify below):"
                />
                <TextField
                  fullWidth
                  variant="standard"
                  disabled
                  label="Other"
                  InputLabelProps={{
                    style: {color: "#000000"},
                  }}
                  type="text"
                  name="other"
                  defaultValue={offer?.other}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              width: "50%",
              paddingLeft: "20px",
              paddingRight: "10px",
            }}
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                disabled
                label="Earnerst Money Amount and Paid"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                type="text"
                name="earnestMoneyAmount"
                value={offer?.earnestMoneyAmountAndPaid}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                disabled
                label="Financing"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                name="mortgage"
                value={offer?.mortgage}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                disabled
                label="Pre-approval"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                name="preApproval"
                value={offer?.preApproval}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{fontWeight: "700", paddingBottom: "20px"}}>
                Documents
              </Typography>
              {offerDocuments && offerDocuments.length > 0 ? (
                <Card sx={{backgroundColor: "#F7F7F7", padding: 1}}>
                  <CardActions
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <RfpAdditionalDocumentsListCardWithOfferPreviewItems
                      onSetModalOpen={handleDocumentClick}
                      rfpDocuments={offerDocuments}
                      name="x"
                    />
                    <ChildDocumentModal
                      open={openDocumentModal}
                      document={selectedDocument}
                      handleClose={() => setOpenDocumentModal(false)}
                    />
                  </CardActions>
                </Card>
              ) : (
                <Card
                  sx={{
                    backgroundColor: "#F7F7F7",
                    padding: 1,
                    height: "300px",
                    width: "470px",
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexWrap: "wrap",
                  }}
                >
                  No documents attached
                </Card>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{fontWeight: "700", paddingBottom: "20px"}}>
                Images
              </Typography>
              {contractProgressImages &&
                contractProgressImages.map((image: any) => (
                  <>
                    <ImageModal
                      setOpen={setOpenModalImage}
                      open={openModalImage}
                    />
                    <Grid item key={image.id}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          width: "200px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {image.fileName}
                      </Typography>
                      <img
                        src={image.imageUrl}
                        alt=""
                        style={{
                          height: 260,
                          width: 200,
                          paddingTop: 2.5,
                          paddingBottom: 2.5,
                          paddingLeft: 3,
                          paddingRight: 3,
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpenModalImage(image)}
                      />
                    </Grid>
                  </>
                ))}
              {contractProgressImages.length === 0 && (
                <Card
                  sx={{
                    backgroundColor: "#F7F7F7",
                    padding: 1,
                    height: "300px",
                    width: "470px",
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexWrap: "wrap",
                  }}
                >
                  No images attached
                </Card>
              )}
            </Grid>
          </Grid>
        </Card>
        {user?.profession !== "Representative" ? null : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              position: "sticky",
              backgroundColor: "#F5F5F5",
              bottom: 0,
            }}
          >
            <Button
              onClick={() => handleResponse(offerId)}
              sx={{
                background: "rgba(4, 119, 130, 1)",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "rgba(1, 150, 164, 1)",
                },
                "&:disabled": {
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#fff",
                borderRadius: "4px",
                letterSpacing: "1.25px",
                margin: "15px",
              }}
              type="submit"
            >
              Accept offer
            </Button>

            <Button
              onClick={cancelEdit}
              sx={{
                background: "#fff",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#047782",
                letterSpacing: "1.25px",
                border: "1px solid #047782",
                borderRadius: "4px",
                margin: "15px",
              }}
            >
              Cancel
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default OfferPropertyViewPage;
