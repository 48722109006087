import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  setChat,
  addMessage,
  addUser,
  clearChat,
} from "../../features/messageCenter/Chat/chatSlice";
import { ChatModel, MessageModel, UserForChat } from "../models/messageCenter";
import { getAccessToken } from "../util/util";
import { useAppDispatch } from "./configureStore";
import { MessageForCreation } from "../models/messageForCreation";
import { toast } from "react-toastify";

// Message center connection is stored in useState and passed through Context
// because the connection cannot be stored inside a Redux Slice
// (Error: It is not serializable)

interface ChatConnectionContextValue {
  onJoinChat: (chatId: string) => void;
  onLeaveChat: () => void;
  onSendMessage: (text: string) => Promise<void>;
}

export const ChatConnectionContext = createContext<ChatConnectionContextValue>({
  onJoinChat: (chatId) => {},
  onLeaveChat: () => {},
  onSendMessage: async (text) => {},
});

export function useChatConnectionContext() {
  return useContext(ChatConnectionContext);
}

export function ChatConnectionContextProvider({ children }: PropsWithChildren) {
  const dispatch = useAppDispatch();
  const [connection, setConnection] = useState<HubConnection>();
  const [chatId, setChatId] = useState<string>();

  const joinChatHandler = useCallback((chatId: string) => {
    setChatId(chatId);
  }, []);

  const leaveChatHandler = useCallback(() => {
    setChatId(undefined);
    dispatch(clearChat());
  }, [dispatch]);

  const sendMessageHandler = async (text: string) => {
    const message: MessageForCreation = { text };

    try {
      await connection?.invoke("SendMessage", message);
    } catch (e) {
      toast.error(
        <>
          <div>Error sending messages.</div>
          <div>Please restart the application and try again.</div>
        </>
      );
    }
  };

  useEffect(() => {
    if (!chatId) return;

    const connection = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_CHAT_HUB_URL!, {
        accessTokenFactory: getAccessToken,
      })
      .configureLogging(LogLevel.Information)
      .build();

    connection.on("ReceiveChatHistory", (chat: ChatModel) => {
      dispatch(setChat(chat));
    });

    connection.on("ReceiveMessage", (message: MessageModel) => {
      dispatch(addMessage(message));
    });

    connection.on("ReceiveNewlyConnectedUser", (user: UserForChat) => {
      dispatch(addUser(user));
    });

    connection.start().then(() => {
      connection.invoke("JoinChat", chatId);
    });

    setConnection(connection);

    return () => {
      connection.stop();
    };
  }, [chatId, dispatch]);

  return (
    <ChatConnectionContext.Provider
      value={{
        onJoinChat: joinChatHandler,
        onLeaveChat: leaveChatHandler,
        onSendMessage: sendMessageHandler,
      }}
    >
      {children}
    </ChatConnectionContext.Provider>
  );
}
