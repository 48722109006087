import {Property} from "../../app/models/property";
import PropertyCard from "./PropertyCard";

interface Props {
  properties: Property[];
  handleFavoriteChange: (propertyId: any) => void;
}

const PropertyList = ({properties, handleFavoriteChange}: Props) => {
  return (
    <>
      {properties &&
        properties.map((property) => (
          <PropertyCard
            key={property.id}
            property={property}
            handleFavoriteChange={handleFavoriteChange}
          />
        ))}
    </>
  );
};

export default PropertyList;
