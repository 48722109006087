import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Box, Card, Checkbox} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import agent from "../../../app/api/agent";
import LoaderWithLinearProgress from "../../../app/components/LoaderWithLinearProgress";

interface Props {
  dealId: number;
}

const RfpOffersTable = ({dealId}: Props) => {
  const [offers, setOffers] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleRFPResponseOffer = (offerId: any) => {
    navigate(`/offer/rfpfilled/${offerId}`);
  };

  useEffect(() => {
    setLoading(true);
    agent.RFPResponses.allResponses(dealId)
      .then((response) => {
        console.log(response);
        setOffers(response.$values);
      })
      .catch((error) => console.log(error.response))
      .finally(() => setLoading(false));
  }, [dealId]);

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <Card sx={{height: "calc(100% - 54.5px)"}}>
      {offers && offers.length > 0 ? (
        <Box sx={{display: "flex", flexDirection: "column"}}>
          <Card
            sx={{
              display: "inline-flex",
              width: "100%",
              backgroundColor: "#FFF",
              border: "none",
              boxShadow: "none",
            }}
          >
            <Table sx={{minWidth: 650}}>
              <TableHead>
                <TableRow>
                  <TableCell width="5.5%"></TableCell>
                  <TableCell width="15.75%">Name</TableCell>
                  <TableCell width="15.75%">Company Name</TableCell>
                  <TableCell width="15.75%">For Whom</TableCell>
                  <TableCell width="15.75%">
                    Time required to complete
                  </TableCell>
                  <TableCell width="15.75%">Cost (GBP)</TableCell>
                  <TableCell width="15.75%">Scope of Work</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Card>

          {offers.map((offer) => (
            <Card
              sx={{
                display: "inline-flex",
                width: "100%",
                backgroundColor: "#FFF",
                border: "none",
                boxShadow: "none",
              }}
              key={offer.id}
            >
              <Table sx={{minWidth: 650}}>
                <TableBody>
                  <TableRow onClick={() => handleRFPResponseOffer(offer.id)}>
                    <TableCell width="5.5%" component="th" scope="row">
                      <Checkbox />
                    </TableCell>
                    <TableCell width="15.75%"> {offer?.firstName}</TableCell>
                    <TableCell width="15.75%">{offer?.companyName}</TableCell>
                    <TableCell width="15.75%">{offer.forWhom}</TableCell>
                    <TableCell width="15.75%"> {offer.timeRequired}</TableCell>
                    <TableCell width="15.75%"> {offer.cost}</TableCell>
                    <TableCell width="15.75%">{offer.scopeOfWork}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          ))}
        </Box>
      ) : (
        <Card
          sx={{
            boxShadow: "none",
            backgroundColor: "#FFF",
            width: "100%",
            padding: "10px",
            marginLeft: "10px",
          }}
        >
          There are no offers
        </Card>
      )}
    </Card>
  );
};

export default RfpOffersTable;
