import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {SpeedDial, SpeedDialIcon, SpeedDialAction} from "@mui/material";
import {useState} from "react";

interface Props {
  actionIcons: {icon: any; name: any; show: any}[];
}

export default function DrawerSpeedDial({actionIcons}: Props) {
  const [openSpeedButton, setOpenSpeedButton] = useState(false);

  const handleToggleSpeedButton = () => {
    setOpenSpeedButton((prevState) => !prevState);
  };

  return (
    <SpeedDial
      ariaLabel="Deal actions"
      sx={{position: "absolute", bottom: "20px", marginLeft: "-80px"}}
      icon={<SpeedDialIcon openIcon={<CloseOutlinedIcon />} />}
      FabProps={{
        sx: {
          backgroundColor: "rgba(4, 119, 130, 1)",
          "&:hover": {
            backgroundColor: "rgba(1, 150, 164, 1)",
          },
        },
      }}
      open={openSpeedButton}
      onClick={handleToggleSpeedButton}
    >
      {actionIcons.map(
        (action) =>
          action.show && (
            <SpeedDialAction
              sx={{display: "flex", alignItems: "center", color: "#047782"}}
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
            />
          )
      )}
    </SpeedDial>
  );
}
