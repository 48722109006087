import {
  Grid,
  Typography,
  Button,
  Card,
  Box,
  CardActions,
  TextField,
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {useAppDispatch, useAppSelector} from "../../app/store/configureStore";
import {useEffect, useState} from "react";
import agent from "../../app/api/agent";
import LoaderWithLinearProgress from "../../app/components/LoaderWithLinearProgress";
import {toast} from "react-toastify";
import {RfpResponse} from "../../app/models/rfpResponse";
import RfpAdditionalDocumentsListCard from "../../app/components/RfpAdditionalDocumentsListCard";
import {
  openCreateChat,
  openMessageCentar,
} from "../messageCenter/messageCenterSlice";
import PrimaryButton from "../../app/components/PrimaryButton";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const PropertyServiceResponseViewPage = () => {
  const {user} = useAppSelector((state) => state.account);
  const navigate = useNavigate();
  const {id} = useParams<{id: string}>();
  const [loading, setLoading] = useState(false);
  const [offer, setOffer] = useState<RfpResponse>();
  const [rfpResponseDocuments, setRfpResponseDocuments] = useState([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      setLoading(true);
      agent.OfferProperty.GetOneServiceOfferPropertyWithoutRFP(parseInt(id))
        .then((response) => {
          console.log(response);
          setOffer(response);
          setRfpResponseDocuments(response.documents.$values);
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
    }
  }, [id]);

  // async function handleAcceptOffer() {
  //   if (id) {
  //     setLoading(true);
  //     agent.RFPResponses.update(parseInt(id))
  //       .then(() => {
  //         toast.success("Offer was accepted successfully");
  //       })
  //       .catch((error) => console.log(error.response))
  //       .finally(() => setLoading(false));
  //   }
  // }

  const handleInquiry = () => {
    dispatch(openMessageCentar());
    dispatch(openCreateChat());

    toast.warning(
      "Create new chat to Inquire. Use the Email Address of the User"
    );
  };

  async function cancelEdit() {
    navigate(-1);
  }

  if (!user) {
    navigate("/login");
  }

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F4F4F4",
          height: "100%",
          width: "100%",
          padding: "20px",
          paddingTop: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            width: "100%",
            alignItems: "center",
            position: "sticky",
            backgroundColor: "#F5F5F5",
            top: 0,
            height: "60px",
            zIndex: "10",
          }}
        >
          <Box onClick={() => cancelEdit()}>
            <ArrowBackIosNewIcon
              sx={{
                display: "flex",
                color: "rgba(112, 112, 112, 1)",
                "&:hover": {
                  color: "rgba(208, 235, 238, 1)",
                },
              }}
            />
          </Box>
        </Box>

        <Card sx={{padding: "20px", height: "100%"}}>
          <Grid
            item
            xs={12}
            sx={{
              paddingLeft: "25px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Roboto",
                color: "#424242",
                fontWeight: "600",
                fontSize: "23px",
              }}
            >
              {offer?.role.replace(/([A-Z])/g, " $1").trim()} Service Offer
            </Typography>
          </Grid>

          <Grid
            container
            spacing={3}
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              width: "50%",
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          >
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="standard"
                label="First Name"
                type="text"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                name="FirstName"
                value={offer?.firstName}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="standard"
                label="Last Name"
                type="text"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                name="LastName"
                value={offer?.lastName}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                label="Email Address"
                type="text"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                name="EmailAddress"
                value={offer?.emailAddress}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="standard"
                label="Phone Number"
                type="text"
                name="PhoneNumber"
                className="no-spinners"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                value={offer?.phoneNumber}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="standard"
                label="Office Number"
                type="text"
                name="OfficeNumber"
                className="no-spinners"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                value={offer?.officeNumber}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                label="Company Name"
                type="text"
                name="CompanyName"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                value={offer?.companyName}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                label="Company Type"
                type="text"
                name="CompanyType"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                value={offer?.companyType}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="standard"
                label="Company Address"
                type="text"
                name="CompanyAddress"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                value={offer?.companyAddress}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="standard"
                label="Unit"
                type="text"
                name="Unit"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                value={offer?.unit}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="standard"
                label="City"
                name="City"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                value={offer?.city}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="standard"
                label="Postal Code"
                type="text"
                name="PostalCode"
                className="no-spinners"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                value={offer?.postalCode}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="standard"
                label="State"
                name="State"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                value={offer?.state}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="standard"
                label="Country"
                name="Country"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                value={offer?.country}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                disabled
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              width: "50%",
              paddingLeft: "20px",
              paddingRight: "20px",

              marginBottom: "80px",
            }}
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                label="Time required to complete"
                name="TimeRequired"
                type="text"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                value={offer?.timeRequired}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                label="Cost (GBP)"
                type="text"
                name="Cost"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                value={offer?.cost}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                label="Scope of Work"
                type="text"
                name="ScopeOfWork"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                value={offer?.scopeOfWork}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{fontWeight: "700", paddingBottom: "20px"}}>
                Attach proposal
              </Typography>
              {rfpResponseDocuments && rfpResponseDocuments.length > 0 ? (
                <Card sx={{backgroundColor: "#F7F7F7", padding: 1}}>
                  <CardActions
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <RfpAdditionalDocumentsListCard
                        rfpDocuments={rfpResponseDocuments}
                        name="x"
                      ></RfpAdditionalDocumentsListCard>
                    </CardActions>
                  </CardActions>
                </Card>
              ) : (
                <Card
                  sx={{
                    backgroundColor: "#F7F7F7",
                    padding: 1,
                    height: "300px",
                    width: "470px",
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexWrap: "wrap",
                  }}
                >
                  No documents attached
                </Card>
              )}
            </Grid>
          </Grid>
        </Card>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            position: "sticky",
            backgroundColor: "#F5F5F5",
            bottom: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "rgba(253, 237, 237, 1)",
              margin: "15px",
              paddingLeft: "10px",
              paddingRight: "10px",
              position: "relative",
              right: "250px",
            }}
          >
            <ErrorOutlineIcon sx={{color: "rgba(255, 82, 82, 1)"}} />
            <Typography
              sx={{
                color: "rgba(95, 33, 32, 0.87)",
                fontFamily: "Roboto",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "24px",
                letterSpacing: "0.5px",
              }}
            >
              Service Provider offers can only be accepted during the “Prepare
              Offer” process.
            </Typography>
          </Box>
          <PrimaryButton disabled>Accept Offer</PrimaryButton>
          <Button
            onClick={() => handleInquiry()}
            sx={{
              background: "#fff",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontSize: "0.875rem",
              fontWeight: "400",
              color: "#047782",
              letterSpacing: "1.25px",
              border: "1px solid #047782",
              borderRadius: "4px",
              margin: "15px",
            }}
          >
            Inquire
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default PropertyServiceResponseViewPage;
