import {useNavigate, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import {Grid, Typography, Button, TextField, Card, Box} from "@mui/material";
import agent from "../../app/api/agent";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {toast} from "react-toastify";
import {Inquiry} from "../../app/models/inquiry";
import LoaderWithLinearProgress from "../../app/components/LoaderWithLinearProgress";
import {useAppDispatch} from "../../app/store/configureStore";
import {
  openCreateChat,
  openMessageCentar,
} from "../messageCenter/messageCenterSlice";

export default function InquiryRFPViewPage() {
  const navigate = useNavigate();
  const {
    formState: {isSubmitting},
  } = useForm();
  const {id} = useParams<{id: string}>();
  const [loading, setLoading] = useState(false);
  const [inquiry, setInquiry] = useState<Inquiry>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      setLoading(true);
      agent.InquriesRFP.oneRFPInquiry(parseInt(id))
        .then((response) => {
          setInquiry(response);
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
    }
  }, [id]);

  async function handleCancel() {
    navigate(-1);
  }

  // async function handleResponse(id: string | undefined) {
  //   if (id) {
  //     setLoading(true);
  //     agent.InquriesProperty.update(parseInt(id))
  //       .then(() => {
  //         if (inquiry) {
  //           inquiry.isAccepted = true;
  //         }
  //         toast.success("Simulated Reply For Now");
  //         navigate(-1);
  //       })
  //       .catch((error) => console.log(error.response))
  //       .finally(() => setLoading(false));
  //   }
  // }

  const handleInquiry = () => {
    dispatch(openMessageCentar());
    dispatch(openCreateChat());

    toast.warning(
      "Create new chat to Inquire. Use the Email Address of the User"
    );
  };

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F4F4F4",
          height: "100%",
          width: "100%",
          padding: "20px",
          paddingTop: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            width: "100%",
            alignItems: "center",
            position: "sticky",
            backgroundColor: "#F5F5F5",
            top: 0,
            height: "60px",
            zIndex: "10",
          }}
        >
          <Box onClick={() => handleCancel()}>
            <ArrowBackIosNewIcon
              sx={{
                display: "flex",
                color: "rgba(112, 112, 112, 1)",
                "&:hover": {
                  color: "rgba(208, 235, 238, 1)",
                },
              }}
            />
          </Box>
        </Box>
        <Card sx={{padding: "20px", height: "87%"}}>
          <Grid
            container
            spacing={3}
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              width: "50%",
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          >
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Roboto",
                  color: "#424242",
                  fontWeight: "600",
                  fontSize: "23px",
                }}
              >
                Inquiry
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="standard"
                disabled
                label="First Name"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                type="text"
                name="FirstName"
                value={inquiry?.firstName}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="standard"
                disabled
                label="Last Name"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                type="text"
                name="LastName"
                value={inquiry?.lastName}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                disabled
                label="Email Address"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                type="text"
                name="EmailAddress"
                value={inquiry?.emailAddress}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                disabled
                label="Phone Number"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                type="text"
                name="PhoneNumber"
                className="no-spinners"
                value={inquiry?.phoneNumber}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="standard"
                disabled
                label="Address"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                type="text"
                name="Address"
                value={inquiry?.address}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="standard"
                disabled
                label="City"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                name="City"
                value={inquiry?.city}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="standard"
                disabled
                label="State"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                name="State"
                value={inquiry?.state}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="standard"
                disabled
                label="Country"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                name="Country"
                value={inquiry?.country}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                disabled
                label="Inquiry Type"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                type="text"
                name="InquiryType"
                value={inquiry?.inquiryType}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                disabled
                label="Offer Price (Optional)"
                InputLabelProps={{
                  style: {color: "#000000"},
                }}
                name="OfferPrice"
                value={inquiry?.offerPrice}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              width: "50%",
              paddingLeft: "20px",
              paddingRight: "20px",
              height: "100%",
            }}
          >
            <Grid item xs={12}>
              <Box height="40px"></Box>
              <Box>
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Roboto",
                    color: "#424242",
                    fontWeight: "700",
                    fontSize: "16px",
                    paddingLeft: "22px",
                    paddingBottom: "20px",
                  }}
                >
                  Message
                </Typography>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="standard"
                    disabled
                    label="Type your inqury here"
                    InputLabelProps={{
                      style: {color: "#000000"},
                    }}
                    type="text"
                    multiline
                    name="InquryMessage"
                    value={inquiry?.inquiryMessage}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Card>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            position: "sticky",
            backgroundColor: "#F5F5F5",
            bottom: 0,
          }}
        >
          <LoadingButton
            onClick={() => handleInquiry()}
            sx={{
              background: "rgba(4, 119, 130, 1)",
              "&.MuiButtonBase-root:hover": {
                bgcolor: "rgba(1, 150, 164, 1)",
              },
              "&:disabled": {
                backgroundColor: "rgba(0, 0, 0, 0.12)",
              },
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontSize: "0.875rem",
              fontWeight: "400",
              color: "#fff",
              borderRadius: "4px",
              letterSpacing: "1.25px",
              margin: "15px",
            }}
            loading={isSubmitting}
            type="submit"
          >
            Respond to Inquiry
          </LoadingButton>

          <Button
            onClick={handleCancel}
            sx={{
              background: "#fff",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontSize: "0.875rem",
              fontWeight: "400",
              color: "#047782",
              letterSpacing: "1.25px",
              border: "1px solid #047782",
              borderRadius: "4px",
              margin: "15px",
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
}
