import {Grid, Typography, Button, Card, Box, CardActions} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {useAppSelector} from "../../app/store/configureStore";
import {FieldValues, useForm} from "react-hook-form";
import AppTextInput from "../../app/components/AppTextInputCustom";
import {useEffect, useState} from "react";
import agent from "../../app/api/agent";
import LoaderWithLinearProgress from "../../app/components/LoaderWithLinearProgress";
import {toast} from "react-toastify";
import ReusableDropzoneMultipleImages from "../../app/components/ReusableDropzoneMultipleImages";

const PropertyServiceResponse = () => {
  const {user} = useAppSelector((state) => state.account);
  const navigate = useNavigate();
  const {control, handleSubmit} = useForm();
  const {id} = useParams<{id: string}>();
  const [loading, setLoading] = useState(false);
  const [property, setProperty] = useState({
    id: 0,
  });

  async function handleSubmitData(data: FieldValues) {
    setLoading(true);
    data.propertyId = property.id;
    try {
      agent.Marketplace.createPropertyResponse(data).then(() => {
        toast.success("Service offer was sent successfully");
        navigate(-1);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      agent.Marketplace.details(parseInt(id))
        .then((response) => {
          setProperty(response);
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
    }
  }, [id]);

  async function cancelEdit() {
    navigate(-1);
  }

  if (!user) {
    navigate("/login");
  }

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F4F4F4",
          height: "100%",
          width: "100%",
          padding: "20px",
          paddingTop: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            width: "100%",
            alignItems: "center",
            position: "sticky",
            backgroundColor: "#F5F5F5",
            top: 0,
            height: "60px",
            zIndex: "10",
          }}
        >
          <Box onClick={() => cancelEdit()}>
            <ArrowBackIosNewIcon
              sx={{
                display: "flex",
                color: "rgba(112, 112, 112, 1)",
                "&:hover": {
                  color: "rgba(208, 235, 238, 1)",
                },
              }}
            />
          </Box>
        </Box>
        <form onSubmit={handleSubmit(handleSubmitData)}>
          <Card sx={{padding: "20px", height: "100%"}}>
            <Grid
              item
              xs={12}
              sx={{
                paddingLeft: "25px",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Roboto",
                  color: "#424242",
                  fontWeight: "600",
                  fontSize: "23px",
                }}
              >
                {user?.profession.replace(/([A-Z])/g, " $1").trim()} Service
                Offer
              </Typography>
            </Grid>

            <Grid item xs={6} sx={{display: "none"}}>
              <AppTextInput
                control={control}
                label="PropertyId"
                name="propertyId"
                value={property.id}
              />
            </Grid>

            <Grid
              container
              spacing={3}
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                width: "50%",
                paddingLeft: "50px",
                paddingRight: "50px",
              }}
            >
              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="First Name"
                  type="text"
                  name="FirstName"
                  required
                  defaultValue={user?.firstName}
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Last Name"
                  type="text"
                  name="LastName"
                  required
                  defaultValue={user?.lastName}
                />
              </Grid>

              <Grid item xs={12}>
                <AppTextInput
                  control={control}
                  label="Email Address"
                  type="text"
                  name="EmailAddress"
                  required
                  defaultValue={user?.email}
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Phone Number"
                  type="text"
                  name="PhoneNumber"
                  className="no-spinners"
                  defaultValue={user?.phoneNumber}
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Office Number"
                  type="text"
                  name="OfficeNumber"
                  className="no-spinners"
                  defaultValue={user?.phoneNumber}
                />
              </Grid>

              <Grid item xs={12}>
                <AppTextInput
                  control={control}
                  label="Company Name"
                  type="text"
                  name="CompanyName"
                  defaultValue={user?.companyName}
                />
              </Grid>

              <Grid item xs={12}>
                <AppTextInput
                  control={control}
                  label="Company Type"
                  type="text"
                  name="CompanyType"
                  defaultValue={user?.companyType}
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Company Address"
                  type="text"
                  name="CompanyAddress"
                  defaultValue={user?.companyAddress}
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Unit"
                  type="text"
                  name="Unit"
                  defaultValue={user?.unit}
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="City"
                  name="City"
                  defaultValue={user?.city}
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Postal Code"
                  type="text"
                  name="PostalCode"
                  defaultValue={user?.postalCode}
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="State"
                  name="State"
                  defaultValue={user?.state}
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Country"
                  name="Country"
                  defaultValue={user?.country}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                width: "50%",
                paddingLeft: "20px",
                paddingRight: "20px",
                height: "100%",
                marginBottom: "80px",
              }}
            >
              <Grid item xs={12}>
                <AppTextInput
                  control={control}
                  label="Time required to complete"
                  name="TimeRequired"
                  type="text"
                />
              </Grid>

              <Grid item xs={12}>
                <AppTextInput
                  control={control}
                  label="Cost (GBP)"
                  type="text"
                  name="Cost"
                />
              </Grid>

              <Grid item xs={12}>
                <AppTextInput
                  control={control}
                  label="Scope of Work"
                  type="text"
                  name="ScopeOfWork"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{fontWeight: "700", paddingBottom: "20px"}}>
                  Attach proposal
                </Typography>
                <Card
                  sx={{
                    backgroundColor: "#F7F7F7",
                    padding: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CardActions>
                    <ReusableDropzoneMultipleImages
                      control={control}
                      name="RFPDocuments"
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        width="100%"
                        alignItems="center"
                      >
                        <img
                          src={"/images/DropzoneIcon.png"}
                          alt="IMG"
                          style={{
                            height: "150px",
                            width: "150px",
                            marginBottom: "15px",
                            marginTop: "15px",
                          }}
                        />
                        <Typography variant="subtitle1">
                          Drag and drop a photo here to upload or
                          <Typography
                            sx={{
                              textDecoration: "underline",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            click here to browse
                          </Typography>
                        </Typography>
                      </Box>
                    </ReusableDropzoneMultipleImages>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Card>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              position: "sticky",
              backgroundColor: "#F5F5F5",
              bottom: 0,
            }}
          >
            <Button
              sx={{
                background: "rgba(4, 119, 130, 1)",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "rgba(1, 150, 164, 1)",
                },
                "&:disabled": {
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#fff",
                borderRadius: "4px",
                letterSpacing: "1.25px",
                margin: "15px",
              }}
              type="submit"
            >
              Submit
            </Button>

            <Button
              onClick={cancelEdit}
              sx={{
                background: "#fff",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#047782",
                letterSpacing: "1.25px",
                border: "1px solid #047782",
                borderRadius: "4px",
                margin: "15px",
              }}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default PropertyServiceResponse;
