import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import {useForm} from "react-hook-form";
import {Form, Link, NavLink, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import agent from "../../app/api/agent";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import React, {useState} from "react";

const Register = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: {isSubmitting, errors},
  } = useForm();
  const [termsChecked, setTermsChecked] = useState(false);
  const [dataProcessingChecked, setDataProcessingChecked] = useState(false);
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);

  const handleCheckboxChange = (event: any) => {
    const {name, checked} = event.target;
    switch (name) {
      case "terms":
        setTermsChecked(checked);
        break;
      case "dataProcessing":
        setDataProcessingChecked(checked);
        break;
      case "privacyPolicy":
        setPrivacyPolicyChecked(checked);
        break;
      default:
        break;
    }
  };

  const areAllCheckboxesChecked = () => {
    return termsChecked && dataProcessingChecked && privacyPolicyChecked;
  };

  function handleApiErrors(errors: any) {
    if (errors) {
      errors.forEach((error: string) => {
        if (error !== "1") {
          setError("existingUser", {message: error});
          toast.error(error);
        }
        if (error.includes("Password")) {
          setError("password", {message: error});
        } else if (error.includes("Email")) {
          setError("email", {message: error});
        }
        // else if (error.includes("Username")) {
        //   setError("username", {message: error});
        // }
      });
    }
  }

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({...values, showPassword: !values.showPassword});
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  // const handlePasswordChange = (prop: any) => (event: any) => {
  //   setValues({...values, [prop]: event.target.value});
  // };

  const handlePasswordChange = (event: any) => {
    setValues({...values, password: event.target.value});
  };

  return (
    <Form
      onSubmit={handleSubmit((data) =>
        agent.Account.register(data)
          .then(() => {
            toast.success("Registration successful - you can now login");
            navigate("/login");
          })
          .catch((error) => handleApiErrors(error))
      )}
    >
      <Container
        component="main"
        maxWidth="xl"
        sx={{display: "flex", justifyContent: "center"}}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "absolute",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            backgroundImage: "url(../../images/sean-pollock-new.jpg)",
            backgroundSize: "cover",
          }}
        >
          <Grid
            container
            style={{
              width: "924px",
              height: "500px",
              backgroundColor: "white",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              padding: "25px",
            }}
          >
            <Grid
              item
              xs={6}
              sx={{borderRight: "2px solid #f5f5f5", padding: "20px"}}
            >
              <Typography
                component="p"
                variant="h5"
                sx={{
                  height: "32px",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "23px",
                  lineHeight: "32px",
                  color: "#424242",
                }}
              >
                Why join Domunus?
              </Typography>

              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "20px",
                  lineHeight: "32px",
                  letterSpacing: "0.25px",
                  color: "#616161",
                }}
              >
                <List
                  sx={{
                    listStyleType: "disc",
                    pl: 2,
                    "& .MuiListItem-root": {
                      display: "list-item",
                    },
                  }}
                >
                  <ListItem>
                    Increase your efficiency and profitability with one place
                    for all your Commercial & Residential Real Estate
                    communication, documents and tasks.
                  </ListItem>
                  <ListItem>
                    Improve your business with qualified leads and offers at
                    best cost.
                  </ListItem>
                  <ListItem>
                    Strengthen your market exposure with real-time business
                    intelligence on the marketplace.
                  </ListItem>
                </List>
              </Typography>
            </Grid>

            <Grid item xs={6} sx={{padding: "20px"}}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "20px",
                  lineHeight: "32px",
                  letterSpacing: "0.25px",
                  color: "#424242",
                  paddingBottom: "30px",
                }}
              >
                Create an account
              </Typography>

              {/* <Input
                sx={{width: "100%", paddingBottom: "5px"}}
                type="username"
                {...register("username", {required: "Username is required"})}
                autoFocus
                placeholder="User Name"
              ></Input>
              {errors && (
                <Typography sx={{color: "error.main"}}>
                  {!!errors.username
                    ? (errors?.username?.message as string)
                    : ""}
                </Typography>
              )} */}

              <Grid>
                <Input
                  sx={{width: "100%", color: "#000000", paddingBottom: "5px"}}
                  type="email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^\w+[\w-.]*@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/,
                      message: "Not a valid email address",
                    },
                  })}
                  placeholder="Email Address"
                  autoFocus
                />
                {errors && (
                  <Typography sx={{color: "error.main"}}>
                    {!!errors.email ? (errors?.email?.message as string) : ""}
                  </Typography>
                )}
              </Grid>

              <Grid>
                <Input
                  sx={{width: "100%", paddingBottom: "5px"}}
                  {...register("password", {
                    required: "Password is required",
                    pattern: {
                      value:
                        /^(?=.{6,})(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;"'<>,.?/~`|\\]).*$/,
                      message:
                        "Password must be at least 6 characters long and contain at least one digit and one special character.",
                    },
                  })}
                  placeholder="Password"
                  type={values.showPassword ? "text" : "password"}
                  //   onChange={handlePasswordChange("password")}
                  onInput={handlePasswordChange}
                  value={values.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors && (
                  <Typography sx={{color: "error.main"}}>
                    {!!errors.password
                      ? (errors?.password?.message as string)
                      : ""}
                  </Typography>
                )}
              </Grid>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox name="terms" onChange={handleCheckboxChange} />
                  }
                  required
                  label={
                    <Typography>
                      I agree to the&nbsp;
                      <Link target="_blank" to="/documents/TermsOfService.pdf">
                        Terms of Service
                      </Link>
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="dataProcessing"
                      onChange={handleCheckboxChange}
                    />
                  }
                  required
                  label={
                    <Typography>
                      I agree to the&nbsp;
                      <Link
                        target="_blank"
                        to="/documents/DataProcessingAddendum.pdf"
                      >
                        Data Processing Addendum
                      </Link>
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="privacyPolicy"
                      onChange={handleCheckboxChange}
                    />
                  }
                  required
                  label={
                    <Typography>
                      I agree to the&nbsp;
                      <Link target="_blank" to="/documents/PrivacyPolicy.pdf">
                        Privacy Policy
                      </Link>
                    </Typography>
                  }
                />
              </FormGroup>

              <Grid
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  paddingBottom: "0",
                }}
              >
                <NavLink
                  to="/login"
                  style={{
                    marginTop: "50px",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontSize: "14px",
                    fontWeight: "500",
                    letterSpacing: "1.25px",
                    textAlign: "center",
                    marginRight: "25px",
                    textTransform: "uppercase",
                    color: "#047782",
                    width: "210px",
                    textDecoration: "none",
                  }}
                >
                  Already a user? Sign in
                </NavLink>

                <Button
                  sx={{
                    marginTop: "50px",
                    alignItems: "center",
                    background: "#047782",
                    "&:hover": {
                      backgroundColor: "#047782",
                    },
                    borderRadius: "4px",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontSize: "14px",
                    fontWeight: "500",
                    letterSpacing: "1.25px",
                    textAlign: "center",
                    width: "119px",
                    height: "36px",
                  }}
                  variant="contained"
                  type="submit"
                  disabled={!areAllCheckboxesChecked()}
                >
                  {isSubmitting ? "Loading..." : "Sign Up"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Form>
  );
};

export default Register;
