import {Grid} from "@mui/material";
import {UseControllerProps} from "react-hook-form";
import DocumentViewCardOfferDocuments from "./DocumentViewCardOfferDocuments";

interface Props extends UseControllerProps {
  rfpDocuments: any[];
  onSetModalOpen: (document: any) => void;
}

export default function RfpAdditionalDocumentsListCardWithOfferPreviewItems({
  rfpDocuments,
  onSetModalOpen,
}: Props) {
  return (
    <Grid container spacing={1} margin="auto">
      {rfpDocuments.map((document: any) => (
        <Grid item key={document.id}>
          <DocumentViewCardOfferDocuments
            document={document}
            onSetModalOpen={onSetModalOpen}
          />
        </Grid>
      ))}
    </Grid>
  );
}
