import {Box} from "@mui/material";
import {useEffect, useState} from "react";
import LoaderWithLinearProgress from "../../app/components/LoaderWithLinearProgress";
import {NavLink, useParams} from "react-router-dom";
import agent from "../../app/api/agent";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {RFP} from "../../app/models/rfp";
import RFPCard from "./RFPCard";
import RfpOffersDrawerMarketplace from "../../app/components/RfpOffersMarketplace";

interface Props {
  handleFavoriteRfpChange: (rfpId: any) => void;
}

export default function RfpResponseOffersAndInquiries({
  handleFavoriteRfpChange,
}: Props) {
  const [loading, setLoading] = useState(false);
  const {id} = useParams<{id: string}>();
  const [rfp, setRfp] = useState<RFP>();

  useEffect(() => {
    if (id) {
      setLoading(true);
      agent.RFPs.RFPDetails(parseInt(id))
        .then((response) => {
          setRfp(response);
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
    }
  }, [id]);

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <Box
      sx={{
        backgroundColor: "#F4F4F4",
        height: "100%",
        width: "100%",
        paddingBottom: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            width: "100%",
            alignItems: "center",
            position: "sticky",
            backgroundColor: "#F5F5F5",
            top: 0,
            height: "60px",
            zIndex: "10",
          }}
        >
          <NavLink to="/marketplace">
            <ArrowBackIosNewIcon
              sx={{
                color: "rgba(112, 112, 112, 1)",
                "&:hover": {
                  color: "rgba(208, 235, 238, 1)",
                },
              }}
            />
          </NavLink>
        </Box>
      </Box>
      {rfp && (
        <>
          <RFPCard
            rfp={rfp}
            handleFavoriteRfpChange={handleFavoriteRfpChange}
          />{" "}
          <RfpOffersDrawerMarketplace rfp={rfp} />
        </>
      )}
    </Box>
  );
}
