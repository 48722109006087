import { Box, Typography } from "@mui/material";
import {
  MessageGroupType,
  MessageGroupTypes,
} from "../../app/models/messageCenter";

interface Props {
  type: MessageGroupType;
  isOpenMessageGroupChat?: boolean;
  onCloseMessageGroupChat?: () => void;
}

export default function MessageGroupHeader({
  type,
  isOpenMessageGroupChat,
  onCloseMessageGroupChat,
}: Props) {
  let backgroundColor;
  switch (type) {
    case MessageGroupTypes.action:
      backgroundColor = "rgba(188, 224, 253, 1)";
      break;
    case MessageGroupTypes.global:
      backgroundColor = "rgba(189, 238, 218, 1)";
      break;
    case MessageGroupTypes.deal:
      backgroundColor = "rgba(189, 204, 220, 1)";
      break;
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{
        backgroundColor,
        paddingX: "13px",
        paddingTop: "8px",
        paddingBottom: "5px",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
      }}
    >
      <Typography variant="subtitle1" fontSize="15px">
        {type}
      </Typography>
      {type === MessageGroupTypes.global && isOpenMessageGroupChat && (
        <Typography
          variant="subtitle1"
          fontSize="15px"
          sx={{ cursor: "pointer" }}
          onClick={onCloseMessageGroupChat}
        >
          Back
        </Typography>
      )}
    </Box>
  );
}
