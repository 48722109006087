import { Box } from "@mui/material";
import MessageItem from "./MessageItem";
import MessageTimeDivider from "./MessageTimeDivider";
import { ChatModel } from "../../../app/models/messageCenter";
import { useEffect, useRef } from "react";
import { User } from "../../../app/models/user";

interface Props {
  appUser: User | null;
  chat: ChatModel | null;
}

export default function MessageContainer({ appUser, chat }: Props) {
  const messageRef = useRef<Element>();

  useEffect(() => {
    if (messageRef && messageRef.current) {
      const { scrollHeight, clientHeight } = messageRef.current;
      messageRef.current.scrollTo({
        left: 0,
        top: scrollHeight - clientHeight,
        behavior: "smooth",
      });
    }
  }, [chat?.messages]);

  return (
    <Box
      ref={messageRef}
      sx={{
        overflowX: "hidden",
        overflowY: "auto",
        flex: 1,
        paddingLeft: "30px",
        paddingRight: "20px",
      }}
    >
      {chat?.messages.map(({ text, timestamp, userId }, i, arr) => (
        <div key={`${userId}-${timestamp}`} className="message">
          <MessageTimeDivider
            timestamp={timestamp}
            prevTimestamp={arr[i - 1]?.timestamp}
          />
          <MessageItem
            appUser={appUser}
            user={chat.users[userId]}
            userId={userId}
            prevUserId={arr[i - 1]?.userId}
            message={text}
          />
        </div>
      ))}
    </Box>
  );
}
