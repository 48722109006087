import {PayloadAction, createSlice} from "@reduxjs/toolkit";

interface UISlice {
  openDealCardActionDrawerId: number;
}

const initialState: UISlice = {
  openDealCardActionDrawerId: 0,
};

export const uiSlice = createSlice({
  name: "uiSlice",
  initialState,
  reducers: {
    openDealCardAfterPrepareOfferByBuyer: (
      state,
      action: PayloadAction<number>
    ) => {
      state.openDealCardActionDrawerId = action.payload;
    },

    clearOpenDealCardAfterCloseOfDealCard: (state) => {
      state.openDealCardActionDrawerId =
        initialState.openDealCardActionDrawerId;
    },
  },
});

export const {
  openDealCardAfterPrepareOfferByBuyer,
  clearOpenDealCardAfterCloseOfDealCard,
} = uiSlice.actions;
