import {Box, Divider} from "@mui/material";
import PropertyInfo from "../../app/models/propertyInfo";
import {NotificationBody} from "./NotificationBody";

interface Props {
  notification: PropertyInfo;
}

export default function NotificationCard({notification}: Props) {
  return (
    <Box sx={{width: "400px", paddingY: "5px"}}>
      <NotificationBody notification={notification} />
      <Divider sx={{borderBottom: "1px solid black"}} />
    </Box>
  );
}
