import { Typography, Paper, Box } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";

interface Props {
  progressTracker: never[];
  onSetOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ConfirmationFormBuyer({
  progressTracker,
  onSetOpen,
}: Props) {
  function handleClick() {
    if (progressTracker.length > 29) {
      onSetOpen(true);
    } else {
      toast.error(
        "Please complete all the necessary tasks. The Confirmation Form will then be available for submission."
      );
    }
  }

  return (
    <div
      onClick={() => handleClick()}
      style={{
        opacity: progressTracker.length > 29 ? 1 : 0.5,
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: "200px",
          whiteSpace: "nowrap",
        }}
      >
        Confirmation Form (Buyer)
      </Typography>

      <Paper
        elevation={4}
        sx={{
          width: 200,
          height: 260,
          paddingX: 2.5,
          paddingY: 3,
          fontSize: "4px",
        }}
      >
        <Box
          sx={{
            wordWrap: "break-word",
            overflow: "hidden",
            height: 212,
            fontSize: "8px",
          }}
        >
          Offer Form <br /> <br />
          Lorem ipsum dolor sit amet consectetur. Consequat feugiat eleifend
          lobortis risus aliquam. Amet nunc maecenas mi elit erat ipsum orci
          consectetur. Sapien nec diam sit at commodo tincidunt turpis. Lacus
          convallis tortor et tristique. Summary Lorem ipsum dolor sit amet
          consectetur. <br /> <br />
          Consequat feugiat eleifend lobortis risus aliquam. Amet nunc maecenas
          mi elit erat ipsum orci consectetur. Sapien nec diam sit at commodo
          tincidunt turpis. Lacus convallis tortor et tristique.
        </Box>
      </Paper>
    </div>
  );
}
