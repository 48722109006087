import {
  Box,
  Card,
  Typography,
  Divider,
  MenuList,
  MenuItem,
} from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CircleIcon from "@mui/icons-material/Circle";
import {useEffect, useState} from "react";
import {useAppSelector} from "../../../app/store/configureStore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import agent from "../../../app/api/agent";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface Props {
  contractProgress: any;
  progressTracker: any;
  onHandleLvL2ClickItemClick: (id: any) => void;
  dateTimeTracking: any;
}

function DealProgressDrawer({
  contractProgress,
  progressTracker,
  onHandleLvL2ClickItemClick,
  dateTimeTracking,
}: Props) {
  const {user} = useAppSelector((state) => state.account);
  const [menu1Open, setMenu1Open] = useState(false);
  const [menu1IdOpen, setMenu1IdOpen] = useState<number>();
  const [daysinDeal, setDaysInDeal] = useState();

  function handleClick(menuId: number) {
    if (menuId) {
      if (menuId === menu1IdOpen) {
        setMenu1Open((prevState) => !prevState);

        if (menu1Open) {
          setMenu1IdOpen(undefined);
        }
      } else {
        setMenu1IdOpen(menuId);
        setMenu1Open(true);
        onHandleLvL2ClickItemClick(menuId);
      }
    }
  }
  useEffect(() => {
    if (dateTimeTracking) {
      agent.ContractProgressDeal.getDealDuration(dateTimeTracking).then(
        (response) => {
          setDaysInDeal(response.days);
        }
      );
    }
  }, [dateTimeTracking]);

  return (
    <>
      <Card
        sx={{
          backgroundColor: "#F2F6FA",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "300px",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingY={0.5}
        >
          <DateRangeIcon sx={{color: "#0D2561"}} />
          <Typography
            sx={{
              alignItems: "center",
              display: "flex",
              color: "#616161",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "36px",
              paddingLeft: "20px",
            }}
          >
            Duration: &nbsp;{" "}
            <b>{dateTimeTracking === undefined ? "N/A" : daysinDeal} Days</b>
          </Typography>
        </Box>
        <Divider
          sx={{
            marginLeft: "15px",
            marginRight: "15px",
            border: "1px solid #0D2561",
          }}
        />
        <MenuList sx={{overflowY: "auto"}}>
          {contractProgress &&
            contractProgress.mvpPhases.$values.map(
              (item: any, index: any): any => (
                <div key={index} style={{paddingTop: "10px"}}>
                  <MenuItem key={item.$id + index}>
                    {item.isCompleted === true ? (
                      <CheckCircleIcon
                        fontSize="medium"
                        sx={{color: "#0D2561"}}
                      />
                    ) : (
                      <CircleIcon fontSize="medium" sx={{color: "#0D2561"}} />
                    )}
                    <Typography
                      sx={{
                        paddingLeft: "10px",
                        color: "#000000DE",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        textAlign: "left",
                      }}
                    >
                      {item.phaseName}
                    </Typography>
                  </MenuItem>

                  {item.taskMessages.$values.map(
                    (element: any, index: any): any => (
                      <>
                        <MenuItem
                          key={element.$id + element.index}
                          sx={{
                            display: "flex",
                          }}
                          onClick={() => handleClick(element.id)}
                        >
                          {progressTracker.includes(element.id) ? (
                            <CheckCircleIcon
                              fontSize="small"
                              sx={{color: "rgba(13, 37, 97, 1)"}}
                            />
                          ) : (
                            <CircleIcon
                              fontSize="small"
                              sx={{color: "#9E9E9E"}}
                            />
                          )}
                          <Typography
                            sx={{
                              paddingLeft: "10px",
                              color: "#0D2561",
                              fontFamily: "Roboto",
                              fontSize: "12px",
                              fontWeight: "400",
                              lineHeight: "20px",
                              textAlign: "left",
                              textWrap: "wrap",
                            }}
                          >
                            {element.taskMessageName}
                          </Typography>
                          {menu1IdOpen === element.id ? (
                            <KeyboardArrowUpIcon sx={{color: "#0D2561"}} />
                          ) : (
                            <KeyboardArrowDownIcon sx={{color: "#0D2561"}} />
                          )}
                        </MenuItem>
                        {menu1Open && menu1IdOpen === element.id && (
                          <MenuList>
                            {user?.profession === "Buyer" &&
                            menu1IdOpen === 1 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Add your Representative</li>
                                  <li>Review property documents</li>
                                  <li>
                                    Deposit 1st Escrow Payment [External]{" "}
                                  </li>
                                  <li>
                                    Upload Escrow Receipt to Receipts for
                                    Escrow/Principal Payments
                                  </li>
                                  <li>
                                    Upload Mortgage Pre-Approval to Contract –
                                    Mortgage Pre-Approval{" "}
                                  </li>
                                  <li>Or: Add your Lender</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 1 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Add your Buyer</li>
                                  <li>Review property documents</li>
                                  <li>
                                    Upload P&S Agreement to Contract - P&S
                                    Agreement and Contract Amendment(s){" "}
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Lender" &&
                              element.id === 2 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Request documents from Buyer</li>
                                  <li>Upload Mortgage Pre-Approval</li>
                                  <li>OR: Upload documents for signature</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 2 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload your documents to Mortgage - Buyer
                                    Documents
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              menu1IdOpen === 3 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Sign documents provided by Lender</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Lender" &&
                              element.id === 4 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Upload Mortgage Pre-Approval</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 5 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Approve Mortgage Pre-Approval</li>
                                  <li>Approve Escrow Payment Receipt</li>
                                  <li>Sign P&S Agreement</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 5 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Approve all Contract Documents (P&S, Escrow,
                                    Mortgage)
                                  </li>
                                  <li>Add your Attorney</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Attorney" &&
                              element.id === 5 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload Amendments to Contract - P&S
                                    Agreement and Contract Amendment(s){" "}
                                  </li>
                                  <li>
                                    Approve all Contract Documents (P&S, Escrow,
                                    Mortgage)
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 6 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Sign P&S Agreement</li>
                                  <li>Sign addtl. documents as needed</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 7 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Fill out Offer Form and submit</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 9 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Add the Seller to the workflow (add partner)
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 9 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Approve 1 offer</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Attorney" &&
                              element.id === 9 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Approve all Contract Documents (P&S, Escrow,
                                    Mortgage)
                                  </li>
                                  <li>
                                    OR: Upload Amendments to Contract - P&S
                                    Agreement and Contract Amendment(s)
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 10 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Sign P&S Documents</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 10 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Sign P&S Documents</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 8 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Accept selected Offer (notifies the Buyer
                                    Side)
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 11 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Add your Attorney</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Attorney" &&
                              element.id === 11 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Approve all agreement documents</li>
                                  <li>
                                    OR: Upload Amendments to Contract - P&S
                                    Agreement and Contract Amendment(s){" "}
                                  </li>
                                  <li>Sign amendment documents</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 11 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Add your Attorney</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Attorney" &&
                              element.id === 11 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Approve all agreement documents</li>
                                  <li>
                                    OR: Upload Amendments to Contract - P&S
                                    Agreement and Contract Amendment(s){" "}
                                  </li>
                                  <li>Sign amendment documents</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 12 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Sign P&S Documents</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 12 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Sign P&S Documents</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 13 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Review property documents</li>
                                  <li>
                                    Upload reports to Dilligence - Report
                                    Documents
                                  </li>
                                  <li>OR: Add your Service Provider </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 13 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Review property documents</li>
                                  <li>
                                    Upload reports to Dilligence - Report
                                    Documents
                                  </li>
                                  <li>OR: Add your Service Provider </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 14 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Select Your Availability</li>
                                  <li>OR: Not Attending</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Inspector" &&
                              element.id === 14 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Select the best time from availabilities
                                  </li>
                                  <li>
                                    Upload invoice to Diligence -
                                    Invoices/Receipts
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 14 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Select Your Availability</li>
                                  <li>OR: Not Attending</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 14 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Select Your Availability</li>
                                  <li>OR: Not Attending</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Appraiser" &&
                              element.id === 14 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Select the best time from availabilities
                                  </li>
                                  <li>
                                    Upload invoice to Diligence -
                                    Invoices/Receipts
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 14 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Select Your Availability</li>
                                  <li>OR: Not Attending</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Inspector" &&
                              element.id === 15 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload Diligence Report to Diligence -
                                    Report Document(s)
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Appraiser" &&
                              element.id === 15 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload Diligence Report to Diligence -
                                    Report Document(s)
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 15 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Deposit service provider fee [External]
                                  </li>
                                  <li>
                                    Upload payment receipt to Diligence –
                                    Invoices/Receipts
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Attorney" &&
                              element.id === 16 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Approve all diligence documents</li>
                                  <li>
                                    OR: Upload to Diligence - Work Request
                                  </li>
                                  <li>Add your Service Provider</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 16 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Approve all diligence documents</li>
                                  <li>
                                    OR: Upload to Diligence - Work Request
                                  </li>
                                  <li>Add your Service Provider</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 16 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Approve all diligence documents</li>
                                  <li>
                                    OR: Upload to Diligence - Work Request
                                  </li>
                                  <li>Add your Attorney</li>
                                  <li>Add your Service Provider</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Attorney" &&
                              element.id === 17 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload/approve Diligence – Work Request and
                                    Agreement
                                  </li>
                                  <li>
                                    Upload/approve Contract - P&S Agreement and
                                    Contract Amendment(s){" "}
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 17 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload/approve Diligence - Work Request and
                                    Agreement
                                  </li>
                                  <li>
                                    Upload/approve Contract - P&S Agreement and
                                    Contract Amendment(s){" "}
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 17 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload/approve Diligence - Work Request and
                                    Agreement
                                  </li>
                                  <li>
                                    Upload/approve Contract - P&S Agreement and
                                    Contract Amendment(s){" "}
                                  </li>
                                  <li>Add your Attorney</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 17 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload/approve Diligence - Work Request and
                                    Agreement
                                  </li>
                                  <li>
                                    Upload/approve Contract - P&S Agreement and
                                    Contract Amendment(s){" "}
                                  </li>
                                  <li>
                                    Upload proof of completed work to Diligence
                                    – Exchange Document(s) btw Buyer Side &
                                    Seller Side{" "}
                                  </li>
                                  <li>Add your Attorney</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 17 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload/approve Diligence - Work Request and
                                    Agreement
                                  </li>
                                  <li>
                                    UUpload/approve Contract - P&S Agreement and
                                    Contract Amendment(s)
                                  </li>
                                  <li>
                                    Upload proof of completed work to Diligence
                                    – Exchange Document(s) btw Buyer Side &
                                    Seller Side
                                  </li>
                                  <li>Add your Attorney</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Attorney" &&
                              element.id === 17 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload/approve Diligence - Work Request and
                                    Agreement
                                  </li>
                                  <li>
                                    Upload/approve Contract - P&S Agreement and
                                    Contract Amendment(s){" "}
                                  </li>
                                  <li>
                                    Upload proof of completed work to Diligence
                                    – Exchange Document(s) btw Buyer Side &
                                    Seller Side
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 18 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Sign Diligence - Work Request Agreement
                                  </li>
                                  <li>
                                    Sign Contract - Amendment(s), if required
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 18 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Sign Diligence - Work Request Agreement
                                  </li>
                                  <li>
                                    Sign Contract - Amendment(s), if required
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 19 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload Approval to Mortgage - Final Closing
                                    Documents
                                  </li>
                                  <li>OR: Add your Lender</li>
                                  <li>
                                    Upload documents to Mortgage - Buyer
                                    Documents(s) as needed
                                  </li>
                                  <li>Deposit 2nd Escrow Payment [External]</li>
                                  <li>
                                    Upload Escrow receipt to Contract -
                                    Escrow/Princial Payments
                                  </li>
                                  <li>
                                    Upload Insurance Policies to Insurance –
                                    Insurance Policies{" "}
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Lender" &&
                              element.id === 19 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Upload Appraisal Report</li>
                                  <li>
                                    Upload documents to Lender Document(s) for
                                    Buyer Signature
                                  </li>
                                  <li>
                                    Upload Approval to Mortgage - Closing
                                    Documents
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 20 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Sign documents in Lender Document(s) for
                                    Buyer Signature
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 21 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Approve Mortgage - Closing Documents</li>
                                  <li>Add your Attorney</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Attorney" &&
                              element.id === 21 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Approve Mortgage - Closing Documents</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 22 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload documents to Ownership - Title
                                    Report(s) and Exchange Document(s)
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Attorney" &&
                              element.id === 22 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload documents to Ownership - Title
                                    Report(s) and Exchange Document(s)
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "TitleAndSettlement" &&
                              element.id === 22 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload reports to Ownership - Title
                                    Report(s) and Exchange Document(s)
                                  </li>
                                  <li>
                                    Upload documents to Title Document(s) for
                                    Seller Signature
                                  </li>
                                  <li>
                                    Upload FINAL documents to Ownership - Final
                                    Deed and Insurance Document(s)
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 22 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload Final Title Insurance Document(s) &
                                    Policy to Ownership – Final Deed and
                                    Insurance Document(s)
                                  </li>
                                  <li>OR: Add your Title Insurer</li>
                                  <li>Add your Attorney</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Attorney" &&
                              element.id === 22 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload Final Title Insurance Document(s) &
                                    Policy to Ownership – Final Deed and
                                    Insurance Document(s)
                                  </li>
                                  <li>OR: Add your Title Insurer</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Attorney" &&
                              element.id === 23 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Sign documents as required</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 23 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Sign documents as required</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Attorney" &&
                              element.id === 24 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Review Deed and Insurance Document(s)</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 24 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Review Deed and Insurance Document(s)</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Attorney" &&
                              element.id === 24 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Approve Deed and Insurance Document(s)
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "TitleAndSettlement" &&
                              element.id === 24 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Approve Deed and Insurance Document(s)
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 24 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Approve Deed and Insurance Document(s)
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 25 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Deposit Final Escrow Payment [External]{" "}
                                  </li>
                                  <li>
                                    Upload Escrow receipt to Closing - Receipts
                                    for Final (Escrow/Principal) Payments
                                  </li>
                                  <li>Prepare for Identity Verification</li>
                                  <li>
                                    Upload Final Closing Statement Document(s)
                                  </li>
                                  <li>OR: Add your Settlement Agent</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Attorney" &&
                              element.id === 25 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Deposit Final Escrow Payment [External]
                                  </li>
                                  <li>
                                    Upload Escrow receipt to Closing - Receipts
                                    for Final (Escrow/Principal) Payments
                                  </li>
                                  <li>Prepare for Identity Verification</li>
                                  <li>
                                    Upload Final Closing Statement Document(s)
                                  </li>
                                  <li>OR: Add your Settlement Agent</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Attorney" &&
                              element.id === 25 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload utility readings to "Closing - Seller
                                    Documents"
                                  </li>
                                  <li>Prepare for Identity Verification</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 25 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload utility readings to "Closing - Seller
                                    Documents"
                                  </li>
                                  <li>Prepare for Identity Verification</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "TitleAndSettlement" &&
                              element.id === 25 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Request Seller documents, as needed</li>
                                  <li>Prorate and adjust documents</li>
                                  <li>
                                    Upload documents Closing - Documents for
                                    Buyer/Seller Signature
                                  </li>
                                  <li>
                                    Upload Final Closing Statement Document(s)
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 26 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Sign documents as required</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 26 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Sign documents as required</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "TitleAndSettlement" &&
                              element.id === 27 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Approve payments in Closing - Receipts for
                                    Final (Escrow/Principal) Payments
                                  </li>
                                  <li>Approve Closing Statement Document(s)</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 27 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Approve payments in Closing - Receipts for
                                    Final (Escrow/Principal) Payments
                                  </li>
                                  <li>Approve Closing Statement Document(s)</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 27 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Approve payments in Closing - Receipts for
                                    Final (Escrow/Principal) Payments
                                  </li>
                                  <li>Approve Closing Statement Document(s)</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Attorney" &&
                              element.id === 27 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Approve payments in Closing - Receipts for
                                    Final (Escrow/Principal) Payments
                                  </li>
                                  <li>Approve Closing Statement Document(s)</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Attorney" &&
                              element.id === 27 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Approve payments in Closing - Receipts for
                                    Final (Escrow/Principal) Payments
                                  </li>
                                  <li>Approve Closing Statement Document(s)</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Lender" &&
                              element.id === 27 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Deposit Principal Amount</li>
                                  <li>Upload Payment Receipt</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "TitleAndSettlement" &&
                              element.id === 28 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Select 3 availability options</li>
                                  <li>OR: Not attending</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Attorney" &&
                              element.id === 28 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Select 3 availability options</li>
                                  <li>OR: Not attending</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Attorney" &&
                              element.id === 28 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Select 3 availability options</li>
                                  <li>OR: Not attending</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 28 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Review availabilities and select the best
                                    time slot
                                  </li>
                                  <li>
                                    Add Settlement Agent and Notary (if not
                                    previously added)
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 28 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Select 3 availability options</li>
                                  <li>OR: Not attending</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 28 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Select 3 availability options</li>
                                  <li>OR: Not attending</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 28 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Select 3 availability options</li>
                                  <li>OR: Not attending</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Attorney" &&
                              element.id === 29 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Follow instructions given by Closing Agents
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 29 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Follow instructions given by Closing Agents
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Attorney" &&
                              element.id === 29 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Follow instructions given by Closing Agents
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "TitleAndSettlement" &&
                              element.id === 29 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Conduct Closing Procedures</li>
                                  <li>Release Fee(s)/Escrow Payments</li>
                                  <li>Upload Payment Receipts</li>
                                  <li>
                                    Upload final documents to Closing - Executed
                                    Transaction Documents
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 29 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Follow instructions given by Closing Agents
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession ===
                                "TitleAndSettlementementAgent" &&
                              element.id === 30 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Release Fee(s)/Escrow Payments</li>
                                  <li>
                                    Upload receipts to Contract - Receipts for
                                    Escrow/Princial Payments
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 31 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Confirm receipt of Property Access</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 31 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Confirm receipt of principal payment</li>
                                </ul>
                              </MenuItem>
                            ) : null}
                          </MenuList>
                        )}
                      </>
                    )
                  )}
                </div>
              )
            )}
        </MenuList>
      </Card>
    </>
  );
}

export default DealProgressDrawer;
