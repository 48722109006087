import { Box } from "@mui/material";

export default function VerticalDividerCard() {
  return (
    <Box
      sx={{
        width: "1px",
        height: "80%",
        backgroundColor: "#EEEEEE",
      }}
    />
  );
}
