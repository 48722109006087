import { Button } from "@mui/material";

export default function PrimaryButton({
  children,
  onClick,
  disabled,
  type,
}: any) {
  return (
    <Button
      sx={{
        background: "rgba(4, 119, 130, 1)",
        "&.MuiButtonBase-root:hover": {
          bgcolor: "rgba(1, 150, 164, 1)",
        },
        "&:disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.12)",
        },
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "500",
        color: "rgba(255, 255, 255, 1)",
        borderRadius: "4px",
        letterSpacing: "1.25px",
        textAlign: "center",
        lineHeight: "36px",
        minWidth: "100px",
        height: "40px",
        margin: "15px",
      }}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {children}
    </Button>
  );
}
