import {Box, Button} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./documentViewer.css";
import {HtmlDocument} from "../../../app/models/htmlDocument";
import {ArrowBackIosNew} from "@mui/icons-material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {toast} from "react-toastify";

interface Props {
  document: HtmlDocument;

  onClickToGoBack: () => void;
}

export default function RFPDocumentViewer({
  document,

  onClickToGoBack,
}: Props) {
  const [value, setValue] = useState<string>("");

  const contentRef = useRef<HTMLDivElement>(null);

  const handleDownloadPdf = async () => {
    if (contentRef.current) {
      const content = contentRef.current;

      console.log(content);
      if (content) {
        const canvas = await html2canvas(content);

        const imgData = canvas.toDataURL("image/png");

        const pdf = new jsPDF("p", "mm", "a4");

        const imgWidth = 210; // A4 width in mm
        const pageHeight = 306; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        let position = 0;

        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save("document.pdf");

        toast.success("The document was downloaded successfully!");
      }
    }
  };

  useEffect(() => setValue(document.text), [document.text]);

  const TOOLBAR_OPTIONS = [
    [{header: [1, 2, 3, 4, 5, 6, false]}],
    [{font: []}],
    [{list: "ordered"}, {list: "bullet"}],
    ["bold", "italic", "underline"],
    [{color: []}, {background: []}],
    [{align: []}],
  ];

  const quillRef = useRef(null);

  useEffect(() => {
    if (quillRef.current) {
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }, []);

  return (
    <Box flex={1} position="relative" height="100%" id="print-area">
      {" "}
      <Box sx={{overflowY: "scroll", height: "100%"}}>
        <Button
          variant="contained"
          sx={{
            width: "110px",
            position: "absolute",
            top: "0.5rem",
            right: "0.5rem",
            paddingLeft: 3,
            paddingRight: 3,
            backgroundColor: "rgba(4, 119, 130, 1)",
            zIndex: 10,
            "&.MuiButtonBase-root:hover": {
              backgroundColor: "rgba(4, 119, 130, 1)",
            },
          }}
          onClick={handleDownloadPdf}
        >
          DOWNLOAD
        </Button>

        <Button
          variant="outlined"
          sx={{
            height: "56px",
            width: "56px",
            minWidth: "56px",
            borderRadius: "50%",
            backgroundColor: "#FFF",
            color: "rgba(4, 119, 130, 1)",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            position: "absolute",
            top: "1rem",
            left: "0.5rem",
            border: "none",
            "&.MuiButtonBase-root:hover": {
              color: "rgba(4, 119, 130, 1)",
              border: "none",
              backgroundColor: "#F2F6FA",
            },
          }}
          onClick={() => onClickToGoBack()}
        >
          <ArrowBackIosNew sx={{width: "24px", height: "24px"}} />
        </Button>

        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            width: "fit-content",
          }}
        >
          <div ref={contentRef}>
            <ReactQuill
              theme="snow"
              value={value}
              onChange={(value) => setValue(value)}
              modules={{toolbar: TOOLBAR_OPTIONS}}
              className={"dc-none"}
            />
          </div>
        </div>
      </Box>
    </Box>
  );
}
