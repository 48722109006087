import {
  Box,
  Card,
  CardActions,
  CardContent,
  Fade,
  Typography,
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {RFP} from "../../../app/models/rfp";
import {useRef, useState} from "react";
import VerticalDivider from "../../../app/components/VerticalDividerCard";
import DealActionDrawerRFP from "../dealAction/DealActionDrawerRFP";

interface Props {
  rfp: RFP;
}

const ServiceCard = ({rfp}: Props) => {
  const boxRef = useRef<Element>();
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);

  console.log(rfp);

  const handleOpenDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  async function handleClick(id: number) {
    navigate(`/deals/service/det/${id}`);
  }

  return (
    <Box ref={boxRef}>
      <Card
        key={rfp.id}
        sx={{
          border: "1px solid #AACCDD",
          boxShadow: "none",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "0px",
          width: "100%",
          height: "154px",
          marginBottom: "15px",
          color: "#424242",
        }}
      >
        <CardContent
          onClick={() => handleClick(rfp.id)}
          sx={{
            minWidth: "245px",
            maxWidth: "245px",
            padding: "0px",
            marginRight: "10px",
            height: "100%",
          }}
        >
          {rfp.mainRFPImage != null ? (
            <img
              src={rfp.mainRFPImage}
              alt=""
              style={{
                height: "154px",
                width: "100%",
                padding: "0px",
                margin: "0px",
              }}
            />
          ) : (
            <img
              src={"/images/RfpDefault.png"}
              alt="RFP"
              style={{
                height: "154px",
                width: "100%",
                padding: "0px",
                margin: "0px",
              }}
            />
          )}
        </CardContent>
        <CardActions
          onClick={() => handleClick(rfp.id)}
          sx={{width: "15%", height: "100%"}}
        >
          <Box
            style={{textDecoration: "none", color: "#757575", height: "100%"}}
          >
            <Typography
              sx={{color: "#424242", paddingBottom: "15px"}}
              variant="h5"
            >
              RFP
            </Typography>
            <Typography
              sx={{
                lineHeight: "1.2",
                fontSize: "0.75rem",
                paddingBottom: "10px",
                color: "#757575",
              }}
            >
              {rfp.addressOfSeeker}
            </Typography>
            <Typography
              sx={{paddingTop: "5px", color: "#757575"}}
              variant="subtitle1"
            >
              #P{rfp.id}
            </Typography>
          </Box>
        </CardActions>
        <VerticalDivider />

        <Box
          onClick={() => handleOpenDrawer()}
          sx={{
            width: "55%",
            height: "100%",
            display: "flex",
            padding: "15px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: "#424242",
              font: "Roboto",
              fontWeight: "400",
              lineHeight: "32px",
            }}
          >
            Click to expand Drawer
          </Typography>
        </Box>
      </Card>

      <Fade mountOnEnter unmountOnExit in={openDrawer}>
        {/* The component is wrapped in div to fix a MUI bug #27154 */}
        <div>
          <DealActionDrawerRFP serviceId={rfp.id} />
        </div>
      </Fade>
    </Box>
  );
};

export default ServiceCard;
