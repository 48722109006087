export namespace MessageGroupTypes {
  export const global = "Global Messages";
  export const deal = "Deal Messages";
  export const action = "Action Comments";
}

export type MessageGroupType =
  | typeof MessageGroupTypes.global
  | typeof MessageGroupTypes.deal
  | typeof MessageGroupTypes.action;

export interface MessageModel {
  id: number;
  userId: string;
  text: string;
  chatId: string;
  timestamp: string;
}

export interface MessagesForGroup {
  [key: string]: MessageModel[] | undefined;
}

export interface UserForChat {
  id: string;
  firstName: string;
  profilePictureUrl: string;
}

export interface UserForCreateChat {
  $id: string;
  $values: { $id: string; id: string; email: string; userName: string }[];
}

export interface UserDictionary {
  [key: string]: UserForChat;
}

export interface PropertyRfpForChat {
  id: number;
  mainImageUrl: string;
}

export interface PropertyRfpDictionary {
  [key: string]: PropertyRfpForChat;
}

export interface SubjectDictionary {
  [key: string]: string;
}

export interface UnreadMessages {
  actionComments: MessagesForGroup;
  globalMessages: MessagesForGroup;
  users: UserDictionary;
  propertiesRfps: PropertyRfpDictionary;
  subjects: SubjectDictionary;
}

export interface UnreadMessage {
  chatId: string;
  message: MessageModel;
  user: UserForChat;
  propertyRfp: PropertyRfpForChat;
}

export interface GlobalMessage {
  chatId: string;
  subject: string;
  messages: MessageModel[];
  users: UserForChat[];
}

export interface ChatModel {
  id: string;
  title: string;
  messages: MessageModel[];
  users: UserDictionary;
}
