import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {getAccessToken} from "../util/util";
import {
  setUnreadMessages,
  addUnreadMessage,
  deleteUnreadMessages,
  setInitialGlobalMessage,
} from "../../features/messageCenter/messageCenterSlice";
import {
  UnreadMessage,
  GlobalMessage,
  UnreadMessages,
} from "../models/messageCenter";
import {useAppDispatch, useAppSelector} from "./configureStore";
import {toast} from "react-toastify";
import {FieldValues} from "react-hook-form";
import PropertyInfo from "../models/propertyInfo";
import {getNotifications, setNotification} from "./notificationSlice";

// Message center connection is stored in useState and passed through Context
// because the connection cannot be stored inside a Redux Slice
// (Error: It is not serializable)

interface MessageCenterConnectionContextValue {
  connection?: HubConnection;
  onCreateChat: (data: FieldValues) => Promise<void>;
  onDeleteUnreadMessages: (chatId: string) => Promise<void>;
}

export const MessageCenterConnectionContext =
  createContext<MessageCenterConnectionContextValue>({
    connection: undefined,
    onCreateChat: async (data) => {},
    onDeleteUnreadMessages: async (chatId) => {},
  });

export function useMessageCenterConnectionContext() {
  return useContext(MessageCenterConnectionContext);
}

export function MessageCenterConnectionContextProvider({
  children,
}: PropsWithChildren) {
  const [connection, setConnection] = useState<HubConnection>();
  const token = useAppSelector((state) => state.account.user?.token);
  const dispatch = useAppDispatch();

  const createChatHandler = async (data: FieldValues) => {
    try {
      await connection?.invoke("CreateChat", data);
    } catch (e) {
      toast.error(
        <>
          <div>Error creating chat.</div>
          <div>Please restart the application and try again.</div>
        </>
      );
    }
  };

  const deleteUnreadMessagesHandler = async (chatId: string) => {
    try {
      await connection?.invoke("DeleteUnreadMessages", chatId);
      dispatch(deleteUnreadMessages(chatId));
    } catch (e) {
      // !: Error persists if there are no messages to delete
      // toast.error(
      //   <>
      //     <div>Error deleting messages.</div>
      //     <div>Please restart the application and try again.</div>
      //   </>
      // );
    }
  };

  useEffect(() => {
    if (!token) return;
    const connection = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_MESSAGE_CENTER_HUB_URL!, {
        accessTokenFactory: getAccessToken,
      })
      .configureLogging(LogLevel.Information)
      .build();

    connection.on("ReceiveUnreadMessages", (unreadMessages: UnreadMessages) => {
      dispatch(setUnreadMessages(unreadMessages));
    });

    connection.on("ReceiveUnreadMessage", (unreadMessage: UnreadMessage) => {
      dispatch(addUnreadMessage(unreadMessage));
    });

    connection.on("ChangeOfTaskMessageNotification", (info: PropertyInfo) => {
      dispatch(setNotification(info));
      dispatch(getNotifications());
    });

    connection.on(
      "ReceiveInitialGlobalMessage",
      (globalMessages: GlobalMessage) => {
        console.log(globalMessages);
        dispatch(setInitialGlobalMessage(globalMessages));
      }
    );

    connection.start().then(() => {
      connection.invoke("JoinMessageCenter");
    });

    setConnection(connection);

    return () => {
      connection.stop();
    };
  }, [setConnection, token, dispatch]);

  return (
    <MessageCenterConnectionContext.Provider
      value={{
        connection,
        onCreateChat: createChatHandler,
        onDeleteUnreadMessages: deleteUnreadMessagesHandler,
      }}
    >
      {children}
    </MessageCenterConnectionContext.Provider>
  );
}
