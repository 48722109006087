import {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Property} from "../../app/models/property";
import agent from "../../app/api/agent";
import {
  Box,
  Grid,
  ImageList,
  ImageListItem,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ApartmentIcon from "@mui/icons-material/Apartment";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import HorizontalSplitOutlinedIcon from "@mui/icons-material/HorizontalSplitOutlined";
import SettingsOverscanOutlinedIcon from "@mui/icons-material/SettingsOverscanOutlined";
import StraightenOutlinedIcon from "@mui/icons-material/StraightenOutlined";
import TrackChangesOutlinedIcon from "@mui/icons-material/TrackChangesOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import NotFound from "../../app/errors/NotFound";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {useAppSelector} from "../../app/store/configureStore";

const PropertyOverviewNotLoggedUser = () => {
  const {user} = useAppSelector((state) => state.account);
  const navigate = useNavigate();
  const {id} = useParams<{id: string}>();
  const [property, setProperty] = useState<Property | null>(null);
  const [galleryImages, setGalleryImages] = useState([]);

  const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  useEffect(() => {
    id &&
      agent.Marketplace.noUserDetails(parseInt(id))
        .then((response) => {
          setProperty(response);
          const galleryValues = response.gallery.$values;
          const galleryUrls = galleryValues.map((img: any) => img.imageUrl);
          setGalleryImages(galleryUrls);
        })
        .catch((error) => console.log(error.response));
  }, [id]);

  if (!property) return <NotFound />;

  if (user) {
    navigate(`/property/det/${id}`);
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F5F5F5",
          height: "100%",
          padding: "20px",
          paddingTop: 0,
        }}
      >
        <Box
          sx={{
            height: "60px",
            marginTop: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ErrorOutlineIcon sx={{color: "rgba(255, 82, 82, 1)"}} />
          <Typography
            sx={{
              color: "rgba(95, 33, 32, 0.87)",
              fontFamily: "Roboto",
              fontWeight: "700",
            }}
          >
            Information: This page is only for non-registered user property
            overview. If you're already a user and you are logged in remove the
            <strong>"/nouser"</strong> from the Url to view the logged in User
            property overview or <Link to={"/login"}>Log In</Link>
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Item sx={{height: "540px"}}>
              <Box padding="10px">
                <img
                  style={{width: "100%", height: "200px"}}
                  src={property.mainImageUrl}
                  alt={property.city + " " + property.type}
                />
              </Box>
              <Grid container>
                <Grid item xs={6} display="inline-flex" padding="10px">
                  <Typography
                    variant="h6"
                    textAlign="left"
                    fontWeight="400"
                    color="#424242"
                  >
                    {property.addressLine} {property.city}, {property.state}{" "}
                    {property.postalCode}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="inline-flex"
                  padding="10px"
                  fontWeight="400"
                  color="#424242"
                >
                  <Typography variant="h6">
                    £{property.listPrice?.toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid
                  item
                  xs={6}
                  display="inline-flex"
                  flexDirection="column"
                  textAlign="left"
                  paddingLeft="10px"
                >
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <ScheduleIcon sx={{height: "18px", width: "18px"}} />{" "}
                    Duration of Listing: {property.durationOfListing}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <ApartmentIcon sx={{height: "18px", width: "18px"}} />
                    Type: {property.type}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <BookmarkBorderIcon sx={{height: "18px", width: "18px"}} />
                    Lease: {property.lease}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <HorizontalSplitOutlinedIcon
                      sx={{height: "18px", width: "18px"}}
                    />
                    Lease rate type: {property.leaseRateType}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <SettingsOverscanOutlinedIcon
                      sx={{height: "18px", width: "18px"}}
                    />
                    Building Size: {property.buildingSize}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <StraightenOutlinedIcon
                      sx={{height: "18px", width: "18px"}}
                    />
                    Site Size: {property.siteSize}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="inline-flex"
                  flexDirection="column"
                  textAlign="left"
                  paddingLeft="10px"
                >
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <TrackChangesOutlinedIcon
                      sx={{height: "18px", width: "18px"}}
                    />
                    Zoning: {property.zoning}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <DateRangeOutlinedIcon
                      sx={{height: "18px", width: "18px"}}
                    />
                    Year Built: {property.built}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <TodayOutlinedIcon sx={{height: "18px", width: "18px"}} />
                    Last Renovation: {property.lastRenovation}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <CategoryOutlinedIcon
                      sx={{height: "18px", width: "18px"}}
                    />
                    Parking: {property.parking}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <ListAltOutlinedIcon sx={{height: "18px", width: "18px"}} />
                    Property: #{property.id}
                  </Typography>
                </Grid>
              </Grid>
            </Item>
          </Grid>

          <Grid item xs={8}>
            <Item sx={{height: "540px"}}>
              <ImageList
                sx={{width: "100%", height: "500px"}}
                cols={3}
                rowHeight={164}
              >
                {galleryImages &&
                  galleryImages.map((url, index) => (
                    <ImageListItem key={index}>
                      <img
                        style={{height: "200px"}}
                        src={url}
                        alt={` ${index}`}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
              </ImageList>
            </Item>
          </Grid>
        </Grid>

        <Grid container spacing={2} marginTop="5px">
          <Grid item xs={4}>
            <Item sx={{height: "230px"}}>
              <Box display="inline-flex" width="100%" padding="10px">
                <Typography
                  variant="h5"
                  sx={{
                    paddingBottom: "15px",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "22px",
                    color: "#424242",
                  }}
                >
                  Summary
                </Typography>
              </Box>
              <Grid
                item
                xs={12}
                flexDirection="column"
                justifyContent="flex-start"
                justifyItems="start"
                paddingLeft="10px"
              >
                <Typography variant="subtitle2" textAlign="left">
                  {property.summary}
                </Typography>
              </Grid>
            </Item>
          </Grid>

          <Grid item xs={8}>
            <Item sx={{height: "230px"}}>
              <Grid container sx={{textAlign: "start"}}>
                <Grid item xs={8} padding="10px">
                  <Typography
                    variant="h5"
                    sx={{
                      paddingBottom: "15px",
                      fontWeight: 700,
                      fontSize: "18px",
                      lineHeight: "22px",
                      color: "#424242",
                    }}
                  >
                    Property Details
                  </Typography>
                </Grid>
                <Grid item xs={4} padding="10px">
                  <Typography
                    variant="h5"
                    sx={{
                      paddingBottom: "15px",
                      fontWeight: 700,
                      fontSize: "18px",
                      lineHeight: "22px",
                      color: "#424242",
                    }}
                  >
                    Utilities
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid
                  item
                  xs={4}
                  display="inline-grid"
                  flexDirection="column"
                  justifyContent="flex-start"
                  justifyItems="start"
                  paddingLeft="10px"
                >
                  <Typography variant="subtitle2">
                    Exterior: {property.exterior}
                  </Typography>
                  <Typography variant="subtitle2">
                    Slab: {property.slab}
                  </Typography>
                  <Typography variant="subtitle2">
                    Building Dimensions: {property.buildingDimensions}
                  </Typography>
                  <Typography variant="subtitle2">
                    Column Spacing: {property.columnSpacing}
                  </Typography>
                  <Typography variant="subtitle2">
                    Ceiling Height: {property.ceilingHeight}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  display="inline-grid"
                  flexDirection="column"
                  justifyContent="flex-start"
                  justifyItems="start"
                  padding="5px"
                  paddingLeft="10px"
                >
                  <Typography variant="subtitle2">
                    Dock-High Doors: {property.dockHighDoors}
                  </Typography>
                  <Typography variant="subtitle2">
                    Drive In Doors: {property.driveInDoors}
                  </Typography>
                  <Typography variant="subtitle2">
                    Elevator: {property.elevator}
                  </Typography>
                  <Typography variant="subtitle2">
                    Sprinkler: {property.sprinkler}
                  </Typography>
                  <Typography variant="subtitle2">
                    Parking: {property.parking}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  display="inline-grid"
                  flexDirection="column"
                  justifyContent="flex-start"
                  justifyItems="start"
                >
                  <Typography variant="subtitle2">
                    Water & Sewer: {property.waterAndSewer}
                  </Typography>
                  <Typography variant="subtitle2">
                    Power: {property.power}
                  </Typography>
                  <Typography variant="subtitle2">
                    Power Available: {property.powerAvailable}
                  </Typography>
                  <Typography variant="subtitle2">
                    Gas: {property.gas}
                  </Typography>
                  <Typography></Typography>
                </Grid>
              </Grid>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PropertyOverviewNotLoggedUser;
