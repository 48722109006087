import {Box, OutlinedInputProps, TextField} from "@mui/material";
import {useController, UseControllerProps} from "react-hook-form";

interface Props extends UseControllerProps {
  label?: string;
  multiline?: boolean;
  rows?: number;
  type?: string;
  disabled?: boolean;
  value?: unknown;
  className?: string;
  required?: boolean;
  helperText?: string;
  showCharacterCounter?: boolean;
  characterCounterLimit?: number;
  onChange?: OutlinedInputProps["onChange"];
}

export default function AppTextInput(props: Props) {
  const {fieldState, field} = useController({
    ...props,
    defaultValue: props.defaultValue ?? "",
  });
  const {showCharacterCounter, characterCounterLimit, ...textFieldProps} =
    props;

  const onChange = (e: any) => {
    // Only when we have showCharacterCounter prop check wether the number of characters exceeds the characterCounterLimit
    if (
      showCharacterCounter &&
      (field.value as string).length >= characterCounterLimit!
    ) {
      // Allow deleting if characterCounterLimit is reached
      if (e.nativeEvent.inputType === "deleteContentBackward") {
        field.onChange(e);
      }

      return;
    }

    field.onChange(e);
  };

  return (
    <>
      <TextField
        {...textFieldProps}
        {...field}
        onChange={onChange}
        multiline={props.multiline}
        rows={props.rows}
        type={props.type}
        fullWidth
        variant="standard"
        error={!!fieldState.error}
        helperText={fieldState.error?.message}
        className="no-spinners"
        inputProps={{
          step: "0.01",
        }}
      />
      {showCharacterCounter && (
        <Box
          sx={{
            fontSize: "12px",
            color: "rgb(117, 117, 117)",
            alignSelf: "end",
            marginTop: "-10px",
          }}
        >
          {(field.value as string).length}/{characterCounterLimit}
        </Box>
      )}
    </>
  );
}
