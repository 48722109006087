import { FormHelperText } from "@mui/material";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { UseControllerProps, useController } from "react-hook-form";

interface Props extends UseControllerProps {
  disabled?: boolean;
  children: any;
}

export default function ReusableDropzoneMultipleImages(props: Props) {
  const { fieldState, field } = useController({ ...props, defaultValue: null });

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      acceptedFiles = Object.assign(acceptedFiles);
      field.onChange(acceptedFiles);
      console.log(acceptedFiles);
    },
    [field]
  );

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    disabled: props.disabled,
  });

  let files = acceptedFiles.map((file: any) => (
    <li key={file.path}>{file.path}</li>
  ));

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {props.children}
      <aside>
        <h4>Files</h4>
        <ul>{files}</ul>
      </aside>
      <FormHelperText>{fieldState.error?.message}</FormHelperText>
    </div>
  );
}
