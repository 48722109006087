import React, { useEffect, useState } from "react";
import agent from "../../app/api/agent";

interface PhaseOption {
  id: number;
  name: string;
  value: boolean;
  options?: {
    $values: any[];
  };
}

interface Phase {
  id: number;
  name: string;
  options: PhaseOption[];
}

function PhaseEditor() {
  const [phases, setPhases] = useState<Array<Phase>>([]);
  const [name, setName] = useState<string>("");
  //const [editingId, setEditingId] = useState<number | null>(null);
  const [options, setOptions] = useState<PhaseOption[]>([]);

  //const [pdfFileChecked, setPdfFileChecked] = useState(false);
  //const [somethingElseChecked, setSomethingElseChecked] = useState(false);

  type CheckboxState = Record<string, boolean>;
  const [checkboxState, setCheckboxState] = useState<CheckboxState>({
    pdfFile: false,
    offer: false,
    inquiry: false,
    bidding: false,
    somethingElse: false,
    // Add more checkboxes here
  });

  useEffect(() => {
    agent.Phases.getPhases()
      .then((response) => {
        console.log("response1", response.$values);
        const responseValue = response.$values;
        setPhases(responseValue);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const resetCheckboxes = () => {
    const resetState = Object.keys(checkboxState).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {} as CheckboxState);
    setCheckboxState(resetState);
  };

  const handleCheckboxChange = (event: any) => {
    const target = event.target;
    //const name = target.name;
    //const checked = target.checked;

    //console.log(target, name, checked);
    // if (name === "pdfFile") {
    //   console.log();
    //   setPdfFileChecked(checked);
    //   setOptions([...options, name]);
    // } else if (name === "somethingElse") {
    //   setSomethingElseChecked(checked);
    //   setOptions([...options, name]);
    // }

    const { name, checked } = event.target;
    console.log(target, name, checked);
    setCheckboxState({ ...checkboxState, [name]: checked });
    setOptions([...options, name]);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = {
      name: name,
      options: options.map((option) => {
        return { name: option };
      }),
    };

    agent.Phases.create(data)
      .then((response) => {
        setPhases([...phases, response]);
        resetCheckboxes();
      })
      .catch((error) => {
        console.log(error);
      });

    setName("");
    setOptions([]);
  };

  const handleDelete = (id: number) => {
    console.log(id);
    agent.Phases.delete(id)
      .then((response) => {
        console.log(response);
        setPhases(phases.filter((phase) => phase.id !== id));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const handleEdit = (phase: Phase) => {
  //   setName(phase.name);
  //   setEditingId(phase.id);
  //   setOptions(phase.options || []);
  // };

  // const handleCancelEdit = () => {
  //   setName("");
  //   setEditingId(null);
  //   setOptions([]);
  // };

  // const handleOptionChange = (index: number, value: boolean) => {
  //   setEditingOptions(
  //     editingOptions.map((option, i) =>
  //       i === index ? { ...option, value } : option
  //     )
  //   );
  // };

  return (
    <div className="row">
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div>
          <h4 className="mb-3 mb-md-2">Deal Phases</h4>
        </div>
      </div>
      <div className="col-md-4 grid-margin">
        <div className="card">
          <div className="card-body">
            <h6 className="card-title">ALL Deal Phases</h6>

            <div className="table-responsive">
              <table className="table">
                <tbody>
                  {phases.map((phase) => (
                    <tr key={phase.id}>
                      <td className="w-100">
                        <span
                          className="d-flex align-items-center"
                          style={{ height: "31px" }}
                        >
                          {phase.name}
                        </span>
                        {/* <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`phase${phase.id}`}
                            checked={true}
                            disabled={true}
                          />

                          <label
                            className="form-check-label"
                            htmlFor={`phase${phase.id}`}
                          >
                            Active
                          </label>
                        </div> */}

                        {/* {phase.options &&
                          phase.options.map((option: any) => (
                            <div key={option.id}>{option.name}</div>
                          ))} */}

                        <span>Options:</span>
                        <b>
                          /
                          {phase.options &&
                            (
                              phase.options as any as { $values: any[] }
                            ).$values.map((option: any) => (
                              <span key={option.id}>{option.name}/</span>
                            ))}
                        </b>

                        {/* {phase.options &&
                          Array.isArray(phase.options) &&
                          phase.options.map((option: PhaseOption) => (
                            <div key={option.id}>{option.name}</div>
                          ))} */}
                      </td>
                      <td>
                        {/* <button
                          onClick={() => handleEdit(phase)}
                          className="btn btn-warning py-1 me-2"
                        >
                          Edit
                        </button> */}
                        <button
                          onClick={() => handleDelete(phase.id)}
                          className="btn btn-danger py-1"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 grid-margin">
        <div className="card">
          <div className="card-body">
            <>
              <h6 className="card-title">Create New Phase</h6>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter phase name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </div>

                <div className="form-group mt-3">
                  <h6 className="card-title mb-1">Phase Requirements:</h6>
                  <div className="d-flex justify-content-start">
                    <input
                      type="checkbox"
                      name="pdfFile"
                      id="pdfFile"
                      checked={checkboxState.pdfFile}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="pdfFile">Adding PDF File</label>
                  </div>

                  <div className="d-flex justify-content-start">
                    <input
                      type="checkbox"
                      name="offer"
                      id="offer"
                      checked={checkboxState.offer}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="pdfFile">Offer</label>
                  </div>

                  <div className="d-flex justify-content-start">
                    <input
                      type="checkbox"
                      name="inquiry"
                      id="inquiry"
                      checked={checkboxState.inquiry}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="pdfFile">Inquiry</label>
                  </div>

                  <div className="d-flex justify-content-start">
                    <input
                      type="checkbox"
                      name="bidding"
                      id="bidding"
                      checked={checkboxState.bidding}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="pdfFile">Bidding</label>
                  </div>

                  <div className="d-flex justify-content-start">
                    <input
                      type="checkbox"
                      name="somethingElse"
                      id="somethingElse"
                      checked={checkboxState.somethingElse}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="somethingElse">something else</label>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button type="submit" className="btn btn-primary">
                    Create
                  </button>
                </div>
              </form>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhaseEditor;
