import {Box, Typography, Paper, Button} from "@mui/material";
import {UserForCreateChat} from "../../app/models/messageCenter";
import {useEffect, useState} from "react";
import agent from "../../app/api/agent";
import {FieldValues, useForm} from "react-hook-form";
import AppTextInput from "../../app/components/AppTextInputCustom";
import {CREATE_CHAT_CHARACTER_COUNTER_LIMIT} from "../../app/util/constants";
import AppAutocompleteInput from "../../app/components/AppAutocompleteInput";

interface Props {
  onCreateChat: (data: FieldValues) => Promise<void>;
  onCloseCreateChatHandler: () => void;
}

export default function CreateChat({
  onCreateChat,
  onCloseCreateChatHandler,
}: Props) {
  const {control, handleSubmit} = useForm();
  const [users, setUsers] = useState<{
    userEmails: string[];
    userIds: string[];
  }>({userEmails: [], userIds: []});

  const submitHandler = async (data: FieldValues) => {
    await onCreateChat(data);
  };

  useEffect(() => {
    agent.Account.getAllUsersForChat().then((users: UserForCreateChat) => {
      const userEmails = users.$values.map((u) => u.email);
      const userIds = users.$values.map((u) => u.id);

      setUsers({userEmails, userIds});
      console.log(userEmails, userIds);
    });
  }, []);

  return (
    <Box className="message-group">
      <Box
        sx={{
          color: "#FFFFFF",
          backgroundColor: "rgb(17, 48, 127)",
          paddingX: "13px",
          paddingTop: "8px",
          paddingBottom: "5px",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <Typography variant="subtitle1" fontSize="15px">
          New Message
        </Typography>
      </Box>
      <Paper
        elevation={4}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        sx={{
          minHeight: "50vh",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
          paddingTop: "15px",
          paddingX: "23px",
        }}
      >
        <AppAutocompleteInput
          control={control}
          name="userEmails"
          rules={{required: "A recipient is required"}}
          label="Email Address"
          optionLabels={users.userEmails}
          optionValues={users.userIds}
          multiple
          required
        />
        <AppTextInput
          control={control}
          name="subject"
          rules={{required: "A subject is required"}}
          label="Subject"
          type="text"
          required
        />
        <AppTextInput
          control={control}
          name="dealId"
          // rules={{ required: "A deal # is required" }}
          label="Deal # (Optional) - To be implemented"
          // optionLabels={[]}
          // optionValues={[]}
          disabled
          required
        />
        <AppTextInput
          control={control}
          name="message"
          rules={{required: "A message is required"}}
          label="Message"
          type="text"
          multiline
          showCharacterCounter
          characterCounterLimit={CREATE_CHAT_CHARACTER_COUNTER_LIMIT}
          required
        />
        <Box
          sx={{
            alignSelf: "end",
            marginTop: "auto",
            marginBottom: "4px",
            marginRight: "-15px",
          }}
        >
          <Button
            type="submit"
            sx={{
              color: "rgb(4, 119, 130)",
              letterSpacing: "1px",
            }}
          >
            Submit
          </Button>
          <Button
            sx={{
              color: "rgb(117, 117, 117)",
              letterSpacing: "1px",
            }}
            onClick={onCloseCreateChatHandler}
          >
            Cancel
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
