import axios, {AxiosError, AxiosResponse} from "axios";
import {toast} from "react-toastify";
import {router} from "../router/Routes";
import {store} from "../store/configureStore";
import {HtmlDocument} from "../models/htmlDocument";

const sleep = () => new Promise((resolve) => setTimeout(resolve, 300));

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

const responseBody = (response: AxiosResponse) => response.data;

axios.interceptors.request.use((config) => {
  const token = store.getState().account.user?.token;
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    if (process.env.NODE_ENV === "development") await sleep();
    return response;
  },
  (error: AxiosError) => {
    console.log("AXIOS ERROR", error);
    const {data, status} = error.response as AxiosResponse;
    switch (status) {
      case 400:
        if (data.errors) {
          const modelStateErrors: string[] = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modelStateErrors.push(data.errors[key]);
            }
          }
          throw modelStateErrors.flat();
        }
        toast.error(data.title);
        break;
      case 401:
        toast.error(data.title);
        break;
      case 500:
        //toast.error(data.title);
        router.navigate("/server-error", {state: {error: data}});
        break;
      default:
        break;
    }
    return Promise.reject(error.response);
  }
);

const requests = {
  get: (url: string) => axios.get(url).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  del: (url: string) => axios.delete(url).then(responseBody),
  postForm: (url: string, data: FormData) =>
    axios
      .post(url, data, {
        headers: {"Content-type": "multipart/form-data"},
      })
      .then(responseBody),
};

const Marketplace = {
  allMarketplaceByUser: () => requests.get(`marketplace`),
  details: (id: number) => requests.get(`marketplace/det/${id}`),
  noUserDetails: (id: number) => requests.get(`marketplace/det/nouser/${id}`),
  create: (data: any) => requests.postForm("marketplace", createFormData(data)),
  update: (id: number, data: any) => requests.post(`marketplace/${id}`, data),
  delete: (id: number) => requests.get(`marketplace/delete/${id}`),
  createPropertyResponse: (data: any) =>
    requests.postForm(`marketplace/propertyResponse`, createFormData(data)),
};

const Deals = {
  allDeals: () => requests.get(`deals/alldeals`),
  oneDealProperty: (id: number) => requests.get(`deals/oneDeal/${id}`),
  postDealCardForBuyer: (id: number) =>
    requests.post(`deals/postprepareoffer/${id}`, {}),
};

const RFPs = {
  allRFPs: () => requests.get("requestforproposal"),
  RFPDetails: (id: number) => requests.get(`requestforproposal/det/${id}`),
  create: (data: any) =>
    requests.postForm("requestforproposal", createFormData(data)),
  delete: (id: number) => requests.del(`requestforproposal/det/${id}`),
  allFavoriteRFPs: () => requests.get("property/favoriterfps"),
};

const RFPResponses = {
  allResponses: (id: number) => requests.get(`responsetorfp/rfp/${id}`),
  oneResponse: (id: number) => requests.get(`responsetorfp/det/${id}`),
  create: (data: any) =>
    requests.postForm("responsetorfp", createFormData(data)),
  delete: (id: number) => requests.del(`responsetorfp/det/${id}`),
  update: (id: number) =>
    requests.put(`responsetorfp/updateresponse/${id}`, id),
  addPartnerToDealAcceptOffer: (id: number, contractId: number) =>
    requests.put(
      `responsetorfp/addPartnerThroughServiceOffer/${id}/${contractId}`,
      {}
    ),
};

const InquriesRFP = {
  allRFPInquiries: (id: number) => requests.get(`inquiry/rfps/${id}`),
  oneRFPInquiry: (id: number) => requests.get(`inquiry/rfp/${id}`),
  create: (data: any) => requests.postForm("inquiry/rfp", createFormData(data)),
  delete: (id: number) => requests.del(`inquiry/${id}`),
  //update:
  UserInquiriesForRFP: (id: number) =>
    requests.get(`inquiry/rfpuserInquiries/${id}`),
};

const InquriesProperty = {
  allPropertyInquiries: (id: number) =>
    requests.get(`inquiry/properties/${id}`),
  onePropertyInquiry: (id: number) => requests.get(`inquiry/property/${id}`),
  create: (data: any) =>
    requests.postForm("inquiry/property", createFormData(data)),
  delete: (id: number) => requests.del(`inquiry/${id}`),
  update: (id: number) => requests.put(`inquiry/updateInquiry/${id}`, {}),
  UserInquiriesForProperty: (id: number) =>
    requests.get(`inquiry/userInquiries/${id}`),
};

const OfferProperty = {
  AllPropertyOffers: (id: number) => requests.get(`offers/properties/${id}`),
  onePropertyOffer: (id: number) => requests.get(`offers/property/${id}`),
  create: (data: any) =>
    requests.postForm("offers/property", createFormData(data)),
  delete: (id: number) => requests.del(`offers/${id}`),
  update: (id: number) => requests.put(`offers/updateoffer/${id}`, {}),
  updateWithAttorneyReviewCheck: (
    requireAttorneyApprove: boolean,
    contractProgressId: number
  ) =>
    requests.put(
      `offers/updateoffer/${requireAttorneyApprove}/${contractProgressId}`,
      {}
    ),
  UserOffersForProperty: (id: number) =>
    requests.get(`offers/useroffers/${id}`),
  GetServiceOffersPropertyWithoutRFP: (id: number) =>
    requests.get(`offers/serviceOffers/${id}`),
  GetOneServiceOfferPropertyWithoutRFP: (id: number) =>
    requests.get(`offers/serviceOffer/${id}`),
};

const LenderOffers = {
  allLenderOffers: (id: number) =>
    requests.get(`lenderoffer/lenderoffers/${id}`),
  oneLenderOffer: (id: number) => requests.get(`lenderoffer/lenderoffer/${id}`),
  createLenderOffer: (data: any) =>
    requests.postForm(`lenderoffer`, createFormData(data)),
  updateLenderOffer: (id: number, contractId: number) =>
    requests.put(`lenderoffer/updatelenderoffer/${id}/${contractId}`, {}),
  delete: (id: number) => requests.del(`lenderoffer/deletelenderoffer/${id}`),
};

const TestErrors = {
  get400Error: () => requests.get("buggy/bad-request"),
  get401Error: () => requests.get("buggy/unauthorised"),
  get404Error: () => requests.get("buggy/not-found"),
  get500Error: () => requests.get("buggy/server-error"),
  getValidationError: () => requests.get("buggy/validation-error"),
};

const Account = {
  login: (values: any) => requests.post("account/login", values),
  register: (values: any) => requests.post("account/register", values),
  currentUser: () => requests.get("account/currentUser"),
  getTypes: () => requests.get(`userTypes`),
  createProfile: (values: any) =>
    requests.post("account/create-profile", createFormData(values)),
  editProfile: (data: any) => requests.post("account/edit-profile", data),
  getAllUsersForChat: () => requests.get("account/chat"),
};

const Partners = {
  getPartners: (id: any) => requests.get(`partnersNetwork/getPartners/${id}`),
  addPartner: (values: any) =>
    requests.post("partnersNetwork/addPartner", values),
};

const PartnerToDeal = {
  addPartnerToDeal: (values: any) =>
    requests.post(`dealpartners/addpropertypartner/`, values),
  getPartnersForDeal: (id: any) =>
    requests.get(`dealpartners/getPartners/${id}`),
};

const Admin = {
  getTypes: () => requests.get(`userTypes`),
  createTypes: (data: any) => requests.post("userTypes", {name: data}),
  updateTypes: (id: string, name: string) =>
    requests.put(`userTypes/${id}`, {id: id, name: name}),
  deleteTypes: (id: number) => requests.del(`userTypes/delete/${id}`),
};

const Phases = {
  getPhases: () => requests.get(`phases/getPhases`),
  create: (data: any) => requests.post("phases", data),
  update: (id: number, data: any) => requests.post(`phases/${id}`, data),
  delete: (id: number) => requests.get(`phases/delete/${id}`),
};

const Document = {
  upload: (data: any) => requests.post("document", data),
  editHtmlDocument: (data: HtmlDocument) => requests.put("document", data),
  getHtmlDocumentsForProperty: (id: number, contractProgressId: number) =>
    requests.get(`document/property/${id}/${contractProgressId}`),
  DocumentsForContractProgressBuyerSide: (contractProgressId: number) =>
    requests.get(
      `document/documentscontractprogressbuyer/${contractProgressId}`
    ),
  DocumentsForContractProgressSellerSide: (offerId: number) =>
    requests.get(`document/documentscontractprogress/${offerId}`),
  ImagesForContractProgressBuyerSide: (contractProgressId: number) =>
    requests.get(`document/imagescontractprogressbuyer/${contractProgressId}`),
  ImagesForContractProgressSellerSide: (offerId: number) =>
    requests.get(`document/imagescontractprogress/${offerId}`),
  uploadHtmlDocumentForProperty: (id: number, data: any) =>
    requests.post(`document/property/${id}`, data),
  approveDocument: (id: number) =>
    requests.put(`document/approvedocument/${id}`, {}),
  signDocument: (data: any) => requests.put(`document/signDocument`, data),
  docTypes: () => requests.get("document/docTypes"),
  deleteDocument: (id: number) => requests.del(`document/deletedocument/${id}`),
  uploadImage: (data: any) => requests.postForm(`document/image`, data),
  uploadImageforRFP: (data: any) =>
    requests.postForm(`document/rfpimage`, data),
  getImages: (id: number, contractProgressId: number) =>
    requests.get(`document/images/${id}/${contractProgressId}`),
  getImagesForRfp: (id: number) => requests.get(`document/rfpimages/${id}`),
  deleteImage: (id: number) => requests.del(`document/deleteimage/${id}`),
  GetDocumentActionTracker: (id: number) =>
    requests.get(`document/documentTracker/${id}`),
  getHtmlDocumentsForRfp: (id: number) => requests.get(`document/rfp/${id}`),
  uploadHtmlDocumentForRFP: (id: number, data: any) =>
    requests.post(`document/rfp/${id}`, data),
};

const Favorites = {
  UpdateFavoriteProperty: (id: number) =>
    requests.put(`favorites/favoriteproperties/${id}`, id),
  UpdateFavoriteRFP: (id: number) =>
    requests.put(`favorites/favoriterfps/${id}`, id),
};

const ContractProgressDeal = {
  contractProgressDeal: (id: number) =>
    requests.get(`contractprogress/dealcontractprogress/${id}`),
  oldSchoolContractProgress: (id: number) =>
    requests.get(`contractprogress/getOldSchoolContractProgress/${id}`),
  getmvptaskmessagechecker: (id: number) =>
    requests.get(`contractprogress/mvptaskmessagechecker/${id}`),
  getCurrentPhase: (id: number) =>
    requests.get(`contractprogress/contractProgressPhase/${id}`),
  FinalConfirmationUpdate: (id: number) =>
    requests.put(`contractprogress/finalConfirmation/${id}`, {}),
  setDateTime: (data: any) => requests.put(`contractprogress/datesetter`, data),
  ConfirmFirstDate: (id: number, date: string) =>
    requests.post(`contractprogress/dateoneconfirmer/${id}/${date}`, {}),
  ConfirmSecondDate: (id: number, date: string) =>
    requests.post(`contractprogress/datetwoconfirmer/${id}/${date}`, {}),
  ConfirmThirdDate: (id: number, date: string) =>
    requests.post(`contractprogress/datethreeconfirmer/${id}/${date}`, {}),
  notAttending: (id: number) =>
    requests.post(`contractprogress/dateconfirmernotattending/${id}`, {}),
  getConfirmDate: (id: number) =>
    requests.get(`contractprogress/confirmedDate/${id}`),
  getConfirmDateList: (id: number) =>
    requests.get(`contractprogress/confirmedDateList/${id}`),
  getTaskNamesBuyer: (id: number) =>
    requests.get(`contractprogress/getSideMenu/${id}`),
  getTaskNamesAllRoles: (id: number) =>
    requests.get(`contractprogress/getSideMenuAllRoles/${id}`),
  getDealDuration: (date: string) =>
    requests.get(`contractprogress/dealDuration/${date}`),
  getListOfTaskMessages: () =>
    requests.get(`contractprogress/listoftaskmessages`),
  getRepresentativeRoleInCp: (contractProgressId: number) =>
    requests.get(
      `contractprogress/representativeRoleInContractProgress/${contractProgressId}`
    ),
};

const Notifications = {
  allNotifications: () => requests.get(`notification`),
};

const Statistics = {
  userList: () => requests.get(`statistics/userList`),
  uniqueDealProgresses: () => requests.get(`statistics/uniqueDealProgresses`),
  progressStatuses: () => requests.get(`statistics/progressStatuses`),
};

function createFormData(item: any) {
  let formData = new FormData();

  for (const key in item) {
    // console.log(key, item[key], item[key].length, Array.isArray(item[key]));
    if (Array.isArray(item[key])) {
      for (let i = 0; i < item[key].length; i++) {
        formData.append(key, item[key][i]);
      }
    } else {
      formData.append(key, item[key]);
    }
  }

  return formData;
}

// function editFormData(item: any) {
//   let formData = new FormData();

//   for (const key in item) {
//       formData.append(key, item[key])
//   }
//   return formData;
// }

const agent = {
  Marketplace,
  TestErrors,
  Account,
  Admin,
  Phases,
  RFPs,
  RFPResponses,
  InquriesRFP,
  InquriesProperty,
  OfferProperty,
  Document,
  Favorites,
  Deals,
  LenderOffers,
  Partners,
  PartnerToDeal,
  ContractProgressDeal,
  Notifications,
  Statistics,
};

export default agent;
