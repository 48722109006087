import {Autocomplete, TextField} from "@mui/material";
import {UseControllerProps, useController} from "react-hook-form";

interface Props extends UseControllerProps {
  label: string;
  optionLabels: string[];
  optionValues: string[];
  multiple?: boolean;
  disabled?: boolean;
  required: boolean;
}

export default function AppAutocompleteInput({
  control,
  name,
  rules,
  label,
  optionLabels,
  optionValues,
  multiple,
  disabled,
}: Props) {
  const {field, fieldState} = useController({
    control,
    name,
    rules,
    defaultValue: [],
  });

  const changeHandler = (e: any, value: any) => {
    field.onChange(value);
  };

  return (
    <Autocomplete
      {...field}
      onChange={changeHandler}
      options={optionLabels}
      multiple={multiple}
      fullWidth
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          error={!!fieldState.error}
          helperText={"Insert Email of the recipient"}
        />
      )}
    />
  );
}
