import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  Typography,
} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../app/store/configureStore";
import {clearNotifications} from "../../app/store/notificationSlice";
import React, {useState} from "react";
import {TransitionProps} from "@mui/material/transitions";

export function NotificationHeader() {
  const {totalNotifications} = useAppSelector((state) => state.notification);
  const [open, setOpen] = useState(false);

  const dispatch = useAppDispatch();

  const handleAllRead = () => {
    dispatch(clearNotifications());
    setOpen(false);
    window.location.reload();
  };

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const ChildModal = (props: any) => {
    const handleClose = () => {
      setOpen(false);
    };

    return (
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{color: "rgba(66, 66, 66, 1)"}}
            >
              Are you sure you clear all notifications?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                background: "#047782",
                "&:hover": {
                  background: "#0196A4",
                },
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#fff",
                borderRadius: "4px",
                letterSpacing: "1.25px",
                margin: "15px",
              }}
              onClick={() => handleAllRead()}
            >
              Yes
            </Button>

            <Button
              sx={{
                background: "#fff",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#047782",
                letterSpacing: "1.25px",
                border: "1px solid #047782",
                borderRadius: "4px",
                margin: "15px",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{
        backgroundColor: "#BDCCDC",
        width: "100%",
        paddingX: "12px",
        paddingY: "2px",
      }}
    >
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          color: "#424242",
          fontSize: "15px",
          fontWeight: 400,
        }}
      >
        {totalNotifications} Unread Notification(s)
      </Typography>
      <ChildModal setModalOpen={setOpen} modalState={open} />
      <Button
        sx={{
          textTransform: "none",
          color: "#424242",
          fontSize: "15px",
          fontWeight: 400,
        }}
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
      >
        Mark All Read
      </Button>
    </Box>
  );
}
