import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {
  UnreadMessage,
  GlobalMessage,
  MessagesForGroup,
  PropertyRfpDictionary,
  UnreadMessages,
  UserDictionary,
  SubjectDictionary,
} from "../../app/models/messageCenter";

// Message center connection is stored in useState and passed through Context
// because the connection cannot be stored inside a Redux Slice
// (Error: It is not serializable)
export interface MessageCenterState {
  open: boolean;
  actionComments: MessagesForGroup;
  globalMessages: MessagesForGroup;
  users: UserDictionary;
  propertiesRfps: PropertyRfpDictionary;
  subjects: SubjectDictionary;
  totalMessageCards: number;
  openCardDrawerWithId: number;
  openCreateChat: boolean;
}

const initialState: MessageCenterState = {
  open: false,
  actionComments: {},
  globalMessages: {},
  users: {},
  propertiesRfps: {},
  subjects: {},
  totalMessageCards: 0,
  openCardDrawerWithId: 0,
  openCreateChat: false,
};

export const messageCenterSlice = createSlice({
  name: "messageCenter",
  initialState,
  reducers: {
    toggleMessageCenter: (state) => {
      state.open = !state.open;
    },
    openMessageCentar: (state) => {
      state.open = true;
    },
    toggleCreateChat: (state) => {
      state.openCreateChat = !state.openCreateChat;
    },
    openCreateChat: (state) => {
      state.openCreateChat = true;
    },
    closeChat: (state) => {
      state.openCreateChat = false;
    },
    setOpenCardDrawerWithId: (state, action: PayloadAction<number>) => {
      state.openCardDrawerWithId = action.payload;
    },
    setUnreadMessages: (state, action: PayloadAction<UnreadMessages>) => {
      state.actionComments = action.payload.actionComments;
      state.globalMessages = action.payload.globalMessages;

      state.users = action.payload.users;
      state.propertiesRfps = action.payload.propertiesRfps;
      state.subjects = action.payload.subjects;

      const unreadGlobalMessages = Object.keys(
        action.payload.globalMessages
      ).reduce((count, chatId) => {
        if (state.globalMessages[chatId]?.length === 1) {
          return count;
        } else return ++count;
      }, 0);
      state.totalMessageCards =
        Object.keys(action.payload.actionComments).length +
        unreadGlobalMessages;
    },
    setInitialGlobalMessage: (state, action: PayloadAction<GlobalMessage>) => {
      const chatId = action.payload.chatId;

      state.globalMessages[chatId] = action.payload.messages;
      action.payload.users.forEach((user) => {
        state.users[user.id] = user;
      });
      state.subjects[chatId] = action.payload.subject;

      state.totalMessageCards++;
    },
    addUnreadMessage: (state, action: PayloadAction<UnreadMessage>) => {
      const chatId = action.payload.chatId;

      // Based on chatId decide in which MessageGroup to add the unread message
      // If action comments
      if (chatId.startsWith("P") || chatId.startsWith("R")) {
        // If there is not a property in messages object for the particular chat or if it is empty array, add it, initialize it to an empty array and increase the number of totalMessageCards
        if (
          !state.actionComments[chatId] ||
          state.actionComments[chatId]?.length === 0
        ) {
          state.actionComments[chatId] = [];
          state.totalMessageCards++;
        }

        state.actionComments[chatId]!.push(action.payload.message);
        state.users[action.payload.user.id] = action.payload.user;
        state.propertiesRfps[chatId] = action.payload.propertyRfp;
      }

      // If global messages
      if (chatId.startsWith("G")) {
        // Increase the badge number only if there is one message
        if (state.globalMessages[chatId]!.length === 1) {
          state.totalMessageCards++;
        }

        state.globalMessages[chatId]!.push(action.payload.message);
        state.users[action.payload.user.id] = action.payload.user;
      }
    },
    deleteUnreadMessages: (state, action: PayloadAction<string>) => {
      const chatId = action.payload;

      // Based on chatId decide from which MessageGroup to delete the unread messages
      // If action comments delete all messages
      if (chatId.startsWith("P") || chatId.startsWith("R")) {
        // if the chat is not in MessageCenter or if the length of array of messages is 0 return
        if (
          state.actionComments[chatId] === undefined ||
          state.actionComments[chatId]?.length! === 0
        )
          return;

        // Delete messages
        state.actionComments[chatId] = [];
        // Reduce badge number
        if (state.totalMessageCards !== 0) state.totalMessageCards--;
      }

      // If global messages keep the last message
      // Prevent deleting the last message, therefore the MessageCard
      if (state.globalMessages[chatId]?.length === 1) return;

      if (chatId.startsWith("G")) {
        // Reduce badge number only if the number of messages is >= 2
        if (state.globalMessages[chatId]!.length >= 2)
          state.totalMessageCards--;

        // Keep only the last message
        const lastMessage =
          state.globalMessages[chatId]![
            state.globalMessages[chatId]?.length! - 1
          ];
        state.globalMessages[chatId] = [lastMessage];
      }
    },
    clearMessageCenter: (state) => {
      state = initialState;
    },
  },
});

export const {
  toggleMessageCenter,
  setOpenCardDrawerWithId,
  setUnreadMessages,
  setInitialGlobalMessage,
  addUnreadMessage,
  deleteUnreadMessages,
  clearMessageCenter,
  toggleCreateChat,
  openCreateChat,
  closeChat,
  openMessageCentar,
} = messageCenterSlice.actions;
