import {PropsWithChildren, useEffect, useState} from "react";
import {Box, Fade} from "@mui/material";
import DealToolbar from "./DealToolbar";
import Chat from "../../messageCenter/Chat/Chat";
import CommunicationToolbarPartners from "./CommunicationToolbarPartners";
import "./DealDrawerWrapper.css";
import {scrollCardIntoView} from "../../../app/util/util";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../app/store/configureStore";
import {setOpenCardDrawerWithId} from "../../messageCenter/messageCenterSlice";
import {Form} from "react-router-dom";
import DateTimePickerValue from "../../../app/components/Date&TimePicker";
import {FieldValues, useForm} from "react-hook-form";
import dayjs, {Dayjs} from "dayjs";
import agent from "../../../app/api/agent";
import {toast} from "react-toastify";
import DealProgressDrawer from "./DealProgressDrawer";
import VideoMeetings from "../../../app/components/VideoMeetings(coming soon)";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/en-gb";

dayjs.extend(localizedFormat);
dayjs.locale("en-gb");

interface Props extends PropsWithChildren {
  dealId: number;
  hideDocumentEditToolbar?: boolean;
  boxRef: React.MutableRefObject<Element | undefined>;
  prevScrollableAreaPosition: React.MutableRefObject<number | undefined>;
  isServiceCard: boolean;
  contractProgress: any;
  openProgressDrawer: {
    openProgressDrawer: boolean;
  };
  setOpenProgressDrawer: React.Dispatch<
    React.SetStateAction<{
      openProgressDrawer: boolean;
    }>
  >;
  property: any;
  onHandleLvL2ClickItemClick: (id: any) => void;
}

const DealDrawerWrapper = ({
  dealId,
  hideDocumentEditToolbar,
  boxRef,
  prevScrollableAreaPosition,
  isServiceCard,
  children,
  contractProgress,
  openProgressDrawer,
  property,
  setOpenProgressDrawer,
  onHandleLvL2ClickItemClick,
}: Props) => {
  const {openCardDrawerWithId} = useAppSelector((state) => state.messageCenter);
  const dispatch = useAppDispatch();
  const [openCommunicationToolbar, setOpenCommunicationToolbar] = useState({
    openCommunicationChat: false,
    openCommunicationToolbarPartners: false,
    openCalendar: false,
    openVideoMeetings: false,
  });
  const {handleSubmit} = useForm();
  const [value, setValue] = useState<Dayjs | null>(dayjs());

  const [
    contractProgressFirstVersionForDateSetter,
    setContractProgressFirstVersionForDateSetter,
  ] = useState();

  const [progressTracker, setProgressTracker] = useState([]);
  const [confirmDateList, setConfirmDateList] = useState([]);
  const [dateTimeTracking, setDateTimeTracking] = useState<Date>();

  const handleConfirmDate = async (data: FieldValues) => {
    data.contractProgressId = property.contractProgressId;
    data.dateTime = value;
    try {
      await agent.ContractProgressDeal.setDateTime(data).then(() => {
        toast.success("Date was set successfully");
      });
    } catch {
      toast.error("3 time slots added already");
    }
  };

  useEffect(() => {
    property.contractProgressId &&
      agent.ContractProgressDeal.getmvptaskmessagechecker(
        property.contractProgressId
      ).then((response) => {
        const newArr = response.$values.map(
          (item: any) => item.mvpTaskMessageId
        );
        setProgressTracker(newArr);
        let found = false;

        response.$values.some((item: any) => {
          if (item.mvpTaskMessageId === 9) {
            setDateTimeTracking(item.dateTime);
            found = true;
            return true;
          }
          return false;
        });

        if (!found) {
          setDateTimeTracking(undefined);
        }
      });
  }, [property.contractProgressId]);

  useEffect(() => {
    property.contractProgressId &&
      agent.ContractProgressDeal.getConfirmDateList(
        property.contractProgressId
      ).then((response) => setConfirmDateList(response.$values));
  }, [property.contractProgressId]);

  useEffect(() => {
    property.contractProgressId &&
      agent.ContractProgressDeal.oldSchoolContractProgress(
        property.contractProgressId
      ).then((response) => {
        setContractProgressFirstVersionForDateSetter(response);
      });
  }, [property.contractProgressId]);

  // useEffect(() => {
  //   prevScrollableAreaPosition.current = scrollCardIntoView(boxRef);
  // }, [boxRef, prevScrollableAreaPosition]);

  useEffect(() => {
    const prevPosition = scrollCardIntoView(boxRef);
    if (prevPosition !== undefined) {
      prevScrollableAreaPosition.current = prevPosition;
    }
  }, [boxRef, prevScrollableAreaPosition]);

  useEffect(() => {
    if (openCardDrawerWithId)
      setOpenCommunicationToolbar({
        openCommunicationChat: true,
        openCommunicationToolbarPartners: false,
        openCalendar: false,
        openVideoMeetings: false,
      });
  }, [openCardDrawerWithId]);

  useEffect(
    () => () => {
      dispatch(setOpenCardDrawerWithId(0));
    },
    [dispatch]
  );

  // function getContractProgress() {
  //   if (property.contractProgressId) {
  //     agent.ContractProgressDeal.contractProgressDeal(
  //       property.contractProgressId
  //     )
  //       .then((response) => {
  //         setContractProgressonCommunicationToolbarClick(response);
  //       })
  //       .catch((error) => console.log(error.response));
  //   }
  // }

  return (
    <Box
      className="open-drawer-height"
      sx={{
        padding: "20px",
        paddingTop: "0px",
        marginTop: "-15px",
        position: "relative",
      }}
    >
      <Box display="flex" flexDirection="column" height="100%">
        <DealToolbar
          isOpenProgressDrawer={openProgressDrawer.openProgressDrawer}
          onClickToOpenProgressDrawer={() =>
            setOpenProgressDrawer((prevState) => {
              return {
                openProgressDrawer: !prevState.openProgressDrawer,
              };
            })
          }
          onClickToOpenCommunicationChat={() =>
            setOpenCommunicationToolbar((prevState) => {
              return {
                openCommunicationToolbarPartners: false,
                openCalendar: false,
                openVideoMeetings: false,
                openCommunicationChat: !prevState.openCommunicationChat,
              };
            })
          }
          onClickToOpenCommunicationPartners={() =>
            setOpenCommunicationToolbar((prevState) => {
              // getContractProgress();
              return {
                openCommunicationChat: false,
                openCalendar: false,
                openVideoMeetings: false,
                openCommunicationToolbarPartners:
                  !prevState.openCommunicationToolbarPartners,
              };
            })
          }
          onClickToOpenCalendar={() =>
            setOpenCommunicationToolbar((prevState) => {
              return {
                openCommunicationChat: false,
                openCommunicationToolbarPartners: false,
                openVideoMeetings: false,
                openCalendar: !prevState.openCalendar,
              };
            })
          }
          onClickToOpenVideoMeetings={() =>
            setOpenCommunicationToolbar((prevState) => {
              return {
                openCommunicationChat: false,
                openCommunicationToolbarPartners: false,
                openCalendar: false,
                openVideoMeetings: !prevState.openVideoMeetings,
              };
            })
          }
          hideDocumentEditToolbar={hideDocumentEditToolbar}
        />
        <Box
          className="open-drawer-content-height"
          sx={{
            display: "flex",
            backgroundColor: "#F2F6FA",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Fade
            mountOnEnter
            unmountOnExit
            in={openProgressDrawer.openProgressDrawer}
          >
            {/* The component is wrapped in div to fix a MUI bug #27154 */}

            <div>
              <DealProgressDrawer
                contractProgress={contractProgress}
                progressTracker={progressTracker}
                onHandleLvL2ClickItemClick={onHandleLvL2ClickItemClick}
                dateTimeTracking={dateTimeTracking}
              />
            </div>
          </Fade>

          {children}

          {openCommunicationToolbar.openCommunicationChat ? (
            <div>
              <Chat
                chatId={isServiceCard ? `R.${dealId}` : `P.${dealId}`}
                property={property}
              />
            </div>
          ) : null}

          {openCommunicationToolbar.openCommunicationToolbarPartners ? (
            <div style={{right: 0, position: "relative"}}>
              <CommunicationToolbarPartners
                dealId={dealId}
                contractId={property.contractProgressId}
                onSetOpenCommunicationToolbar={setOpenCommunicationToolbar}
              />
            </div>
          ) : null}

          {openCommunicationToolbar.openCalendar ? (
            <Box>
              {/* The component is wrapped in div to fix a MUI bug #27154 */}

              <div style={{right: 0, position: "relative"}}>
                <Form onSubmit={handleSubmit(handleConfirmDate)}>
                  <DateTimePickerValue
                    value={value}
                    onSetValue={setValue}
                    contractProgress={contractProgress}
                    confirmDateList={confirmDateList}
                    contractProgressFirstVersionForDateSetter={
                      contractProgressFirstVersionForDateSetter
                    }
                  />
                </Form>
              </div>
            </Box>
          ) : null}

          {openCommunicationToolbar.openVideoMeetings ? (
            <Box>
              {/* The component is wrapped in div to fix a MUI bug #27154 */}

              <div style={{right: 0, position: "relative"}}>
                <VideoMeetings />
              </div>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default DealDrawerWrapper;
