import {Box, Button} from "@mui/material";
import {useState} from "react";
import InquiriesTable from "../../features/deal/dealInterest/InquiriesTable";
import {RFP} from "../models/rfp";
import RfpOffersTable from "../../features/deal/dealInterest/RfpOffersTable";

interface Props {
  rfp: RFP;
}

const RfpOffersDrawerMarketplace = ({rfp}: Props) => {
  const [activeInquiry, setActiveInquiry] = useState(false);

  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: "#F2F6FA",
        padding: "20px",
      }}
    >
      <Box sx={{backgroundColor: "#EAF0F5"}}>
        <Button
          onClick={() => setActiveInquiry(false)}
          sx={{
            paddingLeft: "15px",
            paddingTop: "15px",
            paddingBottom: "15px",
            color: activeInquiry ? "#616161" : "#0D2561",
            borderBottom: activeInquiry
              ? "none"
              : "2px solid rgba(13, 37, 97, 1)",
            borderRadius: 0,
          }}
        >
          OFFERS
        </Button>
        <Button
          onClick={() => setActiveInquiry(true)}
          sx={{
            paddingLeft: "15px",
            paddingTop: "15px",
            paddingBottom: "15px",
            color: activeInquiry ? "#0D2561" : "#616161",
            borderBottom: activeInquiry
              ? "2px solid rgba(13, 37, 97, 1)"
              : "none",
            borderRadius: 0,
          }}
        >
          INQUIRIES
        </Button>
      </Box>

      {activeInquiry ? (
        <InquiriesTable dealId={rfp.id} isServiceCard={true} />
      ) : (
        <RfpOffersTable dealId={rfp.id} />
      )}
    </Box>
  );
};

export default RfpOffersDrawerMarketplace;
