import {Navigate} from "react-router-dom";
import {useAppSelector} from "../../app/store/configureStore";

const AuthorizationWrapper = (props: any) => {
  const {user} = useAppSelector((state) => state.account);

  if (user) {
    return <Navigate to="/marketplace" />;
  }

  return props.children;
};

export default AuthorizationWrapper;
