import {Box, Paper, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import PrimaryButton from "./PrimaryButton";

export default function PrivacyPolicyPage() {
  return (
    <>
      <Box
        sx={{
          height: "60px",
          display: "flex",
          alignItems: "center",
        }}
      ></Box>
      <Box
        sx={{
          height: "calc(100% - 60px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{
            height: "600px",
            width: "650px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={"/images/Fingerprint verification.png"}
            alt="IMG"
            style={{
              height: "300px",
              width: "350px",
              marginBottom: "25px",
            }}
          />
          <Typography sx={{marginBottom: "15px"}}>
            <Link
              target="_blank"
              to="/documents/TermsOfService.pdf"
              style={{display: "flex", color: "#0D2561"}}
            >
              Terms of Service <LaunchIcon sx={{marginLeft: "5px"}} />
            </Link>
          </Typography>

          <Typography sx={{marginBottom: "15px"}}>
            <Link
              target="_blank"
              to="/documents/DataProcessingAddendum.pdf"
              style={{display: "flex", color: "#0D2561"}}
            >
              Data Processing Addendum <LaunchIcon sx={{marginLeft: "5px"}} />
            </Link>
          </Typography>

          <Typography sx={{marginBottom: "15px"}}>
            <Link
              target="_blank"
              to="/documents/PrivacyPolicy.pdf"
              style={{display: "flex", color: "#0D2561"}}
            >
              Privacy Policy <LaunchIcon sx={{marginLeft: "5px"}} />
            </Link>
          </Typography>

          <Typography>
            <Link
              target="_blank"
              to="https://www.domunus.com/tutorials"
              style={{display: "flex", color: "#0D2561"}}
            >
              Video Tutorials
              <LaunchIcon sx={{marginLeft: "5px"}} />
            </Link>
          </Typography>
        </Paper>

        <Paper
          sx={{
            height: "120px",
            width: "650px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingX: "25px",
            marginTop: "20px",
          }}
        >
          <Typography sx={{fontWeight: 400, fontSize: "16px"}}>
            Join our Newsletter to get notified of new updates
          </Typography>
          <Link
            target="_blank"
            to="https://www.domunus.com/join?ss_source=sscampaigns&ss_campaign_id=65982237556e833ebe2cec93&ss_email_id=65a54f69dfbe416a33b2985d&ss_campaign_name=How+to+simplify+your+real+estate+transaction&ss_campaign_sent_date=2024-01-15T15%3A29%3A58Z"
          >
            <PrimaryButton>Subscribe</PrimaryButton>
          </Link>
        </Paper>
      </Box>
    </>
  );
}
