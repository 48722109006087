import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {useEffect, useState} from "react";
import agent from "../../../app/api/agent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface Props {
  title?: string;
  messageCenterChat?: boolean;

  contractId: any;
}

export default function ChatHeader({
  title,
  messageCenterChat,

  contractId,
}: Props) {
  const [partnersInDeal, setPartnersInDeal] = useState([]);

  useEffect(() => {
    if (!contractId) return;

    const fetchPartners = async () => {
      try {
        const response = await agent.PartnerToDeal.getPartnersForDeal(
          contractId
        );
        setPartnersInDeal(response.$values);
      } catch (error) {
        console.error("Error fetching partners: ", error);
      }
    };

    fetchPartners();
  }, [contractId]);

  return (
    <>
      <Box sx={{paddingLeft: "20px"}}>
        {!messageCenterChat && (
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "20px",
              letterSpacing: "0.4px",
              color: "rgba(13, 37, 97, 1)",
              marginTop: "20px",
            }}
          >
            Current Task
          </Typography>
        )}
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Roboto",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "32px",
            letterSpacing: "0.25px",
            textAlign: "left",
            color: "rgba(13, 37, 97, 1)",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          {title}
        </Typography>
      </Box>
      {contractId && (
        <Box>
          <Accordion sx={{boxShadow: "none", border: "1px solid gray"}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              View recipient list:
            </AccordionSummary>
            <AccordionDetails>
              {partnersInDeal &&
                partnersInDeal.map((partner: any) => (
                  <Box
                    key={partner.id}
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    textAlign="left"
                  >
                    <Box width="50%" textAlign="left">
                      <Typography
                        variant="subtitle2"
                        fontWeight="700"
                        color="#424242"
                        sx={{textWrap: "wrap"}}
                      >
                        {partner.profession.replace(/([A-Z])/g, " $1").trim()}:
                      </Typography>
                    </Box>
                    <Box width="50%">
                      <Typography
                        variant="subtitle2"
                        fontWeight="400"
                        color="#616161"
                      >
                        {partner.firstName} {partner.lastName}
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </>
  );
}
