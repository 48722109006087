import {
  Grid,
  Typography,
  Card,
  Box,
  CardActions,
  TextField,
} from "@mui/material";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {useEffect, useState} from "react";
import {useAppSelector} from "../../../app/store/configureStore";
import agent from "../../../app/api/agent";
import LoaderWithLinearProgress from "../../../app/components/LoaderWithLinearProgress";
import RfpAdditionalDocumentsListCard from "../../../app/components/RfpAdditionalDocumentsListCard";
import MainRFPDocumentView from "../../../app/components/MainRFPDocument";

const ServiceCardDetailsView = () => {
  const {user} = useAppSelector((state) => state.account);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [rfpDocuments, setRrfpDocuments] = useState([]);
  const {id} = useParams<{id: string}>();
  const [mainRFP, setMainRFP] = useState("");

  const [rfp, setRfp] = useState({
    id: 0,
    nameOfSeeker: "",
    addressOfSeeker: "",
    addressOfProperty: "",
    typeOfService: "",
    serviceProvider: "",
    timePeriod: "",
    serviceDescription: "",
    mainRFP: "",
    documents: [],
    gallery: [],
    PublicId: "",
  });

  useEffect(() => {
    if (id) {
      agent.RFPs.RFPDetails(parseInt(id))
        .then((response) => {
          setRfp(response);
          const documentValues = response.documents.$values;
          setRrfpDocuments(documentValues);
          const mainRFPUrl = response.mainRFP;
          setMainRFP(mainRFPUrl);
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
    }
  }, [id]);

  if (!user) {
    navigate("/login");
  }

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F4F4F4",
          padding: "20px",
          paddingTop: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            width: "100%",
            alignItems: "center",
            position: "sticky",
            backgroundColor: "#F5F5F5",
            top: 0,
            height: "60px",
            zIndex: "10",
          }}
        >
          <NavLink to="/deals">
            <ArrowBackIosNewIcon
              sx={{
                display: "flex",
                color: "rgba(112, 112, 112, 1)",
                "&:hover": {
                  color: "rgba(208, 235, 238, 1)",
                },
              }}
            />
          </NavLink>
        </Box>
        <Card sx={{padding: "20px", height: "87%"}}>
          <Grid
            container
            spacing={3}
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              width: "50%",
              paddingLeft: "50px",
            }}
          >
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Roboto",
                  color: "#424242",
                  fontWeight: "600",
                  fontSize: "23px",
                }}
              >
                Request for Proposal
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                label="Name of Seeker"
                type="text"
                name="NameOfSeeker"
                disabled
                value={rfp.nameOfSeeker}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                label="Address of Seeker"
                type="text"
                name="AddressOfSeeker"
                disabled
                value={rfp.addressOfSeeker}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                label="Address of Property"
                type="text"
                name="AddressOfProperty"
                disabled
                value={rfp.addressOfProperty}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                label="Type of Service"
                name="TypeOfService"
                disabled
                value={rfp.typeOfService}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                label="Service Provider"
                type="text"
                name="ServiceProvider"
                disabled
                value={rfp.serviceProvider}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                label="Time Period"
                name="TimePeriod"
                disabled
                value={rfp.timePeriod}
              />
            </Grid>

            <Typography sx={{paddingLeft: "20px"}}>
              Service Description
            </Typography>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                label="Describe what you are looking for"
                type="text"
                name="ServiceDescription"
                disabled
                value={rfp.serviceDescription}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              width: "50%",
              paddingLeft: "20px",
              paddingRight: "20px",
              height: "100%",
            }}
          >
            <Grid item xs={12}>
              <Box height="40px"></Box>
              <Box>
                <Typography paddingTop="20px" fontWeight="700">
                  Main RFP
                </Typography>
                {mainRFP && mainRFP.length > 0 ? (
                  <Card sx={{backgroundColor: "#F7F7F7"}}>
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MainRFPDocumentView mainRFP={mainRFP} />
                    </CardActions>
                  </Card>
                ) : (
                  <Card
                    sx={{
                      backgroundColor: "#F7F7F7",
                      padding: 1,
                      height: "300px",
                      width: "470px",
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      flexWrap: "wrap",
                    }}
                  >
                    No documents attached
                  </Card>
                )}
              </Box>
              <Box paddingTop="20px">
                <Typography fontWeight="700">Additional Documents</Typography>
                {rfpDocuments && rfpDocuments.length > 0 ? (
                  <Card sx={{backgroundColor: "#F7F7F7"}}>
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <RfpAdditionalDocumentsListCard
                        rfpDocuments={rfpDocuments}
                        name="x"
                      />
                    </CardActions>
                  </Card>
                ) : (
                  <Card
                    sx={{
                      backgroundColor: "#F7F7F7",
                      padding: 1,
                      height: "300px",
                      width: "470px",
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      flexWrap: "wrap",
                    }}
                  >
                    No documents attached
                  </Card>
                )}
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default ServiceCardDetailsView;
