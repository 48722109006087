import {ClickAwayListener, InputAdornment, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  onSearchClose: () => void;
  onPropertySearch: (value: any) => void;
}

export default function SearchBarOpen({
  onSearchClose,
  onPropertySearch,
}: Props) {
  return (
    // <ClickAwayListener onClickAway={onSearchClose}>
    <TextField
      sx={{
        backgroundColor: "white",
        border: "1px solid #9E9E9E",
        borderRadius: "35px",
        padding: "5px",
        width: "340px",
        height: "45px",
        color: "#9E9E9E",
      }}
      variant="standard"
      autoFocus
      label=""
      placeholder={"Type here to search"}
      type="search"
      onChange={(event: any) => {
        onPropertySearch(event.target.value);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{paddingLeft: "10px"}}>
            <SearchIcon
              sx={{
                color: "rgba(158, 158, 158, 1)",
              }}
            />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <CloseIcon
              onClick={onSearchClose}
              sx={{
                color: "rgba(117, 117, 117, 1)",
                marginRight: "10px",
                cursor: "pointer",
              }}
            />
          </InputAdornment>
        ),
        disableUnderline: true,
      }}
    />
    //  </ClickAwayListener>
  );
}
