import {Box, Paper, Typography} from "@mui/material";

export default function MyDealsNoProperties() {
  return (
    <>
      <Box
        sx={{
          height: "60px",
          display: "flex",
          alignItems: "center",
        }}
      ></Box>
      <Box
        sx={{
          height: "calc(100% - 60px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{
            height: "600px",
            width: "650px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={"/images/thinking man.png"}
            alt="IMG"
            style={{
              height: "300px",
              width: "350px",
              marginBottom: "25px",
            }}
          />
          <Typography sx={{fontWeight: 800, fontSize: "23px"}}>
            No deals have started yet.
          </Typography>
          <Typography sx={{fontWeight: 400, fontSize: "16px"}}>
            You will see the Properties and RFPs that you are part of here.
          </Typography>
        </Paper>
      </Box>
    </>
  );
}
