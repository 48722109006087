import {Box, Divider} from "@mui/material";
import {
  MessageGroupType,
  MessagesForGroup,
  UserDictionary,
  PropertyRfpDictionary,
  MessageGroupTypes,
  SubjectDictionary,
} from "../../app/models/messageCenter";
import MessageCard from "./MessageCard";
import MessageGroupHeader from "./MessageGroupHeader";
import Chat from "./Chat/Chat";

interface Props {
  type: MessageGroupType;
  messages: MessagesForGroup;
  users: UserDictionary;
  propertiesRfps: PropertyRfpDictionary;
  subjects: SubjectDictionary;
  messageGroupChatId?: string;
  onOpenMessageGroupChatHandler?: (id: string) => void;
  onCloseMessageGroupChatHandler?: () => void;
  onDeleteUnreadMessages: (chatId: string) => Promise<void>;
}

export default function MessageGroup({
  type,
  messages,
  users,
  propertiesRfps,
  subjects,
  messageGroupChatId,
  onOpenMessageGroupChatHandler,
  onCloseMessageGroupChatHandler,
  onDeleteUnreadMessages,
}: Props) {
  const hasMessages = Object.keys(messages).reduce((hasMessages, m) => {
    if (messages[m]?.length! > 0) {
      return true;
    }
    return hasMessages;
  }, false);

  if (!hasMessages) return;

  return (
    <Box
      display="flex"
      flexDirection="column"
      className="message-group"
      height={messageGroupChatId ? "100%" : "auto"}
    >
      <MessageGroupHeader
        type={type}
        isOpenMessageGroupChat={!!messageGroupChatId}
        onCloseMessageGroupChat={onCloseMessageGroupChatHandler}
      />

      {messageGroupChatId ? (
        <Chat chatId={messageGroupChatId} messageCenterChat property />
      ) : (
        Object.keys(messages).map((chatId, i, arr) => {
          if (messages[chatId]!.length !== 0)
            return (
              <div key={chatId}>
                <MessageCard
                  chatId={chatId}
                  messages={messages[chatId]!}
                  users={users}
                  propertiesRfps={propertiesRfps}
                  subjects={subjects}
                  globalMessage={type === MessageGroupTypes.global}
                  onOpenMessageGroupChat={onOpenMessageGroupChatHandler}
                  onDeleteUnreadMessages={onDeleteUnreadMessages}
                />
                {i !== arr.length - 1 && messages[arr[++i]]?.length !== 0 && (
                  <Divider sx={{marginX: "10px"}} />
                )}
              </div>
            );
          return null;
        })
      )}
    </Box>
  );
}
