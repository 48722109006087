import {Box} from "@mui/material";
import {useState, useEffect, useReducer} from "react";
import agent from "../../app/api/agent";
import {useAppSelector} from "../../app/store/configureStore";
import {useNavigate} from "react-router-dom";
import RFPList from "../rfps/RFPList";
import PropertyList from "../properties/PropertyList";
import LoaderWithLinearProgress from "../../app/components/LoaderWithLinearProgress";

const MyMarketplace = (Props: any) => {
  const initialState = {
    allProperties: [],
    allRfps: [],
    adjustedProperties: [],
    adjustedRfps: [],
  };

  const {user} = useAppSelector((state) => state.account);

  function reducer(state: any, action: any): any {
    switch (action.type) {
      case "setMarketplace": {
        return {
          ...state,
          allProperties: action.payload.properties.$values,
          allRfps: action.payload.rfPs.$values,
          adjustedProperties: action.payload.properties.$values.filter(
            (x: any) => x.userId === user?.id
          ),
          adjustedRfps: action.payload.rfPs.$values.filter(
            (x: any) => x.userId === user?.id
          ),
        };
      }

      default:
        throw new Error("Unknown action");
    }
  }

  const [{adjustedProperties, adjustedRfps}, dispatch] = useReducer(
    reducer,
    initialState
  );

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleFavoriteChange = (propertyId: any) => {
    dispatch({type: "toggleFavoriteProperties", payload: propertyId});
  };

  const handleFavoriteRfpChange = (rfpId: any) => {
    dispatch({type: "toggleFavoriteRfps", payload: rfpId});
  };

  useEffect(() => {
    setLoading(true);
    agent.Marketplace.allMarketplaceByUser()
      .then((marketplace) => {
        dispatch({
          type: "setMarketplace",
          payload: marketplace,
        });
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, []);

  if (!user) {
    navigate("/login");
  }

  if (user && !user?.isConfirmed) {
    navigate("/create-profile");
  }

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F5F5F5",
          padding: "15px",
        }}
      >
        <PropertyList
          properties={adjustedProperties}
          handleFavoriteChange={handleFavoriteChange}
        ></PropertyList>
        <RFPList
          rfps={adjustedRfps}
          handleFavoriteRfpChange={handleFavoriteRfpChange}
        />
      </Box>
    </>
  );
};

export default MyMarketplace;
