import {Box, TextField, Typography} from "@mui/material";
import {PropsWithChildren} from "react";
import ServiceProviderConfirmDateForPropertyVisit from "./ServiceProviderConfirmDateForPropertyVisit";

interface Props extends PropsWithChildren {
  firstTimeSlotConfirm: boolean;
  secondTimeSlotConfirm: boolean;
  thirdTimeSlotConfirm: boolean;
  notAttendingConfirm: boolean;
  contractProgressFirstVersionForDateSetter: any;
  confirmDateList: any;
}

export default function ConfimedScheduleDate({
  firstTimeSlotConfirm,
  secondTimeSlotConfirm,
  thirdTimeSlotConfirm,
  notAttendingConfirm,
  contractProgressFirstVersionForDateSetter,
  confirmDateList,
}: Props) {
  let firstSchedule =
    contractProgressFirstVersionForDateSetter.firstDateTimeSlotForPropertyVisit;
  let secondSchedule =
    contractProgressFirstVersionForDateSetter.secondDateTimeSlotForPropertyVisit;
  let thirdSchedule =
    contractProgressFirstVersionForDateSetter.thirdDateTimeSlotForPropertyVisit;

  if (
    contractProgressFirstVersionForDateSetter.firstDateTimeSlotForPropertyVisit !=
    null
  ) {
    firstSchedule = firstSchedule
      .replace(/T/, " ")
      .replace(/Z/, " ")
      .slice(0, -4);
  }
  if (
    contractProgressFirstVersionForDateSetter.secondDateTimeSlotForPropertyVisit !=
    null
  ) {
    secondSchedule = secondSchedule
      .replace(/T/, " ")
      .replace(/Z/, " ")
      .slice(0, -4);
  }
  if (
    contractProgressFirstVersionForDateSetter.thirdDateTimeSlotForPropertyVisit !=
    null
  ) {
    thirdSchedule = thirdSchedule
      .replace(/T/, " ")
      .replace(/Z/, " ")
      .slice(0, -4);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "15px",
      }}
    >
      {firstTimeSlotConfirm === true ? (
        <>
          <Typography>Confirmed Date for Schedule - Availability</Typography>
          <TextField value={firstSchedule} sx={{width: "65%"}} />
        </>
      ) : secondTimeSlotConfirm === true ? (
        <>
          <Typography>Confirmed Date for Schedule - Availability</Typography>
          <TextField value={secondSchedule} sx={{width: "65%"}} />
        </>
      ) : thirdTimeSlotConfirm === true ? (
        <>
          <Typography>Confirmed Date for Schedule - Availability</Typography>
          <TextField value={thirdSchedule} sx={{width: "65%"}} />
        </>
      ) : notAttendingConfirm === true ? (
        <>
          <Typography>Confirmed Date for Schedule - Availability</Typography>
          <TextField value={"Not Attending"}></TextField>
        </>
      ) : null}

      <ServiceProviderConfirmDateForPropertyVisit
        confirmDateList={confirmDateList}
        contractProgressFirstVersionForDateSetter={
          contractProgressFirstVersionForDateSetter
        }
      />
    </Box>
  );
}
