import {Box, Paper, Typography} from "@mui/material";

export default function SupportPage() {
  return (
    <>
      <Box
        sx={{
          height: "60px",
          display: "flex",
          alignItems: "center",
        }}
      ></Box>
      <Box
        sx={{
          height: "calc(100% - 60px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{
            height: "600px",
            width: "650px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={"/images/Support.png"}
            alt="IMG"
            style={{
              height: "420px",
              width: "430px",
              marginBottom: "25px",
            }}
          />
          <Typography sx={{fontWeight: 800, fontSize: "23px"}}>
            Need help with something?
          </Typography>

          <Typography sx={{fontWeight: 400, fontSize: "16px"}}>
            Feel free to email us at hello@domunus.com if you
          </Typography>
          <Typography sx={{fontWeight: 400, fontSize: "16px"}}>
            have any support issues or feedback on the product.
          </Typography>
          <Typography sx={{fontWeight: 400, fontSize: "16px"}}>
            We are always happy to hear new ideas.
          </Typography>
        </Paper>
      </Box>
    </>
  );
}
