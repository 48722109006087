import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import agent from "../../app/api/agent";
import {styled} from "@mui/system";
import LoaderWithLinearProgress from "../../app/components/LoaderWithLinearProgress";
import {toast} from "react-toastify";

function PartnersContentMUI(props: any) {
  const {
    register,
    setError,
    formState: {errors},
    reset,
  } = useForm();

  const [partners, setPartners] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    partnerRole: "",
    isActive: false,
  });

  function handleApiErrors(errors: any) {
    if (errors) {
      errors.forEach((error: string) => {
        if (error.includes("Password")) {
          setError("password", {message: error});
        } else if (error.includes("Email")) {
          setError("email", {message: error});
        } else if (error.includes("Username")) {
          console.log(error.includes("Username"));
          setError("username", {message: error});
        }
      });
    }
  }

  const handleChange = (e: any) => {
    let {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    formData.partnerRole = `${props.selectedItem.name}`;
    try {
      await agent.Partners.addPartner(formData).then((response) => {
        setPartners([...partners, response.user]);
        toast.success("Partner added successfully");
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          partnerRole: "",
          isActive: false,
        });
        reset();
      });
    } catch (error) {
      console.log(error);
      handleApiErrors(error);
    }
    setLoading(false);
  };

  const Root = styled("div")(
    ({theme}) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
    td,
    th {
      text-align: left;
       }
    `
  );

  useEffect(() => {
    if (props.selectedItem.id) {
      agent.Partners.getPartners(props.selectedItem.id)
        .then((response) => {
          setPartners(response.$values);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props.selectedItem.id]);

  const sortedPartners = partners.sort((a, b) =>
    a.lastName.localeCompare(b.lastName)
  );

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <>
      {props.selectedItem && props.selectedItem.name ? (
        <Grid item xs={12} sx={{height: "100%"}}>
          <Card
            sx={{
              height: "68.6%",
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#FFF",
            }}
          >
            <Card
              sx={{
                boxShadow: "none",
                backgroundColor: "#FFF",
                width: "100%",
                padding: "10px",
                overflowY: "scroll",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "inline-flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  position: "sticky",
                }}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  sx={{
                    color: "#0D2561",
                    fontWeight: "400",
                    fontSize: "23px",
                    lineHeight: "32px",
                    paddingLeft: "20px",
                  }}
                >
                  {props.selectedItem.name.replace(/([A-Z])/g, " $1").trim()}
                </Typography>
              </Box>
              <Divider sx={{margin: "10px", border: "1px solid #0D2561"}} />
              {partners && partners.length > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "490px",
                  }}
                >
                  {sortedPartners.map((partner) => (
                    <Card
                      sx={{
                        display: "inline-flex",
                        width: "100%",
                        backgroundColor: "#FFF",
                        border: "none",
                        boxShadow: "none",
                        paddingLeft: "20px",
                      }}
                      key={partner.id}
                    >
                      <Typography
                        component="div"
                        width="100%"
                        display="inline-flex"
                        alignItems="center"
                      >
                        <Root sx={{maxWidth: "100%"}}>
                          <table>
                            <tbody>
                              <tr key={partner.email}>
                                <td style={{width: 210}}>
                                  {partner.firstName} {partner.lastName}
                                </td>
                                <td style={{width: 210}}>{partner.email}</td>
                                <td style={{width: 160}}>
                                  {partner.phoneNumber}
                                </td>
                                <td style={{width: 440}}>
                                  {partner.companyAddress} {partner.state}
                                  {partner.postalCode}
                                </td>
                                <td
                                  style={{
                                    minWidth: 105,
                                  }}
                                >
                                  |&nbsp; Call &nbsp; | &nbsp; Message &nbsp;
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Root>
                      </Typography>
                    </Card>
                  ))}
                </Box>
              ) : (
                <Card
                  sx={{
                    boxShadow: "none",
                    backgroundColor: "#FFF",
                    width: "100%",
                    padding: "10px",
                    marginleft: "10px",
                  }}
                >
                  List of partners in{" "}
                  <strong>
                    {props.selectedItem.name.replace(/([A-Z])/g, " $1").trim()}
                  </strong>{" "}
                  is empty
                </Card>
              )}
            </Card>
          </Card>

          <Card
            sx={{
              backgroundColor: "#FFF",
              marginTop: "10px",
              height: "30%",
              padding: "10px",
              overflow: "scroll",
            }}
          >
            <Box>
              <Typography
                variant="h5"
                sx={{color: "#0D2561", padding: "10px", paddingLeft: "20px"}}
              >
                Invite Partner
              </Typography>
            </Box>
            <Divider
              sx={{
                border: "1px solid #0D2561",
                marginLeft: "10px",
                marginRight: "10px",
                marginBottom: "10px",
              }}
            />
            <FormControl onSubmit={handleSubmit} sx={{width: "100%"}}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <Box sx={{width: "33%", padding: "5px"}}>
                  <TextField
                    sx={{display: "none"}}
                    type="text"
                    name="isActive"
                    defaultValue="false"
                  />

                  <TextField
                    sx={{
                      width: "100%",
                      color: "#000000",
                    }}
                    variant="standard"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    label="First Name"
                    size="small"
                    required
                  />
                  {errors && (
                    <Typography sx={{color: "error.main"}}>
                      {!!errors.username
                        ? (errors?.username?.message as string)
                        : ""}
                    </Typography>
                  )}
                </Box>
                <Box sx={{width: "33%", padding: "5px"}}>
                  <TextField
                    sx={{width: "100%"}}
                    variant="standard"
                    name="lastName"
                    label="Last Name"
                    size="small"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                </Box>
                <Box sx={{width: "33%", padding: "5px"}}>
                  <TextField
                    sx={{width: "100%"}}
                    variant="standard"
                    type="email"
                    id="email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^\w+[\w-.]*@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/,
                        message: "Not a valid email address",
                      },
                    })}
                    value={formData.email}
                    onChange={handleChange}
                    label="Email"
                    size="small"
                    required
                  />
                  {errors && (
                    <Typography sx={{color: "error.main"}}>
                      {!!errors.email ? (errors?.email?.message as string) : ""}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "25px",
                  paddingX: "5px",
                }}
              >
                <Link underline="none" href="https://www.google.com/maps/">
                  <Button
                    variant="outlined"
                    sx={{
                      color: "#00838F",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontSize: "0.875rem",
                      fontWeight: "400",
                      letterSpacing: "1.25px",
                      width: "240px",
                    }}
                    type="submit"
                  >
                    Search via Google Maps
                  </Button>
                </Link>
                <Button
                  onClick={handleSubmit}
                  sx={{
                    background: "#00838F",
                    "&.MuiButtonBase-root:hover": {
                      bgcolor: "#00838F",
                    },
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontSize: "0.875rem",
                    fontWeight: "400",
                    color: "#fff",
                    letterSpacing: "1.25px",
                    width: "150px",
                    marginRight: "20px",
                  }}
                  type="submit"
                >
                  Invite Partner
                </Button>
              </Box>
            </FormControl>
          </Card>
        </Grid>
      ) : (
        <Typography>Select Role</Typography>
      )}
    </>
  );
}

export default PartnersContentMUI;
