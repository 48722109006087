import {TextField, InputAdornment, Box, IconButton} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {useState} from "react";

interface Props {
  onSend: (message: string) => void;
}

export default function SendMessageForm({onSend}: Props) {
  const [message, setMessage] = useState("");

  const submitHandler = (e: any) => {
    e.preventDefault();
    if (!message) return;
    onSend(message);
    setMessage("");
  };

  return (
    <Box component="form" onSubmit={submitHandler}>
      <TextField
        sx={{
          fontSize: "14px",
          width: "96%",
          borderRadius: "35px",
          border: "1px solid rgba(158, 158, 158, 1)",
          marginTop: "10px",
          marginBottom: "12px",
          marginLeft: "2%",
        }}
        variant="standard"
        autoComplete="off"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        required
        inputProps={{}}
        InputProps={{
          sx: {
            fontSize: "14px",
            paddingY: "3px",
            paddingLeft: "13px",
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                type="submit"
                sx={{
                  color: "rgba(158, 158, 158, 1)",
                }}
              >
                <SendIcon />
              </IconButton>
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
      />
    </Box>
  );
}
