import {
  Box,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Button,
} from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LinkIcon from "@mui/icons-material/Link";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {useState, useEffect, useReducer} from "react";
import agent from "../../app/api/agent";
import {useAppSelector} from "../../app/store/configureStore";
import {Link, useNavigate} from "react-router-dom";
import RFPList from "../rfps/RFPList";
import FilterListIcon from "@mui/icons-material/FilterList";
import PropertyList from "../properties/PropertyList";
import SearchBarOpen from "../../app/components/SearchBarOpen";
import SearchBarClosed from "../../app/components/SearchBarClosed";
import MarketplaceFilter from "../../app/components/MarketplaceFilter";
import LoaderWithLinearProgress from "../../app/components/LoaderWithLinearProgress";
import {toast} from "react-toastify";

const MarketplaceMUI = (Props: any) => {
  const initialState = {
    anchorElCategory: null,
    openCategory: false,
    anchorElType: null,
    openType: false,
    anchorElsize: null,
    openSize: false,
    anchorElPrice: null,
    openPrice: false,
    isActive: false,
    isFilterActive: false,
    openSpeedButton: false,
    onlyRfps: false,
    primaryView: true,
    allProperties: [],
    allRfps: [],
    adjustedProperties: [],
    adjustedRfps: [],
  };

  function reducer(state: any, action: any): any {
    switch (action.type) {
      case "setMarketplace": {
        return {
          ...state,
          allProperties: action.payload.properties.$values,
          allRfps: action.payload.rfPs.$values,
          adjustedProperties: action.payload.properties.$values,
          adjustedRfps: action.payload.rfPs.$values,
        };
      }
      case "toggleCategory":
        return {
          ...state,
          anchorElCategory: action.payload ? action.payload : null,
          openCategory: !state.openCategory,
        };
      case "toggleType":
        return {
          ...state,
          anchorElType: action.payload ? action.payload : null,
          openType: !state.openType,
        };
      case "toggleSize":
        return {
          ...state,
          anchorElSize: action.payload ? action.payload : null,
          openSize: !state.openSize,
        };
      case "togglePrice":
        return {
          ...state,
          anchorElPrice: action.payload ? action.payload : null,
          openPrice: !state.openPrice,
        };
      case "setIsActive":
        return {
          ...state,
          isActive: !state.isActive,
          lowercasedvalue: state.isActive ? state.lowercasedvalue : "",
        };
      case "setIsFilterActive":
        return {
          ...state,
          isFilterActive: !state.isFilterActive,
        };
      case "setOpenSpeedButton":
        return {
          ...state,
          openSpeedButton: !state.openSpeedButton,
        };

      case "bigToSmall":
        return {
          ...state,
          adjustedProperties: action.payload,
        };

      case "smallToBig":
        return {
          ...state,
          adjustedProperties: action.payload,
        };

      case "highToLow":
        return {
          ...state,
          adjustedProperties: action.payload,
        };

      case "lowToHigh":
        return {
          ...state,
          adjustedProperties: action.payload,
        };

      case "showOnlyProperties":
        return {
          ...state,
          primaryView: true,
          onlyRfps: false,
        };

      case "showOnlyRfps":
        return {
          ...state,
          primaryView: false,
          onlyRfps: true,
          adjustedRfps: action.payload,
        };

      case "showAllMarketplace":
        return {
          ...state,
          adjustedProperties: action.payload.allProperties,
          adjustedRfps: action.payload.allRfps,
          primaryView: false,
          onlyRfps: false,
        };

      case "showOnlyFavorites":
        return {
          ...state,
          adjustedProperties: action.payload.favoriteProperties,
          adjustedRfps: action.payload.favoriteRfps,
          primaryView: false,
          onlyRfps: false,
          isActive: false,
        };

      case "toggleFavoriteProperties":
        const updatedProperties = state.allProperties.map((property: any) =>
          property.id === action.payload
            ? {...property, isFavorite: !property.isFavorite}
            : property
        );
        return {
          ...state,
          onlyRfps: false,
          isActive: false,
          allProperties: updatedProperties,
        };

      case "toggleFavoriteRfps":
        const updatedRfps = state.allRfps.map((rfp: any) =>
          rfp.id === action.payload
            ? {...rfp, isFavorite: !rfp.isFavorite}
            : rfp
        );
        return {
          ...state,
          primaryView: false,
          onlyRfps: false,
          isActive: false,
          allRfps: updatedRfps,
        };

      case "searchResults":
        return {
          ...state,
          adjustedProperties: action.payload.properties,
          adjustedRfps: action.payload.rfps,
          primaryView: false,
          onlyRfps: false,
        };
      case "propertyTypeFilter":
        return {
          ...state,
          adjustedProperties: action.payload.adjustedProperties,
          adjustedRfps: [],
          primaryView: false,
          onlyRfps: false,
          isActive: false,
          openType: false,
        };
      case "newestFirst":
        return {
          ...state,
          adjustedProperties: action.payload,
        };

      default:
        throw new Error("Unknown action");
    }
  }

  const [
    {
      anchorElCategory,
      openCategory,
      anchorElType,
      openType,
      anchorElSize,
      openSize,
      anchorElPrice,
      openPrice,
      isActive,
      isFilterActive,
      openSpeedButton,
      onlyRfps,
      primaryView,
      allProperties,
      allRfps,
      adjustedProperties,
      adjustedRfps,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const {user} = useAppSelector((state) => state.account);
  const [loading, setLoading] = useState(false);
  const {open} = useAppSelector((state) => state.messageCenter);
  const navigate = useNavigate();
  let lowercasedvalue = "";

  const handleToggleCategory = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch({type: "toggleCategory", payload: event.currentTarget});
  };

  const handleToggleSize = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch({type: "toggleSize", payload: event.currentTarget});
  };

  const handleTogglePrice = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch({type: "togglePrice", payload: event.currentTarget});
  };

  const handleToggleType = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch({type: "toggleType", payload: event.currentTarget});
  };

  function handleShowOnlyProperties() {
    dispatch({type: "showOnlyProperties"});
  }

  function handleShowAllMarketplace() {
    dispatch({
      type: "showAllMarketplace",
      payload: {allProperties, allRfps},
    });
  }

  function handleShowOnlyRfps() {
    dispatch({type: "showOnlyRfps", payload: allRfps});
  }

  const handleFavoriteChange = (propertyId: any) => {
    dispatch({type: "toggleFavoriteProperties", payload: propertyId});
  };

  function handleClickFavorites() {
    dispatch({
      type: "showOnlyFavorites",
      payload: {
        favoriteProperties: allProperties.filter(
          (x: any) => x.isFavorite === true
        ),
        favoriteRfps: allRfps.filter((x: any) => x.isFavorite === true),
      },
    });
  }

  const handleFavoriteRfpChange = (rfpId: any) => {
    dispatch({type: "toggleFavoriteRfps", payload: rfpId});
  };

  const handlePropertyType = (value: any) => {
    dispatch({
      type: "propertyTypeFilter",
      payload: {
        adjustedProperties:
          value === "All Types"
            ? allProperties
            : allProperties.filter((x: any) => x.type === value),
      },
    });
  };

  const handlePropertySearch = (value: any) => {
    lowercasedvalue = value.toLowerCase().trim();

    const filteredProperties = allProperties.filter(
      (p: any) =>
        p.city.toLowerCase().includes(lowercasedvalue) ||
        p.state.toLowerCase().includes(lowercasedvalue) ||
        p.postalCode.toLowerCase().includes(lowercasedvalue) ||
        p.addressLine.toLowerCase().includes(lowercasedvalue) ||
        p.id.toString().includes(lowercasedvalue)
    );

    const filteredRfps = allRfps.filter(
      (r: any) =>
        r.addressOfProperty.toLowerCase().includes(lowercasedvalue) ||
        r.serviceProvider.toLowerCase().includes(lowercasedvalue) ||
        r.nameOfSeeker.toLowerCase().includes(lowercasedvalue) ||
        r.addressOfSeeker.toLowerCase().includes(lowercasedvalue) ||
        r.id.toString().includes(lowercasedvalue)
    );

    dispatch({
      type: "searchResults",
      payload: {
        properties: filteredProperties,
        rfps: filteredRfps,
      },
    });
  };

  const handleHighToLow = (value: any) => {
    dispatch({
      type: "highToLow",
      payload: adjustedProperties
        .slice()
        .sort((a: any, b: any) => Number(b.listPrice) - Number(a.listPrice)),
    });
  };

  const handleLowToHigh = (value: any) => {
    dispatch({
      type: "lowToHigh",
      payload: adjustedProperties
        .slice()
        .sort((a: any, b: any) => Number(a.listPrice) - Number(b.listPrice)),
    });
  };

  const handleBigToSmall = (value: any) => {
    dispatch({
      type: "bigToSmall",
      payload: adjustedProperties
        .slice()
        .sort(
          (a: any, b: any) => Number(b.buildingSize) - Number(a.buildingSize)
        ),
    });
  };

  const handleSmallToBig = (value: any) => {
    dispatch({
      type: "smallToBig",
      payload: adjustedProperties
        .slice()
        .sort(
          (a: any, b: any) => Number(a.buildingSize) - Number(b.buildingSize)
        ),
    });
  };

  const handleNewAddedOnTop = (value: any) => {
    dispatch({
      type: "newestFirst",
      payload: adjustedProperties
        .slice()
        .sort(
          (a: any, b: any) =>
            new Date(b.dateAdded).getTime() - new Date(a.dateAdded).getTime()
        ),
    });
  };

  const handleToggleSearch = () => {
    dispatch({type: "setIsActive"});
    if (isActive) {
      handlePropertySearch("");
    }
  };

  const handleToggleFillter = () => {
    dispatch({type: "setIsFilterActive"});
  };

  const handleToggleSpeedButton = () => {
    dispatch({type: "setOpenSpeedButton"});
  };

  const handleToast = () => {
    toast.warning("The feature for linking properties to be added soon");
  };

  useEffect(() => {
    setLoading(true);
    agent.Marketplace.allMarketplaceByUser()
      .then((marketplace) => {
        dispatch({
          type: "setMarketplace",
          payload: marketplace,
        });
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, []);

  const actions = [
    {
      icon: (
        <Link
          to="/requestforproposal"
          style={{display: "flex", alignItems: "center"}}
        >
          <InsertDriveFileOutlinedIcon
            sx={{
              color: "#047782",
              display: "flex",
              alignItems: "center",
              alignContent: "center",
            }}
          />
        </Link>
      ),
      name: "Add an RFP",
    },
    {
      icon: (
        <Link
          to="/new-property"
          style={{display: "flex", alignItems: "center"}}
        >
          <ApartmentIcon sx={{color: "#047782"}} />
        </Link>
      ),
      name: "Add a Property",
      disabled: user?.profession !== "Representative",
    },
    {
      icon: (
        <Box
          onClick={() => handleToast()}
          sx={{display: "flex", alignItems: "center"}}
        >
          <LinkIcon sx={{color: "#047782"}} />
        </Box>
      ),
      name: "Link a property",
    },
  ];

  if (!user) {
    navigate("/login");
  }

  if (user && !user?.isConfirmed) {
    navigate("/create-profile");
  }

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F5F5F5",
          padding: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            width: "100%",
            position: "sticky",
            backgroundColor: "#F5F5F5",
            top: 0,
            height: "60px",
            zIndex: "10",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            {isActive ? (
              <SearchBarOpen
                onSearchClose={handleToggleSearch}
                onPropertySearch={handlePropertySearch}
              />
            ) : (
              <SearchBarClosed onSearchClick={handleToggleSearch} />
            )}
          </Box>
          <Box
            sx={{
              color: "#707070",
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px",
              height: "55px",
            }}
          >
            {isFilterActive ? (
              <MarketplaceFilter
                // Category
                anchorElCategory={anchorElCategory}
                onToggleCategory={handleToggleCategory}
                openCategory={openCategory}
                onlyProperties={handleShowOnlyProperties}
                onlyRFPs={handleShowOnlyRfps}
                allMarketplace={handleShowAllMarketplace}
                //Favorites
                onClickFavorites={handleClickFavorites}
                //Type
                onToggleType={handleToggleType}
                anchorElType={anchorElType}
                openType={openType}
                onHandleType={handlePropertyType}
                //Size
                onToggleSize={handleToggleSize}
                anchorElSize={anchorElSize}
                openSize={openSize}
                onBigToSmall={handleBigToSmall}
                onSmallToBig={handleSmallToBig}
                //Price
                onTogglePrice={handleTogglePrice}
                anchorElPrice={anchorElPrice}
                openPrice={openPrice}
                onHighToLow={handleHighToLow}
                onLowToHigh={handleLowToHigh}
                //Close Icon
                onFilterClose={handleToggleFillter}
                //Last Added
                onHandleNewAddedOnTop={handleNewAddedOnTop}
              />
            ) : (
              <Button
                onClick={handleToggleFillter}
                sx={{
                  "&:hover": {
                    backgroundColor: "#F5F5F5",
                  },
                }}
              >
                <FilterListIcon
                  sx={{
                    color: "rgba(112, 112, 112, 1)",
                    "&:hover": {
                      color: "rgba(13, 37, 97, 1)",
                    },
                  }}
                />
              </Button>
            )}
          </Box>
        </Box>

        {primaryView ? (
          <PropertyList
            properties={adjustedProperties}
            handleFavoriteChange={handleFavoriteChange}
          />
        ) : onlyRfps ? (
          <RFPList
            rfps={adjustedRfps}
            handleFavoriteRfpChange={handleFavoriteRfpChange}
          />
        ) : (
          <>
            <PropertyList
              properties={adjustedProperties}
              handleFavoriteChange={handleFavoriteChange}
            ></PropertyList>
            <RFPList
              rfps={adjustedRfps}
              handleFavoriteRfpChange={handleFavoriteRfpChange}
            />
          </>
        )}

        <SpeedDial
          ariaLabel="SpeedDial openIcon example"
          sx={{position: "fixed", bottom: 13, right: open ? "435px" : "13px"}}
          icon={<SpeedDialIcon openIcon={<CloseOutlinedIcon />} />}
          FabProps={{
            sx: {
              backgroundColor: "rgba(4, 119, 130, 1)",
              "&:hover": {
                backgroundColor: "rgba(1, 150, 164, 1)",
              },
            },
          }}
          open={openSpeedButton}
          onClick={handleToggleSpeedButton}
        >
          {actions.map((action) => (
            <SpeedDialAction
              sx={{
                display: "flex",
                alignItems: "center",
                color: action.disabled ? "#c0c0c0" : "#047782",
                pointerEvents: action.disabled ? "none" : "auto",
                opacity: action.disabled ? 0.5 : 1,
              }}
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
            />
          ))}
        </SpeedDial>
      </Box>
    </>
  );
};

export default MarketplaceMUI;
