import {Card} from "@mui/material";
import {useAppSelector} from "../../../app/store/configureStore";
import {useEffect, useRef} from "react";
import ChatHeader from "./ChatHeader";
import SendMessageForm from "./SendMessageForm";
import MessageContainer from "./MessageContainer";
import {useMessageCenterConnectionContext} from "../../../app/store/MessageCenterConnectionContext";
import {useChatConnectionContext} from "../../../app/store/ChatConnectionContext";

interface Props {
  chatId: string;
  messageCenterChat?: boolean;
  property: any;
}

const Chat = ({chatId, messageCenterChat, property}: Props) => {
  const {user} = useAppSelector((state) => state.account);
  const {chat} = useAppSelector((state) => state.chat);
  const {onDeleteUnreadMessages} = useMessageCenterConnectionContext();
  const {onJoinChat, onLeaveChat, onSendMessage} = useChatConnectionContext();
  // Used to check whether the user sent the first message
  // Only when the first message is sent delete the unread messages
  const firstMessageSend = useRef(false);

  const sendMessageHandler = async (text: string) => {
    await onSendMessage(text);

    // Delete messages only when the first message is sent or in case of global messages with each message
    if (!firstMessageSend.current || messageCenterChat) {
      firstMessageSend.current = !firstMessageSend.current;
      onDeleteUnreadMessages(chat?.id!);
    }
  };

  useEffect(() => {
    onJoinChat(chatId);

    return () => onLeaveChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId, onJoinChat, onLeaveChat]);

  return (
    <Card
      sx={{
        width: messageCenterChat ? "auto" : "385px",
        height: "100%",
        backgroundColor: "#FFF",
        display: "flex",
        flexDirection: "column",
        borderRadius: "none",
        boxShadow: "none",
      }}
    >
      <ChatHeader
        title={property.status}
        messageCenterChat={messageCenterChat}
        contractId={property.contractProgressId}
      />
      <MessageContainer appUser={user} chat={chat} />
      {chat && <SendMessageForm onSend={sendMessageHandler} />}
    </Card>
  );
};

export default Chat;
