import {Box, Divider, Typography} from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import MessageIcon from "@mui/icons-material/Message";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import DateRangeIcon from "@mui/icons-material/DateRange";
import LightTooltip from "../../../app/components/LightTooltip";
import {useState} from "react";

interface Props {
  isOpenProgressDrawer: boolean;
  onClickToOpenProgressDrawer: () => void;
  hideDocumentEditToolbar?: boolean;
  onClickToOpenCommunicationChat: () => void;
  onClickToOpenCommunicationPartners: () => void;
  onClickToOpenCalendar: () => void;
  onClickToOpenVideoMeetings: () => void;
}

export default function DealToolbar({
  isOpenProgressDrawer,
  onClickToOpenProgressDrawer: onOpenProgressDrawer,
  hideDocumentEditToolbar: hideToolbar = true,
  onClickToOpenCommunicationChat: onOpenCommunicationChat,
  onClickToOpenCommunicationPartners: onOpenCommunicationPartners,
  onClickToOpenCalendar: onOpenCalendar,
  onClickToOpenVideoMeetings: onOpenVideoMeetings,
}: Props) {
  const [activeIconId, setActiveIconId] = useState<number | null>(null);

  const handleClick = (id: number, onClickHandler: () => void) => {
    setActiveIconId((prevId) => (prevId === id ? null : id));
    onClickHandler();
  };

  const drawerIcons = [
    {
      id: 1,
      icon: (
        <SupervisorAccountOutlinedIcon
          sx={{color: activeIconId === 1 ? "#233975" : "#707070"}}
          onClick={() => handleClick(1, onOpenCommunicationPartners)}
        />
      ),
      title: "Add Partner",
    },
    {
      id: 2,
      icon: (
        <MessageIcon
          sx={{color: activeIconId === 2 ? "#233975" : "#707070"}}
          onClick={() => handleClick(2, onOpenCommunicationChat)}
        />
      ),
      title: "Task Comment",
    },
    {
      id: 3,
      icon: (
        <DateRangeIcon
          sx={{color: activeIconId === 3 ? "#233975" : "#707070"}}
          onClick={() => handleClick(3, onOpenCalendar)}
        />
      ),
      title: "Schedule Event",
    },
    {
      id: 4,
      icon: (
        <VideocamOutlinedIcon
          sx={{color: activeIconId === 4 ? "#233975" : "#707070"}}
          onClick={() => handleClick(4, onOpenVideoMeetings)}
        />
      ),
      title: "Video Call",
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: "#F2F6FA",
        height: "40px",
        display: "flex",
        alignItems: "center",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        zIndex: 5,
        position: "relative",
      }}
    >
      <Box
        onClick={onOpenProgressDrawer}
        sx={{
          display: "flex",
          alignItems: "center",
          width: "315px",
          paddingLeft: "6px",
          color: isOpenProgressDrawer ? "#0D2561" : "#707070",
          transition: "color 300ms ease-in-out",
        }}
      >
        <MenuOpenIcon
          fontSize="large"
          sx={{
            transform: isOpenProgressDrawer ? "rotateY(180deg)" : "",
            transition: "transform 300ms ease-in-out",
          }}
        />
        <Typography
          variant="subtitle1"
          sx={{
            paddingLeft: "15px",
            font: "Roboto",
            fontWeight: "400",
            lineHeight: "28px",
            fontSize: "15px",
            textAlign: "center",
          }}
        >
          Deal Progress
        </Typography>
      </Box>
      <Divider
        orientation="vertical"
        sx={{
          height: "65%",
          borderRightWidth: "2px",
          borderColor: "#707070",
          marginLeft: "-15px",
        }}
      />
      <Divider
        orientation="vertical"
        sx={{
          height: "65%",
          borderRightWidth: "2px",
          borderColor: "rgba(112, 112, 112, 1)",
          marginLeft: "auto",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "15px",
        }}
      >
        {drawerIcons.map(({id, icon, title}) => (
          <LightTooltip
            title={title}
            placement="top-end"
            key={`${id}-${title}`}
          >
            <Box
              key={id}
              component="span"
              sx={{
                display: "flex",
                margin: "5px",
                color: "#707070",
                "&:hover": {
                  color: "#0D2561",
                },
                transition: "color 300ms ease-in-out",
              }}
            >
              {icon}
            </Box>
          </LightTooltip>
        ))}
      </Box>
    </Box>
  );
}
