import { Stack } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

export default function LoaderWithLinearProgress() {
  return (
    <Stack
      sx={{
        width: "100%",
        color: "rgba(229, 217, 188, 1)",
      }}
    >
      <LinearProgress color="inherit" sx={{ height: "16px" }} />
    </Stack>
  );
}
