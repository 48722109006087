import {Box, Button} from "@mui/material";
import LenderOffersTable from "./LenderOffersTableMarketplace";
import {useState} from "react";
import ServiceProvidersOffersTable from "./ServiceProvidersOffersTable";

interface Props {
  propertyId: any;
}

const LendersOfferDrawerMarketplace = ({propertyId}: Props) => {
  const [activeOffer, setActiveOffer] = useState(false);
  const [activeLenderOffer, setActiveLenderOffer] = useState(true);

  const handleActiveOffer = () => {
    setActiveLenderOffer(false);
    setActiveOffer(true);
  };

  const handleActiveLenderOffer = () => {
    setActiveLenderOffer(true);
    setActiveOffer(false);
  };

  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: "#F2F6FA",
        padding: "20px",
        marginTop: "-15px",
      }}
    >
      <Box sx={{backgroundColor: "#EAF0F5"}}>
        <Button
          onClick={() => handleActiveLenderOffer()}
          sx={{
            paddingLeft: "15px",
            paddingTop: "15px",
            paddingBottom: "15px",
            color: activeLenderOffer ? "#0D2561" : "#616161",
            borderBottom: activeLenderOffer
              ? "2px solid rgba(13, 37, 97, 1)"
              : "none",
            borderRadius: 0,
          }}
        >
          LENDER OFFERS
        </Button>
        <Button
          onClick={() => handleActiveOffer()}
          sx={{
            paddingLeft: "15px",
            paddingTop: "15px",
            paddingBottom: "15px",
            color: activeOffer ? "#0D2561" : "#616161",
            borderBottom: activeOffer
              ? "2px solid rgba(13, 37, 97, 1)"
              : "none",
            borderRadius: 0,
          }}
        >
          OFFERS
        </Button>
      </Box>

      {activeOffer ? (
        <ServiceProvidersOffersTable dealId={propertyId} />
      ) : activeLenderOffer ? (
        <LenderOffersTable />
      ) : null}
    </Box>
  );
};

export default LendersOfferDrawerMarketplace;
