import { Box, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  confirmDateList: any;
}

const ScheduleConfirmationPerRole = ({ confirmDateList }: Props) => {
  return (
    <Box sx={{ paddingTop: "15px" }}>
      <Typography>User date availability confirmation:</Typography>
      {confirmDateList &&
        confirmDateList.map((item: any) => (
          <Typography>
            {item.roleName} - {item.confirmedDate}
          </Typography>
        ))}
    </Box>
  );
};

export default ScheduleConfirmationPerRole;
