import {
  Grid,
  Typography,
  Button,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {FieldValues, useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import AppTextInput from "../../app/components/AppTextInputCustom";
import agent from "../../app/api/agent";
import {TransitionProps} from "@mui/material/transitions";
import React from "react";
import {LoadingButton} from "@mui/lab";
import {toast} from "react-toastify";
import {Property} from "../../app/models/property";
import LoaderWithLinearProgress from "../../app/components/LoaderWithLinearProgress";
import {useAppSelector} from "../../app/store/configureStore";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChildModal = (props: any) => {
  const [loading, setLoading] = useState(false);
  const {user} = useAppSelector((state) => state.account);
  const navigate = useNavigate();
  const {id} = useParams<{id: string}>();

  const {
    control,
    handleSubmit,
    formState: {isSubmitting},
  } = useForm();

  const [property, setProperty] = useState<Property | null>(null);

  useEffect(() => {
    id &&
      agent.Marketplace.details(parseInt(id))
        .then((response) => {
          setProperty(response);
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
  }, [id]);

  async function handleSubmitData(data: FieldValues) {
    setLoading(true);
    data.PropertyId = property?.id;
    console.log(data);
    try {
      agent.InquriesProperty.create(data).then((response) => {
        toast.success("Inquiry was sent successfully");
        navigate("/marketplace");
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleClose = () => {
    props.setModalOpen(false);
  };

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <Dialog
      open={props.modalState}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      maxWidth="xl"
    >
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <DialogTitle>
          <Grid
            item
            xs={12}
            sx={{
              paddingLeft: "25px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Roboto",
                color: "#424242",
                fontWeight: "600",
                fontSize: "23px",
              }}
            >
              Inquiry
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={3}
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              width: "50%",
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          >
            <Grid item xs={6} sx={{display: "none"}}>
              <AppTextInput
                control={control}
                label="Property"
                name="PropertyId"
                value={property?.id}
              />
            </Grid>

            <Grid item xs={6}>
              <AppTextInput
                control={control}
                label="First Name"
                type="text"
                name="FirstName"
                required
                defaultValue={user?.firstName}
              />
            </Grid>

            <Grid item xs={6}>
              <AppTextInput
                control={control}
                label="Last Name"
                type="text"
                name="LastName"
                required
                defaultValue={user?.lastName}
              />
            </Grid>

            <Grid item xs={12}>
              <AppTextInput
                control={control}
                label="Email Address"
                type="text"
                name="EmailAddress"
                required
                defaultValue={user?.email}
              />
            </Grid>

            <Grid item xs={12}>
              <AppTextInput
                control={control}
                label="Phone Number"
                type="text"
                name="PhoneNumber"
                className="no-spinners"
                defaultValue={user?.phoneNumber}
              />
            </Grid>

            <Grid item xs={6}>
              <AppTextInput
                control={control}
                label="Address"
                type="text"
                name="Address"
                defaultValue={user?.companyAddress}
              />
            </Grid>

            <Grid item xs={6}>
              <AppTextInput
                control={control}
                label="City"
                name="City"
                defaultValue={user?.city}
              />
            </Grid>

            <Grid item xs={6}>
              <AppTextInput
                control={control}
                label="State"
                name="State"
                defaultValue={user?.state}
              />
            </Grid>

            <Grid item xs={6}>
              <AppTextInput
                control={control}
                label="Country"
                name="Country"
                defaultValue={user?.country}
              />
            </Grid>

            <Grid item xs={12}>
              <AppTextInput
                control={control}
                label="Inquiry Type"
                type="text"
                name="InquiryType"
              />
            </Grid>

            <Grid item xs={12}>
              <AppTextInput
                control={control}
                label="Offer Price (Optional)"
                name="OfferPrice"
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              width: "50%",
              paddingLeft: "20px",
              paddingRight: "20px",
              height: "100%",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Roboto",
                color: "#424242",
                fontWeight: "700",
                fontSize: "16px",
                paddingLeft: "22px",
              }}
            >
              Message
            </Typography>

            <Grid item xs={12}>
              <AppTextInput
                control={control}
                label="Type your inqury here"
                type="text"
                name="InquiryMessage"
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            sx={{
              background: "#047782",
              "&:hover": {
                background: "#0196A4",
              },
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontSize: "0.875rem",
              fontWeight: "400",
              color: "#fff",
              borderRadius: "4px",
              letterSpacing: "1.25px",
              margin: "15px",
            }}
            loading={isSubmitting}
            type="submit"
          >
            Submit
          </LoadingButton>

          <Button
            onClick={handleClose}
            sx={{
              background: "#fff",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontSize: "0.875rem",
              fontWeight: "400",
              color: "#047782",
              letterSpacing: "1.25px",
              border: "1px solid #047782",
              borderRadius: "4px",
              margin: "15px",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChildModal;
