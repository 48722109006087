import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {TransitionProps} from "@mui/material/transitions";
import {Form, useNavigate} from "react-router-dom";
import QRCode from "react-qr-code";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PropertyQRCode = (props: any) => {
  const navigate = useNavigate();
  const parentId = "svg-container"; // Define the ID of the parent container

  const downloadSvg = () => {
    const parentElement = document.getElementById(parentId);
    if (!parentElement) {
      alert("Parent container not found");
      return;
    }

    const svgElement = parentElement.querySelector("svg");
    if (!svgElement) {
      alert("No SVG element found");
      return;
    }

    const svgContent = new XMLSerializer().serializeToString(svgElement);
    const blob = new Blob([svgContent], {type: "image/svg+xml"});
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "qrcode.svg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  async function cancelEdit() {
    navigate("/marketplace");
  }

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{color: "rgba(66, 66, 66, 1)"}}>
          Download this QR code for Marketing and Promotional use.
        </DialogTitle>
        <DialogContent
          id={parentId}
          sx={{display: "flex", justifyContent: "center"}}
        >
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{color: "rgba(66, 66, 66, 1)"}}
          >
            <QRCode size={150} display="flex" value={props.link} />
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{display: "flex", justifyContent: "space-between"}}>
          <Form>
            <PrimaryButton onClick={() => downloadSvg()}>
              DOWNLOAD
            </PrimaryButton>
          </Form>
          <SecondaryButton onClick={cancelEdit}>CANCEL</SecondaryButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PropertyQRCode;
