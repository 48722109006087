import {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {useParams} from "react-router-dom";
//import {toast} from "react-toastify";
import {
  Box,
  Grid,
  ImageList,
  ImageListItem,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ApartmentIcon from "@mui/icons-material/Apartment";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import HorizontalSplitOutlinedIcon from "@mui/icons-material/HorizontalSplitOutlined";
import SettingsOverscanOutlinedIcon from "@mui/icons-material/SettingsOverscanOutlined";
import StraightenOutlinedIcon from "@mui/icons-material/StraightenOutlined";
import TrackChangesOutlinedIcon from "@mui/icons-material/TrackChangesOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import {Property} from "../../../app/models/property";
import agent from "../../../app/api/agent";
// import ShareIcon from "@mui/icons-material/Share";
// import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import LightTooltip from "../../../app/components/LightTooltip";
import LoaderWithLinearProgress from "../../../app/components/LoaderWithLinearProgress";

const DealOverviewDetails = () => {
  const {propertyId, contractId} = useParams<{
    propertyId: string;
    contractId: string;
  }>();

  const [property, setProperty] = useState<Property | null>(null);
  const [loading, setLoading] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [partnersInDeal, setPartnersInDeal] = useState([]);
  const [documentsInDeal, setDocumentsInDeal] = useState<any>([]);
  //const [icon, setIcon] = useState(property?.isFavorite);
  const [currentPhase, setCurrentPhase] = useState({
    previous: 0,
    current: 0,
    next: 0,
    previousName: "",
    currentName: "",
    nextName: "",
  });

  // const icons: any = [
  //   {
  //     id: 2,
  //     label: "Share",
  //     icon: (
  //       <LightTooltip title="Copy Link" placement="top-end">
  //         <ShareIcon sx={{color: "#707070"}} />
  //       </LightTooltip>
  //     ),
  //   },

  //   {
  //     id: 3,
  //     label: "Favorite",
  //     icon: icon ? (
  //       <LightTooltip title="Favorite" placement="top-end">
  //         <FavoriteIcon
  //           onClick={(e) => {
  //             handleFavorite(property?.id, e);
  //           }}
  //         />
  //       </LightTooltip>
  //     ) : (
  //       <LightTooltip title="Favorite" placement="top-end">
  //         <FavoriteBorderIcon
  //           onClick={(e) => handleFavorite(property?.id, e)}
  //           sx={{color: "#707070"}}
  //         />
  //       </LightTooltip>
  //     ),
  //   },
  // ];

  const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  // function handleFavorite(propertyId: any, e: any) {
  //   agent.Favorites.UpdateFavoriteProperty(propertyId)
  //     .then(() => {
  //       setIcon(!icon);
  //       toast.success("Property was edited Successfully");
  //     })
  //     .catch((error) => console.log(error));
  // }

  useEffect(() => {
    contractId &&
      agent.ContractProgressDeal.getCurrentPhase(parseInt(contractId)).then(
        (response) => {
          console.log(response);
          setCurrentPhase({
            previous: response.previousPhaseId,
            current: response.currentPhaseId,
            next: response.nextPhaseId,
            currentName: response.currentPhaseName,
            nextName: response.nextPhaseName,
            previousName: response.previousPhaseName,
          });
        }
      );
  }, [contractId]);

  useEffect(() => {
    if (!contractId) return;

    const fetchPartners = async () => {
      setLoading(true);
      try {
        const response = await agent.PartnerToDeal.getPartnersForDeal(
          contractId
        );
        setPartnersInDeal(response.$values);
      } catch (error) {
        console.error("Error fetching partners: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPartners();
  }, [contractId]);

  useEffect(() => {
    if (!contractId) return;

    const fetchDocumentActionTracker = async () => {
      setLoading(true);
      try {
        const response = await agent.Document.GetDocumentActionTracker(
          parseInt(contractId)
        );

        setDocumentsInDeal(response.$values);
      } catch (error) {
        console.error("Error fetching document action tracker: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDocumentActionTracker();
  }, [contractId]);

  useEffect(() => {
    const fetchMarketplaceDetails = async () => {
      try {
        const response = await agent.Marketplace.details(parseInt(propertyId!));
        setProperty(response);
        const galleryValues = response.gallery.$values;
        const galleryUrls = galleryValues.map((img: any) => img.imageUrl);
        setGalleryImages(galleryUrls);
        //    setIcon(response.isFavorite);
      } catch (error) {
        console.error("Error fetching marketplace details: ", error);
      }
    };

    fetchMarketplaceDetails();
  }, [propertyId]);

  if (loading) return <LoaderWithLinearProgress />;

  if (!property) return <h3>Property not Found</h3>;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F5F5F5",
          paddingX: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            width: "100%",
            position: "sticky",
            backgroundColor: "#F5F5F5",
            top: 0,
            height: "60px",
            zIndex: "10",
            paddingX: "10px",
          }}
        >
          <Box
            sx={{
              width: "50%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <NavLink to="/deals">
              <ArrowBackIosNewIcon
                sx={{
                  display: "flex",
                  color: "rgba(112, 112, 112, 1)",
                  "&:hover": {
                    color: "rgba(208, 235, 238, 1)",
                  },
                }}
              />
            </NavLink>
            <Typography
              variant="body2"
              color="#00000099"
              fontSize="14px"
              lineHeight="20px"
              paddingLeft="25px"
            >
              {property.state}
              <ArrowForwardIosIcon
                sx={{
                  fontSize: "12px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              />
              {property.city}
              <ArrowForwardIosIcon
                sx={{fontSize: "12px", marginLeft: "10px"}}
              />
            </Typography>
            <Typography
              variant="body2"
              color="black/0.87"
              fontSize="14px"
              lineHeight="20px"
              marginLeft="10px"
            >
              {property.addressLine}
            </Typography>
          </Box>

          {/* <Box
            sx={{
              width: "50%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {icons.map(({id, label, icon}: any) => {
              return (
                <Box
                  key={`${label} - ${id}`}
                  component="span"
                  sx={{display: "flex", margin: "5px"}}
                >
                  {icon}
                </Box>
              );
            })}
          </Box> */}
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Item sx={{height: "530px"}}>
              <Box padding="10px">
                <img
                  style={{width: "100%", height: "200px"}}
                  src={property.mainImageUrl}
                  alt={property.city + " " + property.type}
                />
              </Box>
              <Grid container>
                <Grid item xs={6} display="inline-flex" padding="10px">
                  <Typography
                    variant="h6"
                    textAlign="left"
                    fontWeight="400"
                    color="#424242"
                  >
                    {property.addressLine} {property.city}, {property.state}{" "}
                    {property.postalCode}{" "}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  padding="10px"
                  fontWeight="400"
                  color="#424242"
                >
                  <Typography variant="h6">
                    £{property.listPrice?.toLocaleString()}
                  </Typography>

                  <Typography>DealNumber:{contractId}</Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid
                  item
                  xs={6}
                  display="inline-flex"
                  flexDirection="column"
                  textAlign="left"
                  paddingLeft="10px"
                >
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <ScheduleIcon
                      sx={{height: "18px", width: "18px", marginRight: "4px"}}
                    />{" "}
                    Duration of Listing: {property.durationOfListing}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <ApartmentIcon
                      sx={{height: "18px", width: "18px", marginRight: "4px"}}
                    />
                    Type: {property.type}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <BookmarkBorderIcon
                      sx={{height: "18px", width: "18px", marginRight: "4px"}}
                    />
                    Lease: {property.lease}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <HorizontalSplitOutlinedIcon
                      sx={{height: "18px", width: "18px", marginRight: "4px"}}
                    />
                    Lease rate type: {property.leaseRateType}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <SettingsOverscanOutlinedIcon
                      sx={{height: "18px", width: "18px", marginRight: "4px"}}
                    />
                    Building Size: {property.buildingSize}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <StraightenOutlinedIcon
                      sx={{height: "18px", width: "18px", marginRight: "4px"}}
                    />
                    Site Size: {property.siteSize}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="inline-flex"
                  flexDirection="column"
                  textAlign="left"
                  paddingLeft="10px"
                >
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <TrackChangesOutlinedIcon
                      sx={{height: "18px", width: "18px", marginRight: "4px"}}
                    />
                    Zoning: {property.zoning}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <DateRangeOutlinedIcon
                      sx={{height: "18px", width: "18px", marginRight: "4px"}}
                    />
                    Year Built: {property.built}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <TodayOutlinedIcon
                      sx={{height: "18px", width: "18px", marginRight: "4px"}}
                    />
                    Last Renovation: {property.lastRenovation}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <CategoryOutlinedIcon
                      sx={{height: "18px", width: "18px", marginRight: "4px"}}
                    />
                    Parking: {property.parking}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <ListAltOutlinedIcon
                      sx={{height: "18px", width: "18px", marginRight: "4px"}}
                    />
                    Property: #{property.id}
                  </Typography>
                </Grid>
              </Grid>
            </Item>
          </Grid>

          <Grid item xs={8}>
            <Item sx={{height: "530px", overflowY: "auto"}}>
              <ImageList
                sx={{width: "100%", height: "500px"}}
                cols={3}
                rowHeight={164}
              >
                {galleryImages &&
                  galleryImages.map((url, index) => (
                    <ImageListItem key={index}>
                      <img
                        style={{height: "200px"}}
                        src={url}
                        alt={` ${index}`}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
              </ImageList>
            </Item>
          </Grid>
        </Grid>

        <Grid container spacing={2} marginTop="5px">
          <Grid item xs={4}>
            <Item sx={{height: "481px", padding: "20px", overflowY: "auto"}}>
              <Box
                display="inline-flex"
                width="100%"
                sx={{position: "sticky", top: 0}}
              >
                <Typography
                  variant="h5"
                  sx={{
                    paddingBottom: "10px",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "22px",
                    color: "#424242",
                  }}
                >
                  Partners
                </Typography>
              </Box>

              {partnersInDeal &&
                partnersInDeal.map((partner: any) => (
                  <Box
                    key={partner.id}
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    textAlign="left"
                  >
                    <Box width="35%" textAlign="left">
                      <Typography
                        variant="subtitle2"
                        fontWeight="700"
                        color="#424242"
                        sx={{textWrap: "wrap"}}
                      >
                        {partner.profession.replace(/([A-Z])/g, " $1").trim()}
                      </Typography>
                    </Box>
                    <Box width="65%">
                      <Typography
                        variant="subtitle2"
                        fontWeight="400"
                        color="#616161"
                      >
                        {partner.firstName} {partner.lastName}{" "}
                        {partner.companyName}
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item sx={{height: "481px", padding: "20px", overflowY: "auto"}}>
              {" "}
              <Box
                display="inline-flex"
                width="100%"
                padding="10px"
                sx={{position: "sticky", top: 0}}
              >
                <Typography
                  variant="h5"
                  sx={{
                    paddingBottom: "15px",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "22px",
                    color: "#424242",
                  }}
                >
                  Module Status
                </Typography>
              </Box>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  display="inline-grid"
                  flexDirection="column"
                  justifyContent="flex-start"
                  justifyItems="start"
                  textAlign="left"
                  paddingLeft="10px"
                  paddingRight="10px"
                >
                  <Typography
                    variant="subtitle2"
                    fontWeight="700"
                    color="#616161"
                  >
                    Next:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight="400"
                    color="#616161"
                  >
                    {currentPhase.nextName != null
                      ? currentPhase.nextName
                      : "There is no next phase in the deal"}
                  </Typography>

                  <br />
                </Grid>

                <Grid
                  item
                  xs={12}
                  display="inline-grid"
                  flexDirection="column"
                  justifyContent="flex-start"
                  textAlign="left"
                  justifyItems="start"
                  paddingLeft="10px"
                  paddingRight="10px"
                >
                  <Typography
                    variant="subtitle2"
                    fontWeight="700"
                    color="#616161"
                  >
                    Current:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight="400"
                    color="#616161"
                  >
                    {currentPhase.currentName != null
                      ? currentPhase.currentName
                      : "There is no current phase in the deal"}
                  </Typography>
                  <br />
                </Grid>
                <Grid
                  item
                  xs={12}
                  display="inline-grid"
                  flexDirection="column"
                  justifyContent="flex-start"
                  textAlign="left"
                  justifyItems="start"
                  paddingLeft="10px"
                  paddingRight="10px"
                >
                  <Typography
                    variant="subtitle2"
                    fontWeight="700"
                    color="#616161"
                  >
                    Previous:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight="400"
                    color="#616161"
                  >
                    {currentPhase.previousName != null
                      ? currentPhase.previousName
                      : "There is no previous phase in the deal"}
                  </Typography>
                </Grid>
              </Grid>
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item sx={{height: "481px", padding: "20px", overflowY: "auto"}}>
              {" "}
              <Box
                display="flex"
                width="100%"
                padding="10px"
                sx={{position: "sticky", top: 0}}
              >
                <Typography
                  variant="h5"
                  sx={{
                    paddingBottom: "15px",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "22px",
                    color: "#424242",
                  }}
                >
                  Action Status
                </Typography>
              </Box>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  display="inline-grid"
                  flexDirection="column"
                  justifyContent="flex-start"
                  justifyItems="start"
                  textAlign="left"
                  paddingLeft="10px"
                  paddingRight="10px"
                >
                  <Typography
                    variant="subtitle2"
                    fontWeight="700"
                    color="#616161"
                  >
                    Next:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight="400"
                    color="#616161"
                  >
                    Buyer: Review Documents for Final Closing
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight="400"
                    color="#616161"
                  >
                    Seller: Review Documents for Final Closing
                  </Typography>
                  <br />
                </Grid>

                <Grid
                  item
                  xs={12}
                  display="inline-grid"
                  flexDirection="column"
                  justifyContent="flex-start"
                  textAlign="left"
                  justifyItems="start"
                  paddingLeft="10px"
                  paddingRight="10px"
                >
                  <Typography
                    variant="subtitle2"
                    fontWeight="700"
                    color="#616161"
                  >
                    Current:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight="400"
                    color="#616161"
                  >
                    Settlement Agent: Prepare and Upload Documents for Final
                    Closing
                  </Typography>
                  <br />
                </Grid>
                <Grid
                  item
                  xs={12}
                  display="inline-grid"
                  flexDirection="column"
                  justifyContent="flex-start"
                  textAlign="left"
                  justifyItems="start"
                  paddingLeft="10px"
                  paddingRight="10px"
                >
                  <Typography
                    variant="subtitle2"
                    fontWeight="700"
                    color="#616161"
                  >
                    Previous:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight="400"
                    color="#616161"
                  >
                    Lender: Prepare and Upload Documents for Final Mortgage
                    Approval
                  </Typography>
                </Grid>
              </Grid>
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Item
              sx={{
                height: "300px",
                overflowY: "auto",
                marginBottom: "10px",
                paddingTop: 0,
              }}
            >
              <Box
                display="inline-flex"
                width="100%"
                sx={{
                  position: "sticky",
                  top: 0,
                  zIndex: "10",
                  backgroundColor: "white",
                  paddingLeft: "10px",
                  paddingTop: "10px",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "22px",
                    color: "#424242",
                  }}
                >
                  Documents
                </Typography>
              </Box>
              <Table>
                <TableHead
                  sx={{
                    position: "sticky",
                    top: 32.51,
                    zIndex: 5,
                    backgroundColor: "white",
                  }}
                >
                  <TableRow>
                    <TableCell width="20%">Document Name</TableCell>
                    <TableCell width="40%">Document Family</TableCell>
                    <TableCell width="20%">Date and Time of action</TableCell>
                    <TableCell width="20%">Action by Role</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {documentsInDeal &&
                    documentsInDeal
                      .slice()
                      .sort(
                        (a: any, b: any) =>
                          new Date(b.dateTime).getTime() -
                          new Date(a.dateTime).getTime()
                      )
                      .map(
                        (document: any) =>
                          document.typeName === "Uploaded" && (
                            <TableRow key={document.id} sx={{paddingTop: 0}}>
                              <TableCell width="20%">
                                {document.documentName}
                              </TableCell>
                              <TableCell width="40%">
                                {document.documentFamily}
                              </TableCell>
                              <TableCell width="20%">
                                {new Date(
                                  document.dateTime
                                ).toLocaleDateString()}{" "}
                                {new Date(
                                  document.dateTime
                                ).toLocaleTimeString()}
                              </TableCell>
                              <TableCell width="20%">
                                {document.typeName} by{" "}
                                {document.userRoleName
                                  .replace(/([A-Z])/g, " $1")
                                  .trim()}
                              </TableCell>
                            </TableRow>
                          )
                      )}
                </TableBody>
              </Table>
            </Item>

            <Item
              sx={{
                height: "300px",
                overflowY: "auto",
                marginBottom: "10px",
                paddingTop: 0,
              }}
            >
              <Box
                display="inline-flex"
                width="100%"
                sx={{
                  position: "sticky",
                  top: 0,
                  zIndex: "10",
                  backgroundColor: "white",
                  paddingLeft: "10px",
                  paddingTop: "10px",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "22px",
                    color: "#424242",
                  }}
                >
                  Journal
                </Typography>
              </Box>

              <Table>
                <TableHead
                  sx={{
                    position: "sticky",
                    top: 32.51,
                    zIndex: 5,
                    backgroundColor: "white",
                  }}
                >
                  <TableRow>
                    <TableCell width="20%">Document Name</TableCell>
                    <TableCell width="40%">Document Family</TableCell>
                    <TableCell width="20%">Date and Time of action</TableCell>
                    <TableCell width="20%">Action by Role</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {documentsInDeal &&
                    documentsInDeal
                      .slice()
                      .sort(
                        (a: any, b: any) =>
                          new Date(b.dateTime).getTime() -
                          new Date(a.dateTime).getTime()
                      )
                      .map(
                        (document: any) =>
                          document.typeName !== "Uploaded" && (
                            <TableRow key={document.id} sx={{paddingTop: 0}}>
                              <TableCell width="20%">
                                {document.documentName}
                              </TableCell>
                              <TableCell width="40%">
                                {document.documentFamily}
                              </TableCell>
                              <TableCell width="20%">
                                {new Date(
                                  document.dateTime
                                ).toLocaleDateString()}{" "}
                                {new Date(
                                  document.dateTime
                                ).toLocaleTimeString()}
                              </TableCell>
                              <TableCell width="20%">
                                {document.typeName} by{" "}
                                {document.userRoleName
                                  .replace(/([A-Z])/g, " $1")
                                  .trim()}
                              </TableCell>
                            </TableRow>
                          )
                      )}
                </TableBody>
              </Table>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DealOverviewDetails;
