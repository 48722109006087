import {useEffect, useState} from "react";
import agent from "../../app/api/agent";
import {useAppSelector} from "../../app/store/configureStore";
import {Box, Card, Grid} from "@mui/material";
import PartnersContentMUI from "./PartnersContent";
import PartnersMenuMUI from "./PartnersMenu";
import {useNavigate} from "react-router-dom";

const CreatePartner = () => {
  const {user} = useAppSelector((state) => state.account);
  const [userTypes, setUserTypes] = useState<Array<any>>([]);
  const [selectedItem, setSelectedItem] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    agent.Account.getTypes()
      .then((response) => {
        const filteredTypes = response.$values.filter(
          (type: any) =>
            type.name !== "BuyerRepresentative" &&
            type.name !== "SellerRepresentative" &&
            type.name !== "SellerAttorney" &&
            type.name !== "BuyerAttorney" &&
            type.name !== "Admin" &&
            type.name !== "Member" &&
            type.name !== "EscrowAgent"
        );

        setUserTypes(filteredTypes);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [selectedItem]);

  function handleItemClick(item: any) {
    setSelectedItem(item);
  }

  if (!user) {
    navigate("/login");
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F5F5F5",
          margin: "0",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <Grid container sx={{height: "100%", backgroundColor: "#F5F5F5"}}>
          <Grid item xs={3}>
            <Card
              sx={{
                height: "100%",
                padding: "15px",
                borderRadius: "8px",
                backgroundColor: "#FFF",
                marginRight: "10px",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <PartnersMenuMUI
                items={userTypes}
                selectedItem={selectedItem}
                onItemClick={handleItemClick}
              />
            </Card>
          </Grid>

          <Grid item xs={9} sx={{height: "100%"}}>
            <PartnersContentMUI selectedItem={selectedItem} user={user} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CreatePartner;
