import {Box, Paper, Typography} from "@mui/material";
import {NavLink} from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const NotFound = () => {
  return (
    <>
      <Box
        sx={{
          height: "60px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <NavLink to="/marketplace">
          <ArrowBackIosNewIcon
            sx={{
              display: "flex",
              color: "rgba(112, 112, 112, 1)",
              "&:hover": {
                color: "rgba(208, 235, 238, 1)",
              },
            }}
          />
        </NavLink>
      </Box>
      <Box
        sx={{
          height: "calc(100% - 60px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{
            height: "600px",
            width: "650px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={"/images/No connection.png"}
            alt="IMG"
            style={{
              height: "430px",
              width: "430px",
            }}
          />
          <Typography sx={{fontWeight: 800, fontSize: "23px"}}>
            Oops! We couldn't find the page that you're looking for.
          </Typography>
          <Typography sx={{fontWeight: 400, fontSize: "16px"}}>
            The link you followed is probably broken or the page has been
            removed
          </Typography>
        </Paper>
      </Box>
    </>
  );
};

export default NotFound;
