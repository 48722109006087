import {RFP} from "../../app/models/rfp";
import RFPCard from "./RFPCard";

interface Props {
  rfps: RFP[];
  handleFavoriteRfpChange: (rfpId: any) => void;
}

const RFPList = ({rfps, handleFavoriteRfpChange}: Props) => {
  return (
    <>
      {rfps &&
        rfps.map((rfp) => (
          <RFPCard
            key={rfp.id}
            rfp={rfp}
            handleFavoriteRfpChange={handleFavoriteRfpChange}
          />
        ))}
    </>
  );
};

export default RFPList;
