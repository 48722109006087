import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import {useEffect, useReducer, useState} from "react";
import agent from "../../../app/api/agent";
import {toast} from "react-toastify";

interface Props {
  dealId: number;
  onSetOpenCommunicationToolbar: React.Dispatch<
    React.SetStateAction<{
      openCommunicationChat: boolean;
      openCommunicationToolbarPartners: boolean;
      openCalendar: boolean;
      openVideoMeetings: boolean;
    }>
  >;
  contractId: any;
}

const CommunicationToolbarPartners = ({
  dealId,
  onSetOpenCommunicationToolbar,
  contractId,
}: Props) => {
  const initialState = {
    selectedNetorkOrManually: "",
    selectedItem: "",
    userTypes: [],
    partners: [],
    selectedItemID: "",
    partnerUserIds: "",
  };

  function reducer(state: any, action: any): any {
    switch (action.type) {
      case "setTypes": {
        return {
          ...state,
          userTypes: action.payload,
        };
      }
      case "setPartners": {
        return {
          ...state,
          partners: action.payload.$values,
        };
      }

      case "setPartnerUserIds": {
        return {
          ...state,
          partnerUserIds: action.payload,
        };
      }
      case "setRoleSelectedItem":
        return {
          ...state,
          selectedItem: action.payload.name,
          selectedItemID: action.payload.id,
        };
      case "setSelectedNetorkOrManually":
        return {
          ...state,
          selectedNetorkOrManually: action.payload,
        };

      case "addPartnersManually": {
        return {
          ...state,
          partners: [...state.partners, action.payload.user],
        };
      }

      default:
        throw new Error("Unknown action");
    }
  }

  const [
    {
      selectedNetorkOrManually,
      selectedItem,
      userTypes,
      partners,
      selectedItemID,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const items = ["Select from Network", "Add Manually"];
  const [selectedOption, setSelectedOption] = useState();
  const [taskMessagesList, setTaskMessagesList] = useState<any>([]);

  const [formDataAddManually, setFormDataAddManually] = useState({
    firstName: "",
    lastName: "",
    email: "",
    partnerRole: "",
    isActive: false,
    propertyId: dealId,
    contractId: contractId,
  });

  const [formDataAddFromNetwork, setFromDataAddFromNetwork] = useState({
    partnerRole: "",
    email: "",
    propertyId: dealId,
    partnerId: selectedOption,
    contractId: contractId,
  });

  const defaultProps = {
    options: partners,
    getOptionLabel: (option: any) => (option ? option.email : ""),
  };

  const transformName = (name: any) => {
    return name.replace(/([A-Z])/g, " $1").trim();
  };

  function handleItemClick(item: any) {
    dispatch({type: "setRoleSelectedItem", payload: item});
  }

  function handleSelectFromNetworkOrAddManually(item: any) {
    dispatch({type: "setSelectedNetorkOrManually", payload: item});
  }

  function handleCancel() {
    onSetOpenCommunicationToolbar(() => {
      return {
        openCommunicationChat: false,
        openCalendar: false,
        openCommunicationToolbarPartners: false,
        openVideoMeetings: false,
      };
    });
  }

  function handleClearFieldsAddManually() {
    setFormDataAddManually({
      firstName: "",
      lastName: "",
      email: "",
      partnerRole: "",
      isActive: false,
      propertyId: dealId,
      contractId: contractId,
    });
  }

  function handleClearFieldsNetwork() {
    setFromDataAddFromNetwork({
      partnerRole: "",
      email: "",
      propertyId: dealId,
      partnerId: undefined,
      contractId: contractId,
    });
  }

  const handleSubmitAddManually = async (e: any) => {
    e.preventDefault();
    formDataAddManually.partnerRole = `${selectedItem}`;

    try {
      await agent.Partners.addPartner(formDataAddManually)
        .then((response) => {
          dispatch({
            type: "addPartnersManually",
            payload: response,
          });
          toast.success("Partner was added Successfully");
          handleClearFieldsAddManually();
        })
        .catch((error) => {
          toast.error(error[1]);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitSelectFromNetwork = async (e: any) => {
    e.preventDefault();
    formDataAddFromNetwork.propertyId = dealId;
    formDataAddFromNetwork.partnerId = selectedOption;
    formDataAddFromNetwork.contractId = contractId;

    try {
      await agent.PartnerToDeal.addPartnerToDeal(formDataAddFromNetwork)
        .then(() => {
          toast.success("Partner was added Successfully");
          handleClearFieldsNetwork();
        })
        .catch((error) => {
          toast.error(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeAddManually = (e: any) => {
    let {name, value} = e.target;
    setFormDataAddManually({...formDataAddManually, [name]: value});
  };

  const handleChangeNetwork = (newValue: any) => {
    setFromDataAddFromNetwork({...formDataAddFromNetwork, email: newValue});
    setSelectedOption(newValue.id);
  };

  useEffect(() => {
    agent.ContractProgressDeal.getListOfTaskMessages().then((response) =>
      setTaskMessagesList(response.$values)
    );
  }, []);

  useEffect(() => {
    agent.Account.getTypes()
      .then((response) => {
        const filteredTypes = response.$values.filter(
          (type: any) =>
            type.name !== "BuyerRepresentative" &&
            type.name !== "SellerRepresentative" &&
            type.name !== "SellerAttorney" &&
            type.name !== "BuyerAttorney" &&
            type.name !== "Admin" &&
            type.name !== "Member"
        );

        dispatch({
          type: "setTypes",
          payload: filteredTypes,
        });
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (selectedItemID) {
      agent.Partners.getPartners(selectedItemID)
        .then((response) => {
          dispatch({
            type: "setPartners",
            payload: response,
          });
        })
        .catch((error) => console.log(error));
    }
  }, [selectedItemID]);

  useEffect(() => {
    dispatch({
      type: "setPartnerUserIds",
      payload: partners.map((u: any) => u.id),
    });
  }, [partners]);

  const sortedUserTypes = [...userTypes].sort((a, b) => {
    const nameA = transformName(a.name).toLowerCase();
    const nameB = transformName(b.name).toLowerCase();
    return nameA.localeCompare(nameB);
  });

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#FFF",
          overflowY: "scroll",
          height: "100%",
          width: "385px",
        }}
      >
        <Box
          display="flex"
          padding="25px"
          justifyContent="center"
          sx={{
            backgroundColor: "rgba(242, 246, 250, 1)",
            boxShadow: "0.5px 0.5px rgba(0, 0, 0, 0.25) inset",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "24px",
              letterSpacing: "0.5px",
              textAlign: "left",
              color: "rgba(13, 37, 97, 1)",
            }}
          >
            PARTNERS
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          padding="25px"
          height="40px"
          marginBottom="15px"
        >
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0.5px",
              textAlign: "left",
              color: "#0D2561",
            }}
          >
            Add a preferred partner to the deal
          </Typography>
        </Box>
        <Divider
          sx={{
            borderColor: "rgba(13, 37, 97, 1)",
            marginLeft: "15px",
            marginRight: "15px",
          }}
        />

        <Box
          display="flex"
          justifyContent="space-between"
          padding="25px"
          height="40px"
          marginBottom="15px"
        >
          <TextField
            select
            label="Type of partner"
            variant="standard"
            fullWidth
            required
          >
            {sortedUserTypes.map((option: any) => (
              <MenuItem
                key={option.id}
                value={option.name || ""}
                onClick={() => handleItemClick(option)}
              >
                {option.name.replace(/([A-Z])/g, " $1").trim()}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          padding="25px"
          height="40px"
          marginBottom="15px"
        >
          <TextField
            select
            variant="standard"
            defaultValue={items[0]}
            fullWidth
            label="Select from Network or Add Manually"
            required
          >
            {items.map((option: any) => (
              <MenuItem
                key={option}
                value={option}
                onClick={() => handleSelectFromNetworkOrAddManually(option)}
              >
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        {selectedNetorkOrManually === "Add Manually" ? (
          <>
            <FormControl
              onSubmit={handleSubmitAddManually}
              sx={{width: "100%"}}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                padding="15px"
                height="40px"
                marginBottom="15px"
              >
                <TextField
                  sx={{
                    width: "100%",
                  }}
                  variant="standard"
                  name="firstName"
                  value={formDataAddManually.firstName}
                  onChange={handleChangeAddManually}
                  label="Enter partner first name"
                  required
                />
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                padding="15px"
                height="40px"
                marginBottom="15px"
              >
                <TextField
                  sx={{
                    width: "100%",
                  }}
                  variant="standard"
                  name="lastName"
                  value={formDataAddManually.lastName}
                  onChange={handleChangeAddManually}
                  label="Enter partner last name"
                  required
                />
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                padding="15px"
                height="40px"
                marginBottom="15px"
              >
                <TextField
                  sx={{
                    width: "100%",
                  }}
                  variant="standard"
                  name="email"
                  value={formDataAddManually.email}
                  onChange={handleChangeAddManually}
                  label="Enter partner email"
                  required
                />
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                padding="25px"
                height="40px"
                marginBottom="15px"
              >
                <TextField
                  select
                  label="Action Required"
                  variant="standard"
                  fullWidth
                >
                  {taskMessagesList &&
                    taskMessagesList.map((option: any) => (
                      <MenuItem
                        key={option.id}
                        value={option.taskMessageName || ""}
                      >
                        {option.taskMessageName}
                      </MenuItem>
                    ))}
                </TextField>
              </Box>

              <Box
                sx={{
                  marginRight: "20px",
                  marginTop: "35px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  sx={{color: "rgba(0, 131, 143, 1)"}}
                  type="submit"
                  onClick={handleSubmitAddManually}
                >
                  Submit
                </Button>
                <Button
                  onClick={() => handleCancel()}
                  sx={{color: "rgba(117, 117, 117, 1)"}}
                >
                  Cancel
                </Button>
              </Box>
            </FormControl>
          </>
        ) : (
          <>
            <FormControl
              onSubmit={handleSubmitSelectFromNetwork}
              sx={{width: "100%"}}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                padding="25px"
                height="40px"
                marginBottom="15px"
              >
                <Autocomplete
                  {...defaultProps}
                  autoComplete
                  fullWidth
                  value={formDataAddFromNetwork.email}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      handleChangeNetwork(newValue);
                    }
                  }}
                  includeInputInList
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search"
                      variant="standard"
                      value={formDataAddFromNetwork.email}
                      helperText="Select Type of partner for results"
                      required
                    />
                  )}
                />
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                padding="25px"
                height="40px"
                marginBottom="15px"
                paddingTop="35px"
              >
                <TextField
                  select
                  label="Action Required"
                  variant="standard"
                  fullWidth
                >
                  {taskMessagesList &&
                    taskMessagesList.map((option: any) => (
                      <MenuItem
                        key={option.id}
                        value={option.taskMessageName || ""}
                      >
                        {option.taskMessageName}
                      </MenuItem>
                    ))}
                </TextField>
              </Box>

              <Box
                sx={{
                  marginTop: "40px",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "20px",
                }}
              >
                <Button
                  sx={{color: "rgba(0, 131, 143, 1)"}}
                  type="submit"
                  onClick={handleSubmitSelectFromNetwork}
                >
                  Submit
                </Button>
                <Button
                  sx={{color: "rgba(117, 117, 117, 1)"}}
                  onClick={() => handleCancel()}
                >
                  Cancel
                </Button>
              </Box>
            </FormControl>
          </>
        )}
      </Box>
    </>
  );
};
export default CommunicationToolbarPartners;
