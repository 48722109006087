import { Avatar, Box, Button, Typography } from "@mui/material";
import { cutStringToNearestWord, relativeDate } from "../../app/util/util";
import { useEffect, useState } from "react";
import {
  MessageModel,
  PropertyRfpDictionary,
  SubjectDictionary,
  UserDictionary,
} from "../../app/models/messageCenter";
import { MESSAGE_CARD_CUT_MESSAGE_LENGTH } from "../../app/util/constants";
import styles from "./MessageCard.module.css";
import { useAppDispatch } from "../../app/store/configureStore";
import {
  setOpenCardDrawerWithId,
  toggleMessageCenter,
} from "./messageCenterSlice";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  chatId: string;
  messages: MessageModel[];
  users: UserDictionary;
  propertiesRfps: PropertyRfpDictionary;
  subjects?: SubjectDictionary;
  globalMessage: boolean;
  onOpenMessageGroupChat?: (id: string) => void;
  onDeleteUnreadMessages: (chatId: string) => Promise<void>;
}

function getDetailsForMessage(message: MessageModel, users: UserDictionary) {
  return {
    relativeDate: relativeDate(message.timestamp),
    userFirstName: users[message.userId].firstName,
    userProfilePictureUrl: users[message.userId]?.profilePictureUrl ?? "",
  };
}

export default function MessageCard({
  chatId,
  messages,
  users,
  propertiesRfps,
  subjects,
  globalMessage,
  onOpenMessageGroupChat,
  onDeleteUnreadMessages,
}: Props) {
  const lastMessage = messages[messages.length - 1];
  const propertyRfpId = !globalMessage && propertiesRfps[chatId].id;
  const location = useLocation();
  const navigate = useNavigate();

  const [showAllMessages, setShowAllMessages] = useState(false);
  const [detailsForMessage, setDetailsForMessage] = useState(
    getDetailsForMessage(lastMessage, users)
  );
  const dispatch = useAppDispatch();

  const mouseEnterHandler = (message: MessageModel, users: UserDictionary) => {
    setDetailsForMessage(getDetailsForMessage(message, users));
  };

  const mouseLeaveHandler = () => {
    setDetailsForMessage(getDetailsForMessage(lastMessage, users));
  };

  const toggleShowAllMessagesHandler = () => {
    // When collapsing the messages show only details for the last message
    showAllMessages &&
      setDetailsForMessage(getDetailsForMessage(lastMessage, users));

    setShowAllMessages((prevState) => {
      return !prevState;
    });
  };

  const onReplyHandler = () => {
    if (globalMessage) {
      onOpenMessageGroupChat!(chatId);
    } else {
      if (location.pathname !== "deals") navigate("/deals");
      dispatch(setOpenCardDrawerWithId(propertyRfpId as number));
      dispatch(toggleMessageCenter());
    }
  };

  useEffect(
    () => {
      setDetailsForMessage(getDetailsForMessage(lastMessage, users));
      const interval = setInterval(() => {
        setDetailsForMessage(getDetailsForMessage(lastMessage, users));
      }, 60000);

      return clearInterval(interval);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastMessage]
  );

  const allMessages = messages.map((message) => (
    <div
      key={message.id}
      className={styles.message}
      onMouseEnter={() => mouseEnterHandler(message, users)}
    >
      {message.text}
    </div>
  ));

  const lastCutMessage = cutStringToNearestWord(
    lastMessage.text,
    MESSAGE_CARD_CUT_MESSAGE_LENGTH
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      paddingX="7px"
      paddingTop="10px"
      paddingBottom={showAllMessages ? "4px" : "8px"}
      sx={{ backgroundColor: "rgba(255, 255, 255, 1)" }}
    >
      <Box display="flex">
        {!globalMessage && (
          <Box width="40%" maxHeight="80px">
            <img
              className="chat-image"
              src={propertiesRfps[chatId].mainImageUrl}
              alt="Chat"
            />
          </Box>
        )}
        <Box
          sx={{
            width: globalMessage ? "100%" : "60%",
            display: "flex",
            flexDirection: globalMessage ? "row" : "column",
            alignItems: globalMessage ? "center" : "stretch",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "18px",
              fontWeight: "500",
              marginTop: "-3px",
              marginLeft: "10px",
              lineHeight: globalMessage ? "1.4" : "1.75",
            }}
          >
            {globalMessage && messages.length !== 1 && (
              <Box
                component="span"
                sx={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  width: "5px",
                  height: "5px",
                  borderRadius: "10px",
                  backgroundColor: "rgba(255, 69, 58, 1)",
                  marginRight: "5px",
                  marginBottom: "2px",
                }}
              />
            )}
            {globalMessage ? subjects![chatId] : "Review P&S Agreement"}
          </Typography>

          <Box
            display="flex"
            alignItems="center"
            marginLeft="16px"
            sx={{
              marginBottom: globalMessage ? "2px" : "5px",
            }}
          >
            {!globalMessage && (
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  color: "rgba(0, 0, 0, 0.6)",
                  textTransform: "uppercase",
                  marginRight: "auto",
                }}
              >
                Deal #{propertiesRfps[chatId].id}
              </Typography>
            )}
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                color: "rgba(255, 69, 58, 1)",
              }}
            >
              {detailsForMessage.relativeDate}
            </Typography>
            <Avatar
              sx={{
                width: "36px",
                height: "36px",
                marginLeft: "7px",
                marginRight: "10px",
              }}
            >
              {detailsForMessage.userProfilePictureUrl ? (
                <img
                  src={detailsForMessage.userProfilePictureUrl}
                  alt="User profile"
                  style={{
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <Typography sx={{ color: "#0D2561" }}>
                  {detailsForMessage.userFirstName.charAt(0).toUpperCase()}
                </Typography>
              )}
            </Avatar>
          </Box>
        </Box>
      </Box>
      <Box
        className={styles.container}
        onMouseLeave={mouseLeaveHandler}
        sx={{
          fontSize: "14px",
          fontWeight: "400",
          marginTop: globalMessage ? "5px" : "9px",
          marginX: "3px",
        }}
      >
        {showAllMessages ? allMessages : lastCutMessage}
        <span
          style={{
            fontSize: "14px",
            fontWeight: "400",
            marginLeft: "7px",
            color: "rgba(0, 162, 255, 1)",
            cursor: "pointer",
          }}
          onClick={toggleShowAllMessagesHandler}
        >
          {showAllMessages ? "Less.." : "More..."}
        </span>
      </Box>
      {showAllMessages && (
        <Box alignSelf="end" marginTop="5px">
          <Button variant="text" onClick={onReplyHandler}>
            REPLY
          </Button>
          <Button
            variant="text"
            sx={{ color: "rgba(117, 117, 117, 1)", marginLeft: "15px" }}
            onClick={() => onDeleteUnreadMessages(chatId)}
          >
            DELETE
          </Button>
        </Box>
      )}
    </Box>
  );
}
