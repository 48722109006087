import {Button, Card, Menu, MenuItem} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

interface Props {
  allMarketplace: () => void;
  anchorElCategory: HTMLElement | null;
  onToggleCategory: (event: React.MouseEvent<HTMLButtonElement>) => void;
  openCategory: boolean;
  onlyProperties: () => void;
  onlyRFPs: () => void;
  onClickFavorites: () => void;
  onToggleType: (event: React.MouseEvent<HTMLButtonElement>) => void;
  anchorElType: HTMLElement | null;
  openType: boolean;
  onToggleSize: (event: React.MouseEvent<HTMLButtonElement>) => void;
  anchorElSize: HTMLElement | null;
  openSize: boolean;
  onBigToSmall: (value: any) => void;
  onSmallToBig: (value: any) => void;
  onTogglePrice: (event: React.MouseEvent<HTMLButtonElement>) => void;
  anchorElPrice: HTMLElement | null;
  openPrice: boolean;
  onHighToLow: (value: any) => void;
  onLowToHigh: (value: any) => void;
  onFilterClose: () => void;
  onHandleType: (value: any) => void;
  onHandleNewAddedOnTop: (value: any) => void;
}

export default function MarketplaceFilter({
  allMarketplace,
  anchorElCategory,
  onToggleCategory,
  openCategory,
  onlyProperties,
  onlyRFPs,
  onClickFavorites,
  onToggleType,
  anchorElType,
  openType,
  onToggleSize,
  anchorElSize,
  openSize,
  onBigToSmall,
  onSmallToBig,
  onTogglePrice,
  anchorElPrice,
  openPrice,
  onHighToLow,
  onLowToHigh,
  onFilterClose,
  onHandleType,
  onHandleNewAddedOnTop,
}: Props) {
  return (
    <Card sx={{display: "flex"}}>
      <Button
        onClick={onToggleCategory}
        sx={{
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: "700",
          lineHeight: "20px",
          letterSpacing: "0.4px",
          textAlign: "left",
          color: "rgba(97, 97, 97, 1)",
        }}
      >
        Category
        {openCategory ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      <Menu
        anchorEl={anchorElCategory}
        open={openCategory}
        onClose={onToggleCategory}
      >
        <MenuItem onClick={onlyProperties}>Property </MenuItem>
        <MenuItem onClick={onlyRFPs}>RFP</MenuItem>
        <MenuItem onClick={allMarketplace}>All</MenuItem>
      </Menu>

      <Button
        onClick={onClickFavorites}
        sx={{
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: "700",
          lineHeight: "20px",
          letterSpacing: "0.4px",
          textAlign: "left",
          color: "rgba(97, 97, 97, 1)",
        }}
      >
        Favorites
      </Button>

      <Button
        onClick={onToggleType}
        sx={{
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: "700",
          lineHeight: "20px",
          letterSpacing: "0.4px",
          textAlign: "left",
          color: "rgba(97, 97, 97, 1)",
        }}
      >
        Type
        {openType ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      <Menu anchorEl={anchorElType} open={openType} onClose={onToggleType}>
        <MenuItem onClick={() => onHandleType("All Types")}>All Types</MenuItem>
        <MenuItem onClick={() => onHandleType("Data Center")}>
          Data Center
        </MenuItem>
        <MenuItem onClick={() => onHandleType("Home")}>Home</MenuItem>
        <MenuItem onClick={() => onHandleType("Flex")}>Flex</MenuItem>
        <MenuItem onClick={() => onHandleType("Hospitality")}>
          Hospitality
        </MenuItem>
        <MenuItem onClick={() => onHandleType("Land")}>Land</MenuItem>
        <MenuItem onClick={() => onHandleType("Life Sciences")}>
          Life Sciences
        </MenuItem>
        <MenuItem onClick={() => onHandleType("Medical")}>Medical</MenuItem>
        <MenuItem onClick={() => onHandleType("Multifamily")}>
          Multifamily
        </MenuItem>
        <MenuItem onClick={() => onHandleType("Office")}>Office</MenuItem>
        <MenuItem onClick={() => onHandleType("Retail")}>Retail</MenuItem>
        <MenuItem onClick={() => onHandleType("Speciality")}>
          Speciality
        </MenuItem>
      </Menu>

      <Button
        onClick={onToggleSize}
        sx={{
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: "700",
          lineHeight: "20px",
          letterSpacing: "0.4px",
          textAlign: "left",
          color: "rgba(97, 97, 97, 1)",
        }}
      >
        Size
        {openSize ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      <Menu anchorEl={anchorElSize} open={openSize} onClose={onToggleSize}>
        <MenuItem onClick={onBigToSmall}>Bigger to smaller</MenuItem>
        <MenuItem onClick={onSmallToBig}>Smaller to bigger</MenuItem>
      </Menu>

      <Button
        onClick={onTogglePrice}
        sx={{
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: "700",
          lineHeight: "20px",
          letterSpacing: "0.4px",
          textAlign: "left",
          color: "rgba(97, 97, 97, 1)",
        }}
      >
        Price
        {openPrice ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      <Menu anchorEl={anchorElPrice} open={openPrice} onClose={onTogglePrice}>
        <MenuItem onClick={onHighToLow}>High to Low</MenuItem>
        <MenuItem onClick={onLowToHigh}>Low to High</MenuItem>
      </Menu>

      <Button
        sx={{
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: "700",
          lineHeight: "20px",
          letterSpacing: "0.4px",
          textAlign: "left",
          color: "rgba(97, 97, 97, 1)",
        }}
        onClick={onHandleNewAddedOnTop}
      >
        Recently Added
      </Button>
      <Button onClick={onFilterClose}>
        <CloseIcon sx={{color: "rgba(97, 97, 97, 1)"}} />
      </Button>
    </Card>
  );
}
